export default {
    home: '首頁',
    technology: '技術',
    ecological: '生態體系',
    browser: '區塊鏈瀏覽器',
    recruitment: '節點',
    wallet: '錢包',
    contact: '聯絡我們',
    joinUs: '加入我們',
    partner: '合作伙伴',
    language: '語言',
    learnMore: '瞭解更多',
    company: 'EDAO發展基金會有限公司',
    funds: 'ICW基金',
    lucky: '幸運轉盤',
    hashGuess: '雜湊競猜',
    prize: '鉅額獎池，保證中獎',
    prizeBtn: '前往',

    aboutICW: '關於TridentChain',
    whitePaper: '白皮書',
    ICWwhitePaper: 'TDCChain  白皮書',
    technologyFunctions: '技術與功能',
    roadmap: '路線圖',
    map: '路線圖',

    White: '白皮書下載',
    Introduction: 'TridentChain介紹',

    Numbertransactions: '交易數',
    Blockheight: '區塊高度',
    Coretoken: '總質押',

    welcometoICW: '歡迎訪問TridentChain',
    bannerText: '探索區塊鏈在深海的新方向。',
    bannerText2: 'TridentChain支援高階使用者、新消費者以及介於兩者之間的每個人的體驗。',
    whitePaperDownload: '白皮書下載連結',
    whychoose: '為什麼選擇',
    aspires: 'ICW勵志做WEB3.0的領航者',
    decentralizationTitle: '去中心化、安全性、高效能',
    decentralizationTitle1: '去中心化、',
    decentralizationTitle2: '安全性、高效能',
    decentralizationSubtitle: 'TridentChain採用PoW+DpoS混合共識模型，提高去中心化程度；通過增加參與度來實現區塊鏈網路和社羣的公平性節點的閾值和計費組的隨機性。',
    contractTitle: '通用交易智慧合約',
    contractSubtitle: 'TridentChain 是一條由全球開發者社羣領導的去中心化公共鏈。它快速、節能、可靠，為開發者提供了強大的開放性。',
    highlyModularTitle: '高度模組化',
    highlyModularSubtitle: 'TridentChain參考NULS模組架構，具有熱插拔、高效、長壽、永恆的特點。這顯著降低了區塊鏈的整體複雜性，簡化了其設計、除錯和維護流程。',
    crossChainCommunicationTitle: '跨鏈通訊',
    crossChainCommunicationSubtitle: 'ICWChain支援跨鏈通訊，未來將建立自己的去中心化的跨幣交易所。',
    engineTitle: '高效處理引擎',
    engineSubtitle: 'ICWChain還採用了超並行交易處理引擎，允許網路同時處理數千個智慧合約呼叫。',

    ICWTechLight1: 'ICWChain 技術',
    ICWTechLight2: '亮點',

    ICWTechLightSub: 'ICWChain身為新一代的公有鏈底層基礎設施，採取PoW+DPOS混合共識機制，在技術層面設計了高度模組化且極具擴充性的應用層構造，讓跨鏈通訊、平行鏈建置、鏈外資訊儲存更加便利，實現了鏈上撮合、秒級出塊。',
    techIntroduction: '技術介紹',
    succeed: '成功',
    whitePaperCn: 'ICWChain 白皮書 中文版',
    whitePaperEn: 'ICWChain 白皮書 英文版',
    decentralizationSubContent1: '去中心化——DPOS+POW混合共識機制',
    decentralizationSubContent2: 'ICWChain社群成員透過投票選舉節點，選舉節點透過算力競爭超級節點席次，並獲得該輪記賬權；記賬節點完成區塊打包後結束本次記賬任務，然後透過上述流程選舉新一輪超級節點，並進入新一輪記賬。',
    decentralizationSubContent3: '高性能、低延遲——百萬TPS，秒級驗證',
    decentralizationSubContent4: '在已經保證去中心化程度的前提下，進一步提高交易處理效率，最快可達秒級交易速率。',
    decentralizationSubContent5: '安全—— 雙重共識防禦，提高作惡難度',
    decentralizationSubContent6: 'ICWChain採用混合共識機制對記帳組進行多次輪換選舉，增加節點作惡的難度和成本，從而保障用戶的數位資產的安全。',
    contractSubContent: 'ICWChain延伸了一種完全去中心化的鏈上撮合與交易訂單管理的解決方案。基於ICWChain獨創的通用交易智慧合約，將傳統聚合撮合的模塊中所有交易對拆分成各自的模塊（智慧合約），以實現鏈上資產的完全去中心化交易。當持有其他幣種的用戶在ICWChain的代幣上編寫並發起合約時，需調用ICWChain通用交易智慧合約並綁定一個或多個交易對，且交易對的幣種需為ICWChain上發行的合約幣進行交易，與此同時，通用交易合約自帶撮合交易功能，用戶可直接在合約中掛單，完成鏈上資產的去中心化交易。',
    contractSubContent1: 'ICWChain延伸出一種完全去中心化的鏈上撮合與交易訂單管理的解決方案，基於ICWChain獨創的通用交易智能合約，將傳統聚合撮合的模塊中所有交易對拆分成各自的模塊（智能合約），以實現鏈上資產的完全去中心化交易。',
    contractSubContent2: '當持有其他幣種的用戶在ICWChain的代幣上編寫並發起合約，需調用ICWChain通用交易智能合約並綁定一個或多個交易對，且交易對的幣種需為ICWChain上發行的合約幣進行交易，與此同時，通用交易合約自帶撮合交易功能，用戶可直接在合約中掛單，完成鏈上資產的去中心化交易。',
    highlyModularSubContent: 'ICWChain所參考的NULS模組化基礎架構，屬ICWChain生態最根基的部分。NULS的模組架構具有熱插拔、高效、長久、不過時等特性。良好的模組化能夠大大降低區塊鏈的整體複雜度，使鏈的設計、調試和維護變得簡單化；各個模組仍可保持獨立運行。NULS模組提供了標準定義“Module”，所有ICWChain的模組都遵循NULSModule模組定義。ICWChain模組管理器負責管理所有模組，定義了模組加載/卸載、服務註冊等規範，凡是遵循NULSModule規範的擴展模組都可以通過模組管理器加載到ICWChain節點運行。',
    highlyModularSubContent1: 'ICWChain 所參考的 NULS 模組化基礎架構，是 ICWChain 生態系統最基本的組成部分。NULS 的模組架構擁有熱插拔、高效率、長久持續、不會過時等特性。良好的模組化設計可以大幅降低區塊鏈的整體複雜度，讓鏈的設計、偵錯和維護變得更為簡便；各個模組依然可以保持獨立運行。NULS 模組提供了標準定義“Module”',
    highlyModularSubContent2: '所有 ICWChain 模組皆遵循 NULS 模組定義。ICWChain 模組管理器負責管理所有模組，並定義了模組加載/卸載、服務註冊等規範。任何符合 NULS 模組規範的擴展模組都可以透過模組管理器加載到 ICWChain 節點中。',
    crossChainCommunicationSubContent: 'ICWChain 設計開發了聯盟鏈跨鏈閘道，通過該閘道實現聯盟鏈與主流公有鏈之間的資料和 Token 的互聯互通。透過 IBC 協議實現 Token 的跨鏈流轉。',
    engineSubContent: 'ICWChain 採用超並行交易處理引擎，允許網絡同時處理數千個智慧合約呼叫。透過其流水線基礎設施，ICWChain 還能夠更快地驗證交易並跨節點複製交易訊息，不論其硬體規格如何。',
    techRoadMap1: '多應用納入TridentChain生態系',
    techRoadMap2: '推廣TridentChain，提升知名度，持續發展TridentChain社群',
    techRoadMap3: '在前20名CMC交易所中的3-4家推出，以提高代幣流動性。',
    techRoadMap4: '啟動開發者活動、競賽和獎勵，吸引開發者參與生態系統建設；支援DApps以更多技術和資源，增加生態系統活動。',
    techRoadMap5: '加強與跨鏈夥伴的合作，以推進區塊鏈生態系統的發展。',
    techRoadMap6: '推動更多應用接入TridentChain生態系統',

    ICWCHAIN: 'TridentChain ',
    adopts: 'TridentChain 是一條模組化公鏈，採用 POW+DPOS 共識機制，致力於為區塊鏈生態系統的可持續增長與發展提供強大支持。我們的節點在實現這一願景中扮演著至關重要的角色。',

    ecologicalNetTit: 'ICWChain生態網絡',
    ecologicalNetTit1: 'TridentChain 生態系統',
    ecologicalNetTit2: '網路',

    ecologicalSub: 'ICWChain擁有一個由所有社群成員在“願景共識、權益共識、價值共識、治理共識、收入共識、技術共識”的基礎上發展起來的、完整的“自由平等、民主自治、合作共享”的多元化社會網絡生態系統。',
    ecologicalSub1: 'TridentChain是一個蓬勃發展的社交網絡生態系統，其核心價值觀是“擁抱自由平等、維護民主與自治、促進合作與共享”。讓我們團結起來，打造開放網路的未來。',
    ecologicalAppTit: 'ICWChain生態應用',

    API:'API文件',
    NodeCreation:'創建節點文件',

    Find1: '發現TridentChain上最熱門的項目',
    Find2: '鏈?',
    development: '生態平衡健康發展',
    ICWNode: 'TridentChain節點',
    incentive: '節點招募',
    section: '什麼是TridentChain節點？',
    bookkeeping: 'TridentChain 節點是網絡的基礎構件，負責維持網路的安全性、穩定性與去中心化。這些節點構成了我們生態系統的核心，確保一切能夠正常運作。',
    ServiceNode: '節點建立與質押',
    servicenode: '創建一個TridentChain 節點需求1000個 TDC（TridentChain 代幣）作為保證金。每個節點的最低質押金額為3000 TDC，而最高質押金額為30,000 TDC。',
    Grossincome: '記帳組與獎勵機制',
    Grossincomes: '每輪記帳組合由27個節點組成，在一個記帳週期（45分鐘）輪流出塊（每個節點10個塊，共270個塊），每個區塊生成確認時間為10秒。初始情況下，每個區塊獎勵為10枚TDC，之後每年遞減15%。這一動態獎勵機制確保了新代幣的有序發行，以支援 TridentChain 網絡的長期可持續發展。',

    ecologicalAppTitle1: '數字身份與可信網路',
    ecologicalAppTitle2: '金融領域與 DeFi',
    ecologicalAppTitle3: '去中心化社交',
    ecologicalAppTitle4: '電商與跨境支付',
    ecologicalAppTitle5: '版權保護與 NFT',
    ecologicalAppTitle6: '慈善公益',
    ecologicalContent1: 'ICWChain 區塊鏈分散式儲存系統將眾多普通伺服器連接在一起，透過網絡連線提供整體儲存服務，利用區塊鏈式數據結構進行數據驗證與儲存，並運用分散式節點共識算法產生及更新數據，維護完整、有效且安全的資料庫。',
    ecologicalContent2: 'ICWChain 正在重新定義數字世界的資產概念，並確保交易行為的保障。ICWChain 區塊鏈網絡將接入並建構主流可信金融產業區塊鏈系統，透過 Token 安全轉移與兌換機制，實現金融產業各類應用的協同合作。',
    ecologicalContent3: 'ICWChain 透過區塊鏈技術為內容社交平台注入新活力，Eseeworld 作為 ICWChain 生態應用中的重要一員，利用分散式管理將平台去中心化。',
    ecologicalContent4: 'ICWChain 將區塊鏈技術應用於證券交易中，各參與方便可獨立完成整個結算流程。ICWChain 將點對點的技術應用於跨境支付領域後，匯款通道變得透明，交易歷史數據可追溯，讓收款方、匯款方都安心，也大大提升了效率與速度。',
    ecologicalContent5: 'ICWChain依靠區塊鏈網絡本身的特性，為元宇宙中的稀有屬性、稀有資產提供證明及確權，從而實現數據內容的價值流轉。通過映射數字資產，用戶在“元宇宙”世界裡的裝備道具、土地產權等等也將擁有可交易的實體。',
    ecologicalContent6: '區塊鏈技術能顯著提升慈善領域的信息透明度，讓捐贈者有權監督慈善體系與流程，同時保障了捐贈方與受贈方的隱私。',

    mission: 'ICWChain 使命',
    mission1: 'ICWChain',
    mission2: '使命',

    contactSub: '相較於中心化的互聯網平台，區塊鏈擁有更穩定、更高的抗風險能力、更公平且更簡單的特點。ICWChain革新了資訊交換的方式，解決了以中心化思維難以突破的應用難題，推進了數字經濟建設的實現，讓web3.0與分布式思維緊密結合在一起。',

    vipkefu: 'VIP專屬客服(微信)',
    community: 'ICWChain官方社區',
    Telegram: 'Telegram',
    Twitter: 'Twitter',

    recruitTit: 'ICW創世節點全球節點招募',
    userManual: '用戶手冊',
    selfCreation: '自主創建',
    replaceCreation: '代建節點',
    agentConstruction: '代建節點',
    installtion: '節點安裝包',
    account: '請輸入您的名字或者企業名稱',
    mobileAccount: '請輸入您的手機號碼',
    emailAccount: '請輸入您的聯絡郵箱',
    infoText1: '用戶可透過平台購買伺服器託管服務，享受1V1客戶服務。',
    infoText2: '客戶填寫購買意向單之後，客戶經理將發送郵件指引客戶完成後續伺服器購買操作',
    thirdPartyServices: '本服務由第三方ESEEWORLD提供',
    apply: '立即申請',
    advTitle1: 'ICWChain公鏈主網即將上線',
    advTitle2: '現面向全球進行創世節點招募',
    advContent1: 'ICWChain是一條既考慮到去中心化、高效能與資料安全的創新型公有鏈網絡。',
    advContent2: 'ICWChain將作為第三代互聯網科技革命浪潮中的創新者與領航員，致力於推動全球數位經濟體系的建設，這既是其前進的動力也是最終的使命。',
    nrTitle: '節點招募期',
    ndTitle: '節點部署期',
    nrTime: '5月26日  下午12：00-6月15日',
    ndTime: '6月15日  下午12：00-6月20日',
    ndTime1: '節點上線及運行',
    nrContetn1: '•     用戶可以透過平台購買伺服器托管服務，提供一對一客戶服務。',
    nrContetn2: '•     每三個月的托管服務將獲得20,000個ICW（價值20u）的獎勵，多購多得，早加入早受益，詳情可諮詢客服。',
    ndContetn1: '• 節點招募期間，通過諮詢客戶經理購買托管伺服器的用戶，將可享受ICW空投獎勵，該獎勵可直接用於創建節點的質押。',
    recruitmentTit: 'ICW創世節點獎勵計畫與收益',
    accountingTitle: '記賬組',
    accountingContent: '記賬組由15個共識節點組成，輪詢出塊，出塊時間為6秒，每15分鐘作為一個記賬週期（每個節點出10個塊，共150個塊），每天出14,400塊。出塊獎勵每4年減半，初始出塊獎勵為100枚/塊。初始每天獎勵144萬枚，每年5.256億枚，21.024億減半',
    nodeTitle: '服務節點',
    nodeContent: '服務節點提供同步賬本服務，每記賬週期（15分鐘）獎勵一次，每天獎勵96次；同步獎勵每4年減半，初始7500枚/記賬週期（所有同步節點平均分配）。初始每天獎勵72萬枚，每年2.628億枚，10.512億減半。',
    profitTitle: '總收益',
    profitContent: '若按節點質押達到50萬ICW，且必然出塊的先決條件下，1分半出一個塊，收益為100ICW/塊，1小時預計收益4,000ICW，一天預計收益96,000ICW，一年預計收益35,040,000ICW，則預計可獲得約70倍左右的回報。',
    nodeCreation: '節點創建',
    qualification: '節點創建資格',
    process: '節點創建流程',
    pledge: '節點質押',

    // create global world
    networkEnvironmentSettings: '網絡環境設定',
    deposit: '保證金≥20,000枚ICW',

    edaos: 'EDAO發展基金會',
    edao: 'EEDAO基金會致力於支援ICWChain協議、去中心化網絡以及開發者生態系統的成長與發展。',
    Social: '官方社交平台',
    Follow: '關注我們，瞭解更多關於TDC的訊息',
    Discord: 'Discord是一款語音與文字聊天應用，web3主流社區聊天通訊軟體。',
    github: '線上軟體原始碼託管服務平台。',
    Twitters: '致力於為公眾對話服務。',
    ljgd: '加入我們',
    Telegrams: '世界上最受歡迎的跨平台即時通訊軟體之一。',
    voice: '用戶更專注於遊戲本身，流暢的語音佔用更少的CPU',
    safe: '既安全又免費',
    Support: '同時支持大量用戶在線交流，標準化興趣社區',

    serverSetup: '伺服器搭建',
    serverSetup1: 'A.自主配置網路',
    serverSetup2: 'B.伺服器代建',
    loginWallet: '登入ICW錢包',
    fillinInfo: '填寫節點資訊',
    depositPayment: '繳納保證金',
    creationSucceeded: '節點創建成功等待質押完成',
    ICWPledge: '進行ICW質押',
    nodeOperation: '質押量超過二十萬枚ICW，節點即可運行',
    more: '您想知道更多關於ICW Chain的資訊',

    // 升級彈窗
    DearICWChainPartner: '尊敬的ICWChain的合作夥伴：',
    OK: '知道了',
    popupDate: 'ICWChain將於2022年9月24日9:00-18:00（UTC/GMT+08:00）進行網絡升級，',
    popupWarn: '升級期間建議不要進行有關資產轉移的任何操作！',
    popupText1: '本次升級內容主要包括：優化難度共識算法、優化區塊打包效率、優化節點投票共識。',
    popupText2: '本次升升級完成後，記帳組節點出塊時間將調整為12秒/區塊（即記帳組輪換調整為1800秒/周期）',
    popupText3: '出塊獎勵調整為200ICW/區塊，同步共識獎勵調整為15000ICW/記帳組周期。',
    popupText4: '同時，本次升級完成後，ICWChain將支援USDT-IRC20的發行。',

    // 头版
    hbtitle: '超級以太坊籌集（第一期）',
    // hbtime: '2022.11.04 14:00:00 ~ 2022.11.10 09:59:59',
    hbtime: '2022.11.10 10:00:00 ~ 2022.12.2 17:00:00',
    hbBtn: '瞭解詳情',
    // hbuy: '立即購買',
    hbuy: '已售罄',
    // 彈窗
    name: 'SETH CHAIN',
    diallogtext1: '全新DPOS+POW混合共識機制',
    diallogtext2: '優化以太坊共識',
    diallogtext3: '支援以太坊礦機和虛擬機',
    diallogtext4: '促進原以太坊礦工遷移和鏈上生態建設',
    dialogT: '籌集第一期',
    begin: '起：',
    dialogStart: '2022.11.10 10:00:00',
    end: '止：',
    dialogEnd: '2022.11.25 17:00:00',
    dialogInfo: '20U ',
    dialogOline: '主網上線：',
    dialogOlineTime: '2023年3月',
    dialogKnow: '好的',
    address: '地址',
    city2: '馬來西亞',
    city2Ad: '加蘭·梅迪卡市納閩金融公園主辦公大樓15層',
    city1: '新加坡',
    city1Ad: '73 Upper Paya Lebar Road #06-01C Centro Bianco Singapore 534818',
    gmail: '官方郵箱',
    emailAddress: 'admin@icwchain.com'
}
