<!-- 生态 -->
<template>
  <div class="ecological">
    <div class="advantage">
      <div class="w">
        <img class="work1" src="../assets/images/work1.png" alt="">
        <div class="title">
          {{ "ecologicalNetTit1" | t }}
        </div>
        <div class="adv-info">
          {{ "ecologicalSub1" | t }}
        </div>

        <img class="work2" src="../assets/images/work2.png" alt="">
        <img class="work3" src="../assets/images/work3.png" alt="">
      </div>
    </div>
    <div class="ec-main">
      <div class="w">
        <div class="ecTitle">
          <h2>{{ "Find1" | t }} </h2>
        </div>
        <div class="ecWrap">

          <!-- <ul class="ecWrapList">
            <li v-on:click='change(index)' :class='currentIndex == index ? "active" : ""' :key='item.id'
              v-for='(item, index) in ecWrapList'>{{ item.title }}</li>

          </ul> -->
          <!-- <div class="ecContent">
            <ul :class='currentIndex == index ? "current" : ""' :key='item.id' v-for='(item, index) in ecWrapList'>
              <a :href="item.chirlden.href1"><img :src="item.chirlden.path1" alt=""></a>
              <span v-if="item.chirlden.path2">
                <a :href="item.chirlden.href2"><img :src="item.chirlden.path2" alt=""></a>
              </span>
            </ul>
          </div> -->
          <div class="ecContent">
            <ul>
              <li v-for='(item, index) in ecWrapList' :key='item.id'>
                <a :href="item.href" target="_blank"><img :src="item.path" alt=""></a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wallet from '@/assets/images/wallet.png'
import BitHive from '@/assets/images/BitHive.png'
import Swap from '@/assets/images/Swap.png'
import explor from '@/assets/images/explor.png'
// import { getBlockheight } from '@/utils/api'

export default {
  name: 'ecological-page',
  data() {
    return {
      currentIndex: 0,
      ecWrapList: [{
        id: 1,
        path: BitHive,
        href: 'https://www.bithive.cc/'
      },
      {
        id: 2,
        path: wallet,
        href: 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/TridentWallet.zip',
      }, {
        id: 3,
        path: Swap,
        href: 'http://tdc.antswap.co/#/home'
      }, {
        id: 4,
        path: explor,
        href: 'https://browser.tridentchain.com/'
      }]
    }
  },
  created() {
    this.getBlockheights()
  },
  methods: {
    jumper(nameOrURI) {
      const re = /^(ftp|https?):\/\//
      if (re.test(nameOrURI)) {
        if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
          if (window.localStorage.getItem('language') === 'zh-CN') {
            window.open('https://www.cgxyex.co/#/')
          } else {
            window.open('https://www.cgxyex.com/#/')
          }
        } else {
          window.open(nameOrURI)
        }
      } else {
        const uri = this.$router.resolve({ name: nameOrURI }).href
        location.href = uri
      }
    },
    // downloadPDF(url) {
    //     // const lang = this.$store.getters.getLanguage
    //     // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
    //     // const pdfUrl = 'http://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
    //     downloadjs(url)
    // },
    change: function (index) {
      this.currentIndex = index
    },
    // async getBlockheights() {
    //   this.randomNumbers = Math.round(Math.random() * 9999)
    //   const { data: res } = await getBlockheight()
    //   console.log('res', res)
    //   this.Download = res.data
    //   this.ecWrapList[0].href = this.Download
    // }
  }
}
</script>
<style lang="less" scoped>
.ecological {
  background: url("../assets/images/bg1.png") no-repeat center top !important;

  .advantage {
    font-family: 'MiSans';
    width: 100%;
    height: 980px;
    margin: 0 auto;

    .w {
      position: relative;
    }

    .title {
      width: 1200px;
      position: absolute;
      font-weight: 700;
      font-size: 64px;
      color: #FFFFFF;
      text-align: center;
      top: 360px;
      left: 0;

      b {
        color: #FFFFFF;
      }
    }

    .adv-info {
      position: absolute;
      width: 800px;
      text-align: center;
      font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
      font-size: 24px;
      line-height: 30px;
      color: #FFFFFF;
      top: 500px;
      left: 200px;
    }

    .work1 {
      position: absolute;
      top: 180px;
      left: 225px;
    }

    .work2 {
      position: absolute;
      top: 620px;
      left: 10px;
    }

    .work3 {
      position: absolute;
      top: 680px;
      right: 0px;
    }
  }

  .ec-main {
    width: 100%;
    height: 806px;
  }

  .ecTitle {
    font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
    color: #FFFFFF;
    width: 654px;
    text-align: center;
    margin: 0 auto;
    padding-top: 100px;

    h2 {
      font-size: 36px;
      color: #FFFFFF;
    }

    h5 {
      margin-top: 20px;
      font-weight: normal;
      color: #FFFFFF;
      font-size: 16px;
    }
  }

  .ecWrap {
    font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
    margin-top: 120px;
    text-align: center;

    .active {
      background-color: #246EFF;
      color: #FFFFFF;
    }

    .ecContent {
      margin-top: 100px;
      width: 1200px;
      height: 500px;

      ul {

        li {
          display: inline-block;
          width: 260px;
          height: 200px;
          margin-right: 28px;
          border-radius: 20px;
          background-color: rgba(0, 0, 0, 0.3);
          cursor: pointer;

          a {
            display: block;
            width: 260px;
            height: 200px;

            img {
              width: 260px;
              height: 200px;
            }
          }
        }
      }

      // ul:nth-child(2){
      //   padding-left: 10px;
      // }

      // .current {
      //   display: block;
      //   text-align: center;
      //   // padding-left: 350px;
      // }

      // a {
      //   padding-left: 10px;
      //   // float: left;
      //   // margin-right: 20px;
      //   // display: block;
      //   cursor: pointer;

      //   img {
      //     width: 360px;
      //     height: 158px;

      //   }

      //   img[src=""],
      //   img:not([src]) {
      //     opacity: 0;
      //   }
      // }
    }
  }
}
</style>
<!-- 移动端 -->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
  .ecological {
    background: url('../assets/images/shift-bg3.png') no-repeat !important;
    background-size: cover !important;

    .advantage {
      // background: url('../assets/images/shift-bg3.png') no-repeat center;
      background-size: 7.5rem 6.64rem;
      height: 11rem;

      .work1 {
        width: 1.67rem;
        left: 0.95rem;
        top: 1.81rem;
      }

      .work2 {
        width: 1.94rem;
        left: 0.17rem;
        top: 7.21rem;
      }

      .work3 {
        width: 1.97rem;
        left: 5.35rem;
        top: 8.23rem;
      }
    }

    .w {
      width: 100%;
      margin: 0 auto;

      .title {
        width: 4.93rem;
        font-size: 0.74rem;
        top: 2.69rem;
        left: 1.29rem;

        b {
          top: 4.97rem;
          left: 2.07rem;
        }
      }

      .adv-info {
        width: 5.08rem;
        height: 0.94rem;
        line-height: 0.38rem;
        font-size: 0.24rem;
        top: 5.37rem;
        left: 1.06rem;
        color: #FFFFFF;
        font-weight: normal;
      }
    }

    .ec-main {
      width: 100%;
      height: 10.83rem;

      .w {
        width: 7.22rem;

        .ecTitle {
          font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
          color: #081022;
          width: 100%;
          text-align: center;
          margin: 0 auto;
          padding-top: 0.40rem;

          h2 {
            font-size: 0.4rem;
          }

          em {
            display: block;
            width: 0.52rem;
            height: 0.01rem;
            margin: 0.2rem auto;
            text-align: center;
            background-color: #94A5C5;
          }

          h5 {
            font-weight: normal;
            color: #FFFFFF;
            font-size: 0.2rem;
          }
        }

        .ecWrap {
          margin-top: 0.15rem;
          text-align: center;

          .ecContent {
            margin-top: 0.5rem;
            width: 100%;
            height: 8rem;

            li {
              width: 3.31rem;
              height: 2.66rem;
              margin-right: 0.17rem;
              border-radius: 0.16rem;
              margin-top: 0.37rem;
            }

            a {
              width: 3.31rem;
              height: 2.66rem;
              // float: left;
              margin: 0 0.12rem;
              cursor: pointer;

              img {
                width: 3.31rem;
                height: 2.66rem;
                border-color: none;
              }
            }

            img:not([src]) {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
</style>
