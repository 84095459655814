export default {
    home: '홈',
    technology: '기술',
    ecological: '생태계',
    browser: '블록체인 브라우저',
    recruitment: '노드',
    wallet: '지갑',
    contact: '문의하기',
    joinUs: '함께하기',
    partner: '협력 파트너',
    language: '언어',
    learnMore: '더 알아보기',
    company: 'EDAO DEVELOPMENT FOUNDATION LTD',
    funds: 'ICW 기금',
    lucky: '행운의 룰렛',
    hashGuess: '해시 경연',
    prize: '백 퍼센트 승리, 거대한 상금 풀',
    prizeBtn: '시작하기',

    White: '백서 다운로드',
    Introduction: 'TridentChain 소개',

    Numbertransactions: '거래 수',
    Blockheight: '블록 높이',
    Coretoken: '총 스테이크',

    aboutICW: 'TridentChain 에 대하여',
    whitePaper: '백서',
    ICWwhitePaper: 'TDCChain 백서',
    technologyFunctions: '기술 및 기능',
    roadmap: '로드맵',
    map: '로드맵',

    welcometoICW: 'TDC CHAIN 에 오신 것을 환영합니다',
    bannerText: '깊은 바다의 블록체인 새 방향 탐색.',
    bannerText2: 'TridentChain 고급 사용자, 신규 소비자, 그리고 그 사이의 모든 사람의 경험을 지원합니다!.',
    whitePaperDownload: '백서 다운로드 링크',
    whychoose: '왜 선택하는가',
    aspires: 'ICW는 WEB3.0의 항해사가 되고자 합니다',
    decentralizationTitle: '탈중앙화, 보안 및 고성능',
    decentralizationTitle1: '탈중앙화, ',
    decentralizationTitle2: '보안 및 고성능',
    decentralizationSubtitle: '트라이던트체인은 블록체인 네트워크의 탈중앙화와 커뮤니티의 공정성을 높이기 위해 노드의 참여 임계값을 높이고 계산 그룹의 무작위성을 증가시키는 PoW+DpoS 하이브리드 합의 모델을 채택합니다.',
    contractTitle: '일반 거래 스마트 계약',
    contractSubtitle: "TridentChain은 전 세계 개발자 커뮤니티가 주도하는 분산형 퍼블릭 체인입니다. 빠르고, 에너지 효율적이며, 신뢰할 수 있습니다. 개발자들에게 강력한 오픈 환경을 제공합니다.",
    highlyModularTitle: '고도로 모듈화됨',
    highlyModularSubtitle: 'TridentChain은 NULS 모듈 아키텍처를 참조하여, 핫 스와핑, 고효율, 장수명, 시대를 초월한 특성을 갖추고 있습니다. 이는 블록체인의 전체 복잡성을 크게 줄이고, 설계, 디버깅 및 유지 관리 과정을 간소화합니다. ',
    crossChainCommunicationTitle: '크로스 체인 커뮤니케이션',
    crossChainCommunicationSubtitle: '미래의 크로스 통화 교환을 지원하기 위해 크로스 통화 커뮤니케이션 체인을 구축합니다.',
    engineSubtitle: 'ICWChain 또한 초병렬 트랜잭션 처리 엔진을 채택하여, 네트워크가 동시에 수천 개의 스마트 계약 호출을 처리할 수 있습니다.',

    ICWTechLight1: 'ICWCHAIN 기술',
    ICWTechLight2: '하이라이트',

    ICWTechLightSub: 'ICWChain은 DPOS + POW 하이브리드 합의 메커니즘을 채택하고 기술 수준에서 매우 모듈화되고 확장 가능한 어플리케이션 계층 구조를 설계하여, 체인 간 통신, 병렬 체인 구축 및 체인 외 정보 저장을 더욱 편리하게 만듭니다.',
    techIntroduction: '기술 소개',
    succeed: '성공',
    whitePaperCn: 'ICWChain 백서 - 중국어',
    whitePaperEn: 'ICWChain 백서 - 영어',
    decentralizationSubContent1: '탈중앙화 - DPOS+POW 하이브리드 합의 메커니즘',
    decentralizationSubContent2: 'ICWChain 커뮤니티 구성원들은 투표를 통해 선거 노드를 선출하고, 선거 노드들은 연산력 경쟁을 통해 슈퍼 노드 자리를 경쟁하며 해당 라운드의 회계 권한을 얻습니다; 회계 노드는 블록 포장을 완료한 후 이 회계 작업을 종료합니다. 그런 다음 위의 과정을 통해 새로운 라운드의 슈퍼 노드를 선택하고 새로운 회계 라운드에 진입합니다.',
    decentralizationSubContent3: '고성능 및 저지연 - 백만 TPS, 초 단위 검증',
    decentralizationSubContent4: '탈중앙화의 정도를 보장하는 전제 하에, 거래 처리 효율성을 더욱 향상시키고, 가장 빠른 거래 속도는 초 단위에 도달할 수 있습니다.',
    decentralizationSubContent5: '보안 - 이중 합의 방어 및 악행의 난이도 향상',
    decentralizationSubContent6: "ICWChain은 하이브리드 합의 메커니즘을 채택하여 회계 그룹에 대한 여러 번의 로테이션 선거를 실시하여, 노드에서 악행을 저지르는 난이도와 비용을.",
    contractSubContent: 'ICWChain은 체인 내 매칭과 거래 순서 관리를 위한 완전히 탈중앙화된 솔루션을 확장했습니다. ICWChain이 원래 만든 일반 거래 스마트 계약을 기반으로, 전통적인 집합 매칭 모듈의 모든 거래 쌍이 자체 모듈(스마트 계약)로 나뉘어져, ICWChain의 토큰에 대한 계약을 작성하고 시작하는 다른 화폐를 가진 사용자들이 체인상에서 자산의 완전히 탈중앙화된 거래를 실현할 수 있게 됩니다. 이러한 사용자들은 ICWChain 일반 거래 스마트 계약을 호출하고 하나 이상의 거래 쌍을 바인딩해야 하며, 거래 쌍의 화폐는 거래를 위해 ICWChain에 발행된 계약 화폐여야 합니다. 동시에, 일반 거래 계약에는 자체 매칭 거래 기능이 있으며, 사용자는 계약에 직접 주문을 걸어 체인상의 자산 거래를 탈중앙화하여 완료할 수 있습니다.',
    contractSubContent1: 'ICWChain은 체인 내 매칭과 거래 순서 관리를 위한 완전히 탈중앙화된 솔루션을 도입했습니다. ICWChain에 의해 개발된 기초 거래 스마트 계약을 활용하는 이 접근 방식은 전통적인 집합 매칭 모듈에서 거래 쌍을 독립된 모듈(스마트 계약)로 분리함으로써, 체인상의 자산 거래를 완전히 탈중앙화할 수 있게 합니다.',
    contractSubContent2: 'ICWChain의 토큰에 대한 계약을 작성하고 시작하는 다른 화폐를 가진 사용자들은 ICWChain 일반 거래 스마트 계약을 호출하고 하나 이상의 거래 쌍을 바인딩해야 하며, 거래 쌍의 화폐는 거래를 위해 ICWChain에 발행된 계약 화폐여야 합니다. 동시에, 일반 거래 계약은 자체 매칭 거래 기능을 갖추고 있으며, 사용자는 계약에 직접 주문을 걸어 체인상의 자산 거래를 탈중앙화하여 완료할 수 있습니다.',
    highlyModularSubContent: 'ICWChain이 참조하는 NULS 모듈식 인프라는 ICWChain 생태계의 가장 기본적인 부분입니다. NULS의 모듈 아키텍처는 핫 플러그, 고효율, 장기간, 시간 제약 없음 등의 특성을 가지고 있습니다. 좋은 모듈성은 블록체인의 전체 복잡성을 크게 줄이고 블록체인의 설계, 디버깅 및 유지 보수를 단순화할 수 있습니다. 각 모듈은 여전히 독립적으로 운영할 수 있으며 NULS 모듈은 “모듈”의 표준 정의를 제공합니다. 모든 ICWChain 모듈은 NULS 모듈 사양을 준수합니다. ICWChain 모듈 관리자는 모든 모듈의 관리를 감독하고 모듈 로딩/언로딩 및 서비스 등록과 같은 작업에 대한 사양을 개요합니다. NULS 모듈 사양을 준수하는 모든 확장 모듈은 모듈 관리자를 통해 ICWChain 노드에 로드될 수 있습니다.',
    highlyModularSubContent1: 'ICWChain이 참조하는 NULS 모듈식 인프라는 ICWChain 생태계의 가장 기본적인 부분입니다. NULS의 모듈 아키텍처는 핫 플러그, 고효율, 장기간, 시간 제약 없음 등의 특성을 가지고 있습니다. 좋은 모듈성은 블록체인의 전체 복잡성을 크게 줄이고 블록체인의 설계, 디버깅 및 유지 보수를 단순화할 수 있습니다. 각 모듈은 여전히 독립적으로 운영할 수 있으며 NULS 모듈은 “모듈”의 표준 정의를 제공합니다.',
    highlyModularSubContent2: '모든 ICWChain 모듈은 NULS 모듈 정의를 따릅니다. ICWChain 모듈 관리자는 모든 모듈을 관리하고 모듈 로딩/언로딩 및 서비스 등록과 같은 사양을 정의하는 책임이 있습니다. NULS 모듈 사양을 따르는 모든 확장 모듈은 모듈 관리자를 통해 ICWChain 노드에 로드될 수 있습니다.',
    crossChainCommunicationSubContent: 'ICWChain은 동맹체인의 크로스 체인 게이트웨이를 설계하고 개발하였습니다. 이 게이트웨이를 통해, 동맹체인과 주류 공공체인 사이의 데이터 및 토큰의 상호 연결이 실현됩니다. 토큰의 크로스 체인 흐름은 IBC 프로토콜을 통해 실현됩니다.',
    engineSubContent: 'ICWChain은 초병렬 거래 처리 엔진을 채택하여, 네트워크가 동시에 수천 개의 스마트 계약 호출을 처리할 수 있게 합니다. 파이프라인 인프라를 통해 ICWChain은 또한 하드웨어 사양에 관계없이 거래를 더 빠르게 검증하고 노드 간에 거래 정보를 복사할 수 있습니다.',
    techRoadMap1: '다수의 애플리케이션이 TridentChain 생태계에 통합됨',
    techRoadMap2: 'TridentChain을 홍보하고 브랜드 인지도를 높이며, TridentChain 커뮤니티를 계속 발전시킴',
    techRoadMap3: '토큰 유동성을 증대하기 위해 상위 20개 CMC 거래소 중 3-4곳에서 론칭합니다.',
    techRoadMap4: '개발자들이 생태계 구축에 참여하도록 유도하기 위해 개발자 이벤트, 대회 및 보상을 시작합니다; 기술과 자원을 더 많이 지원하여 DApps의 활동을 증가시킵니다.',
    techRoadMap5: '블록체인 생태계를 발전시키기 위해 크로스체인 파트너와의 협업 강화',
    techRoadMap6: '더 많은 애플리케이션이 TridentChain 생태계에 참여하도록 장려',
    ICWCHAIN: 'TridentChain',
    adopts: 'TridentChain은 POW+DPOS 합의 메커니즘을 채택한 모듈식 공용 블록체인으로, 블록체인 생태계의 지속 가능한 성장과 발전을 강력하게 지원하기 위해 헌신합니다. 우리의 노드는 이 비전을 달성하는 데 중요한 역할을 합니다.',

    ecologicalNetTit: 'ICWCHAIN 생태계 네트워크',
    ecologicalNetTit1: 'TridentChain 생태계',
    ecologicalNetTit2: '네트워크',
    ecologicalSub: 'ICWChain은 "비전 공감, 권리와 이익 공감, 가치 공감, 거버넌스 공감, 수익 공감 및 기술 공감"의 기반 위에 모든 커뮤니티 멤버가 개발한 완전한 "자유와 평등, 민주주의와 자치, 협력과 공유"의 다양한 소셜 네트워크 생태계를 가지고 있습니다.',
    ecologicalSub1: "TridentChain은 '자유와 평등을 포용하고, 민주주의와 자치를 지지하며, 협력과 공유를 촉진하는' 가치를 핵심으로 하는 번영하는 소셜 네트워크 생태계로 자리매김하고 있습니다. 함께 열린 웹의 미래를 만들어 나갑시다.",
    ecologicalSub2: "함께 열린 웹을 구축합시다.",
    ecologicalAppTit: 'ICWChain 생태계 애플리케이션',

    API: 'API 문서',
    NodeCreation: '노드 생성 문서',

    Find1: 'TridentChain에서 가장 핫한 프로젝트를 발견하다',
    Find2: 'CHAIN?',
    development: '균형 잡히고 건전한 생태계 개발',
    ICWNode: 'TridentChain 노드',
    incentive: '노드 모집',
    section: 'TridentChain 노드란 무엇인가?',
    bookkeeping: 'TridentChain 노드는 네트워크의 보안, 안정성 및 분산화를 유지하는 데 책임이 있는 네트워크의 기본 구성 요소입니다. 이러한 노드들은 우리 생태계의 핵심을 구성하며, 원활한 운영을 보장합니다.',
    ServiceNode: '노드 생성 및 스테이킹',
    servicenode: 'TridentChain 노드를 생성하려면 1000 TDC(TridentChain 토큰)의 예치금이 필요합니다. 노드 당 최소 스테이킹 금액은 3000 TDC이며, 최대 스테이킹 한도는 30,000 TDC입니다.',
    Grossincome: '회계 그룹 및 보상 메커니즘',
    Grossincomes: '회계 그룹의 한 라운드는 27개의 노드로 구성됩니다. 한 회계 기간(45분) 동안, 라운드 로빈 방식을 통해 블록이 생성됩니다(각 노드가 10개의 블록을 생산하여 총 270개의 블록). 각 블록의 확인 시간은 10초입니다. 초기에 각 블록은 10 TDC 토큰을 보상으로 하며, 이 보상은 매년 15%씩 감소합니다. 이 동적 보상 메커니즘은 TridentChain 네트워크의 장기 지속 가능성을 지원하기 위해 새 토큰의 질서 있는 발행을 보장합니다.',

    ecologicalAppTitle1: '디지털 신원 및 신뢰할 수 있는 네트워크',
    ecologicalAppTitle2: '금융 분야 및 DeFi',
    ecologicalAppTitle3: '분산형 소셜 네트워킹',
    ecologicalAppTitle4: '전자상거래 및 국경 간 결제',
    ecologicalAppTitle5: '저작권 보호 및 NFT',
    ecologicalAppTitle6: '자선',
    ecologicalContent1: 'ICWChain 블록체인 분산 스토리지 시스템은 다수의 일반 서버를 연결하여, 네트워크 연결을 통한 집합적 스토리지 솔루션을 제공합니다. 데이터 검증 및 저장을 위해 블록체인 데이터 구조를 사용하며, 데이터 생성 및 업데이트를 위해 분산 노드 합의 알고리즘을 활용합니다. 이는 포괄적이고 효율적이며 안전한 데이터베이스의 유지를 보장합니다.',
    ecologicalContent2: 'ICWChain은 디지털 세계에서 자산 개념을 재정의하고, 모든 형태의 자산을 대표하는 데이터 자산에 대한 온라인 권리 및 이익의 정의를 확립하는 동시에 거래 행위의 보호를 보장합니다. ICWChain 블록체인 네트워크는 금융 산업 내에서 주류이며 신뢰할 수 있는 블록체인 시스템에 접속 및 구축할 것입니다. 이는 안전한 토큰 전송 및 교환 메커니즘을 통해 금융 산업의 다양한 애플리케이션 간 협력을 촉진할 것입니다.',
    ecologicalContent3: 'ICWChain은 블록체인 기술을 통해 콘텐츠 소셜 플랫폼에 새로운 혈액을 주입합니다. ICWChain 생태계 애플리케이션의 중요한 구성원으로서, ESEEWORLD는 분산 관리를 사용하여 플랫폼을 분산화합니다.',
    ecologicalContent4: 'ICWChain은 블록체인 기술을 증권 거래에 적용하여, 각 참가자가 ICWChain이 국경 간 결제 분야에 점대점 기술을 적용한 후 독립적으로 전체 결제 과정을 완료할 수 있게 합니다. 송금 채널이 투명해지고 거래 이력 데이터를 추적할 수 있게 되어, 수취인과 송금인 모두를 안심시키는 것은 물론, 효율성과 속도를 크게 향상시킵니다.',
    ecologicalContent5: "블록체인 네트워크의 특성을 활용하여, ICWChain은 메타버스 내에서 희귀 속성 및 자산의 소유권 검증 및 확인을 가능하게 합니다. 이를 통해 사용자의 장비, 소품, 토지 소유권과 같은 자산의 디지털 표현을 매핑함으로써 데이터 콘텐츠의 가치 흐름을 가능하게 합니다. 결과적으로, 이러한 자산은 디지털 세계에서 거래 가능한 실체가 됩니다.",
    ecologicalContent6: '블록체인 기술은 자선 분야에서 정보의 투명성을 크게 향상시킬 수 있으며, 기부자에게 자선 시스템 및 프로세스를 감독할 권리를 주고, 기부자와 수혜자의 프라이버시를 보호합니다.',

    mission: 'ICWCHAIN의 미션',
    mission1: 'ICW CHAIN',
    mission2: '미션',
    contactSub: '중앙집중식 인터넷 플랫폼에 비해, 블록체인은 향상된 안정성, 우수한 위험 저항력, 더 큰 공정성 및 단순성을 제공합니다. ICWChain은 정보 상호작용 방식을 변화시켜, 중앙집중식 아이디어로는 해결할 수 없는 응용 프로그램의 도전을 극복하고 디지털 경제 구축의 구현을 촉진했습니다. 이는 분산된 아이디어와 Web3.0을 긴밀히 통합했습니다.',

    vipkefu: 'VIP 서비스（Wechat）',
    community: 'ICWChain 커뮤니티',
    Telegram: 'Telegram',
    Twitter: 'Twitter',

    recruitTit: 'ICW 제네시스 노드 모집',
    userManual: '사용자 매뉴얼',
    selfCreation: '자체 생성',
    replaceCreation: '대리 구축 서비스',
    agentConstruction: '대리 구축',
    installtion: '노드 설치 패키지',
    account: '이름 또는 단체 이름을 입력하세요',
    mobileAccount: '휴대폰 번호를 입력하세요',
    emailAccount: '이메일 주소를 입력하세요',
    infoText1: '사용자는 플랫폼을 통해 서버 호스팅 서비스를 구매하고 일대일 고객 서비스를 즐길 수 있습니다.',
    infoText2: '고객이 구매 의사 양식을 작성한 후, 계정 관리자가 이메일을 보내 고객이 후속 서버 구매 작업을 완료할 수 있도록 안내합니다',
    thirdPartyServices: '이 서비스는 제3자 ESEEWORLD에 의해 제공됩니다',
    apply: '즉시 신청하기',
    advTitle1: 'ICWChain 공용 체인 메인넷이 곧 출시될 예정입니다',
    advTitle2: '현재 전 세계적으로 제네시스 노드 모집 중입니다',
    advContent1: 'ICWChain은 분산화, 고성능 및 데이터 보안을 고려한 혁신적인 공용 체인 네트워크입니다.',
    advContent2: 'ICWChain은 제3세대 인터넷 과학 기술 혁명의 파도에서 혁신가이자 항해자가 되며, 글로벌 디지털 경제 시스템의 구축을 촉진하는 선도적 행동력과 궁극적인 미션으로 삼을 것입니다.',
    nrTitle: '노드 모집 기간.',
    nrTitle: '노드 모집 기간',
    ndTitle: '노드 배치 기간',
    nrTime: '5월 26일 오후 12시부터 6월 15일까지',
    ndTime: '6월 15일 오후 12시부터 6월 20일까지',
    ndTime1: '노드 시작 및 실행',
    nrContetn1: '•     사용자는 플랫폼을 통해 서버 호스팅 서비스를 구매하여 1대1 고객 서비스를 제공할 수 있습니다.',
    nrContetn2: '•     3개월마다 호스팅 서비스에 대해 20,000 ICWS(20u 가치)가 수여됩니다. 더 많이 구매하고 더 많이 받으세요. 일찍 참여하고 일찍 혜택을 누리세요. 자세한 사항은 고객 서비스에 문의하세요.',
    ndContetn1: '•     노드 모집 기간 동안 고객 관리자와 상담하여 관리 서버를 구매한 사용자는 ICW 에어드랍 보상을 받을 수 있으며, 이는 생성된 노드의 약속에 직접 사용될 수 있습니다.',
    recruitmentTit: 'ICW 제네시스 노드 보상 계획 및 수익',
    accountingTitle: '회계 그룹',
    accountingContent: '회계 그룹은 15개의 합의 노드로 구성되어 있습니다. 이들은 차례로 6초마다 블록을 생성하며, 회계 기간은 15분입니다(각 노드는 10개의 블록을 생성하므로 총 150개의 블록이 있습니다), 그리고 매일 14,400개의 블록이 생성됩니다. 블록 생성 보상은 4년마다 반으로 줄어들며, 초기 보상은 블록당 100 ICWs입니다. 초기 일일 보상은 144만 ICWs이며, 연간 보상은 5억 2560만 ICWs입니다. 따라서 보상이 21억 240만에 도달하면 반으로 줄어듭니다.',
    nodeTitle: '서비스 노드',
    nodeContent: '원장 서비스를 동기화하기 위해, 서비스 노드는 매 회계 기간(15분)마다 한 번 보상을 받으며 하루에 96번 보상을 받습니다. 동기화 보상은 4년마다 반으로 줄어들며, 초기 보상은 회계 기간당 7,500 ICWs입니다(모든 동기화 노드에 동등하게 분배됩니다). 초기 일일 보상은 72만 ICWs이며, 연간 보상은 2억 6280만 ICWs입니다. 따라서 보상이 10억 5120만에 도달하면 반으로 줄어듭니다.',
    profitTitle: '총 수익',
    profitContent: '노드 플레지가 500,000 ICW에 도달하고 블록 생성이 보장된다면(우리의 전제 조건), 1분 반 만에 한 블록이 생성되고, 수입은 블록당 100 ICWs가 될 것입니다. 예상 수입은 한 시간에 4,000 ICWs, 하루에 96,000 ICWs, 일년에 35,040,000 ICWs이며, 수익률은 약 70배가 될 것으로 예상됩니다.',
    nodeCreation: '노드 생성',
    qualification: '노드 생성 자격',
    process: '노드 생성 과정',
    pledge: '노드 플레지',

    // create global world
    networkEnvironmentSettings: '네트워크 환경 설정',
    deposit: '보증금 ≥ 20,000 ICW',

    edaos: 'EDAO Development Foundation',
    edao: 'ICWChain 프로토콜, 탈중앙화 네트워크 및 개발자 생태계의 성장과 발전을 지원하는 데 전념하고 있습니다.',
    Social: '소셜 플랫폼',
    Follow: 'TridentChain에 대해 더 알아보려면 저희를 팔로우하세요',
    Discord: 'Discord는 음성 및 텍스트 채팅을 위한 앱으로, web3의 주류 커뮤니티 통신 소프트웨어입니다.',
    github: '온라인 소프트웨어 소스 코드 호스팅 서비스 플랫폼',
    Twitters: '공공 대화를 위한 서비스에 전념합니다',
    Telegrams: '세계에서 가장 인기 있는 크로스 플랫폼 즉시 메시징 소프트웨어 중 하나입니다',
    ljgd: '함께하세요',
    voice: '사용자는 게임 자체에 더 집중하며, 원활한 음성은 CPU를 덜 사용합니다',
    safe: "안전하고 무료입니다",
    Support: '동시에 대량의 사용자 온라인 커뮤니케이션을 지원하며, 표준화된 관심사 커뮤니티를 제공합니다',
    serverSetup: '서버 설정',
    serverSetup1: 'A. 네트워크 자체 구성',
    serverSetup2: 'B. 서버 에이전트',
    loginWallet: 'ICW Wallet 로그인',
    fillinInfo: '노드 정보 입력',
    depositPayment: '예치금 지불',
    creationSucceeded: '노드 생성에 성공하였으며, 플레지 완료를 기다립니다',
    ICWPledge: 'ICW 플레지',
    nodeOperation: '플레지 금액이 200,000 ICWS를 초과하면 노드를 운영할 수 있습니다',
    more: 'ICW 체인에 대해 더 알고 싶으십니까',

    // dialog upgrade
    DearICWChainPartner: 'ICWChain 파트너 여러분',
    OK: '확인',
    popupDate: 'ICWChain은 2022년 9월 24일 9:00-18:00 (UTC/GMT+08:00)에 업그레이드될 예정입니다.',
    popupWarn: '업그레이드 중 자산 이전 작업을 진행하지 않도록 주의해 주세요!',
    popupText1: '이번 업그레이드는 주로 블록 포장 효율성 개선, 난이도 합의 알고리즘 및 노드 투표 합의 최적화를 포함합니다.',
    popupText2: '업그레이드가 완료된 후, 회계 그룹 노드의 블록 생산 시간은 12초/블록(즉, 회계의 회전이 1800초/기간으로 조정될 것입니다),',
    popupText3: '블록 출 리워드는 200ICW/블록으로 조정되며, 동기화 합의 보상은 15000ICW/회계 기간으로 조정됩니다. 각 노드의 사용자는 9월 24일 9:00-18:00 (UTC/GMT+08:00) 사이에 자신의 노드를 업그레이드해야 합니다. 업그레이드 지연으로 인한 모든 노드 손실은 본인이 부담해야 합니다.',
    popupText4: '동시에, 이번 업그레이드 이후 ICWChain은 USDT-IRC20의 발행을 지원할 것입니다.',

    // banner heading
    hbtitle: 'SETH ICO (1단계)',
    hbtime: '2022/11/10 10:00:00 (UTC+8) ~ 2022/12/2 17:00:00 (UTC+8)',
    hbBtn: '더 알아보기',
    hbuy: '매진',
    // hbuy: '구입',
    // dialog
    name: 'SETH CHAIN',
    diallogtext1: '새로운 DPOS+POW 하이브리드 합의 메커니즘',
    diallogtext2: '이더넷 합의 최적화',
    diallogtext3: '이더리움 채굴기 및 가상 머신 지원',
    diallogtext4: '원래 이더리움 채굴자의 이주 촉진 및 체인 상 생태계 구축 지원',
    dialogT: 'ICO (1단계)',
    begin: '부터',
    dialogStart: '2022년 11월 10일 오전 10시.',
    end: '까지',
    dialogEnd: '2022년 11월 25일 오후 5시.',
    dialogInfo: '20U ',
    dialogOline: '메인넷 출시',
    dialogOlineTime: '2023년 3월',
    dialogKnow: '확인',
    address: '주소',
    city2: '말레이시아',
    city2Ad: '말레이시아 연방 지역 라부안, 자란 메르데카, 파이낸셜 파크 라부안 메인 오피스 타워, 15층(A1), 87000',
    city1: '싱가포르',
    city1Ad: '싱가포르 534818 상퍼 페이야 레바 로드 73번지 #06-01C 센트로 비앙코',
    gmail: 'Gmail',
    emailAddress: 'admin@icwchain.com'
}
