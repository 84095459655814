<template>
    <div class="ecological">

        <div class="advantage">
            <div class="w">
                <div class="title">
                    {{ 'ICWCHAIN' | t }} <br> <b>{{ 'recruitment' | t }}</b>
                </div>
                <div class="adv-info">
                    {{ 'adopts' | t }}
                </div>
                <img class="node1" src="../assets/images/node1.png" alt="">
            </div>
        </div>

        <div class="node">
            <div class="w">
                <div class="why fl">
                    <h2>TridentChain</h2>
                    <em></em>
                    <h5>{{ 'incentive' | t }}</h5>
                    <img class="node2" src="../assets/images/node2.png" alt="">
                </div>
                <div class="fr adv">
                    <div class="adv-child ">
                        <div class="title1">
                            {{ 'section' | t }}
                        </div>
                        <div class="content">
                            {{ 'bookkeeping' | t }}
                        </div>
                    </div>
                    <div class="adv-child">
                        <div class="title1">
                            {{ 'ServiceNode' | t }}
                        </div>
                        <div class="content">
                            {{ 'servicenode' | t }}
                        </div>
                    </div>
                    <div class="adv-child">
                        <div class="title1">
                            {{ 'Grossincome' | t }}
                        </div>
                        <div class="content">
                            {{ 'Grossincomes' | t }}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="ec-main">
            <div class="w">
                <div class="ecWrap">


                    <div class="ecContent">
                        <ul>
                            <li v-for='(item, index) in planBenefits' :key='item.id'>
                                <a :href="item.href" target="_blank"><img :src="item.path" alt=""></a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import nodes from '@/assets/images/nodes.png'
import staking from '@/assets/images/staking.png'
import Swap from '@/assets/images/Swap.png'
import downloadjs from 'downloadjs'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { t } from '@/assets/i18n/index'

export default {
    name: 'recruitment-page',
    data() {
        return {
            planBenefits: [
                {
                    id: 1,
                    path: staking,
                    href: 'http://staking.bithive.cc/'
                },
                {
                    id: 2,
                    path: nodes,
                    href: 'https://config.tridentchain.com/ ',
                }
            ],
            activeIndex: 0,
            agentDialogVisible: false,
            error: '', // 未填数据项
            email: '',
            phone: '',
            userName: ''
        }
    },
    computed: {
        lang() {
            return this.$store.getters.getLanguage
        }
    },
    created() {
        window.addEventListener('click', this.closeDialog)
    },
    methods: {
        t,
        changeTab(index) {
            this.activeIndex = index
        },
        download(name) {
            if (this.lang === 'zh-CN') {
                let pdfUrl
                name && name === 'userManual' && (pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/yonghushouceCN.pdf')
                name && name === 'selfCreation' && (pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/Self%20Creation%20Chinese%20version.pdf')
                downloadjs(pdfUrl)
            } else {
                const pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/' + name + '.pdf'
                downloadjs(pdfUrl)
            }
        },
        downloadTAR(tar) {
            // downloadjs(tar)
            window.open(tar)
        },
        openDialog() {
            this.agentDialogVisible = true
            disablePageScroll()
        },
        closeDialog() {
            this.agentDialogVisible = false
            this.userName = ''
            this.phone = ''
            this.email = ''
            enablePageScroll()
        },
        preRegister() {
            if (!this.userName) {
                this.error = 'user'
            } else if (!this.phone) {
                this.error = 'phone'
            } else if (!this.email) {
                this.error = 'email'
            } else {
                this.$ajax.post('/edaoadminfinance/pre_register/save', {
                    userName: this.userName,
                    phone: this.phone,
                    email: this.email
                }).then(() => {
                    this.closeDialog()
                    alert(`${t('succeed')} !`)
                })
            }
        }
    },
    beforeRouteLeave() {
        window.removeEventListener('click', this.closeDialog)
        enablePageScroll()
    }
}
</script>
<style lang="less" scoped>
.ecological {
    background: url('../assets/images/bg2.png') center top;

    .advantage {
        font-family: 'Poppins';
        width: 100%;
        height: 950px;
        // background: url('../assets/images/bg2.png') no-repeat center;
        margin: 0 auto;

        .w {
            position: relative;

            .node1 {
                position: absolute;
                top: 200px;
                right: 10px;
            }

            .title {
                font-family: 'Gautami';
                position: absolute;
                top: 305px;
                width: 758px;
                font-weight: 700;
                font-size: 64px;
                color: #FFFFFF;

                b {
                    position: absolute;
                    top: 85px;
                    color: #FFFFFF;
                }
            }

            .adv-info {
                position: absolute;
                top: 555px;
                width: 540px;
                height: 166px;
                line-height: 30px;
                font-size: 20px;
                color: #FFFFFF;
            }
        }

    }

    .node {
        font-family: 'Poppins';
        width: 100%;
        height: 1016px;

        .why {
            color: #FFFFFF;
            width: 400px;
            margin: 80px auto 0;
            position: relative;

            h2 {
                font-size: 36px;
            }

            h5 {
                font-weight: 700;
                color: #66a6fe;
                font-size: 30px;
                margin-top: 20px;
                margin-left: 100px;
            }

            .node2 {
                position: absolute;
                top: 170px;
                left: -80px;
            }
        }

        .adv {
            margin-top: 60px;
        }

        .adv-child {
            box-sizing: border-box;
            margin-top: 20px;
            width: 762px;
            height: 244px;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.3);
            padding: 40px 50px;
        }

        .adv-child .title1 {
            font-size: 24px;
            color: #2387FF;
            font-weight: 700;
        }

        .adv-child .content {
            margin-top: 40px;
            width: 660px;
            line-height: 30px;
            font-size: 18px;
            color: #FFFFFF;
        }

        .adv-child:nth-child(3) {
            height: 364px;
        }


    }

    .ec-main {
        width: 100%;
        height: 206px;
    }

    .ecWrap {
        font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
        margin-top: 20px;
        text-align: center;

        .active {
            background-color: #246EFF;
            color: #FFFFFF;
        }

        .ecContent {
            margin-top: 100px;
            width: 1200px;
            height: 200px;

            ul {

                li {
                    display: inline-block;
                    width: 260px;
                    height: 200px;
                    margin-right: 28px;
                    border-radius: 20px;
                    background-color: rgba(0, 0, 0, 0.3);
                    cursor: pointer;

                    a {
                        display: block;
                        width: 260px;
                        height: 200px;

                        img {
                            width: 260px;
                            height: 200px;
                        }
                    }
                }
            }
        }
    }
}
</style>
<!--移动端-->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .ecological {
        background: url('../assets/images/m-bg4.png') no-repeat !important;
        background-size: cover !important;

        .advantage {
            height: 13.85rem;

            .w {
                width: 100%;

                .title {
                    font-size: 0.74rem;
                    top: 1.7rem;
                    left: 0.29rem;
                    width: 6rem;

                    b {
                        top: 1rem;
                    }
                }

                .adv-info {
                    font-size: 0.24rem;
                    padding: 0;
                    top: 3.9rem;
                    width: 6.38rem;
                    left: 0.28rem;
                    line-height: 0.34rem;
                    color: #FFFFFF;
                }

                .node1 {
                    top: 6.1rem;
                    left: 1.28rem;
                    width: 4.9rem;
                    height: 6rem;
                }
            }
        }

        .node {
            width: 100%;
            height: 17.2rem;

            .w {
                width: 6.98rem;

                .why {
                    width: 5rem;
                    margin: 0.52rem auto 0.2rem;

                    h2 {
                        font-size: 0.42rem;
                    }

                    em {
                        width: 0.52rem;
                        margin: 0.2rem auto;
                    }

                    h5 {
                        font-size: 0.4rem;
                        margin-top: 0.26rem;
                        margin-left: 1.1rem;
                    }

                    .node2 {
                        left: 1.84rem;
                        top: 12.56rem;
                        width: 3.82rem;
                        height: 4.74rem;
                    }
                }

                .adv-child {
                    margin-top: 0.22rem;
                    box-sizing: border-box;
                    width: 6.90rem;
                    height: 3.20rem;
                    padding: 0.4rem;
                    border-radius: 0.28rem;

                    .line {
                        width: 1.04rem;
                        height: 1.04rem;
                        margin-top: -0.5rem;
                        margin-left: -0.14rem;

                        img {
                            width: 1.04rem;
                            height: 1.04rem;
                        }
                    }

                    .title1 {
                        font-size: 0.35rem;
                        margin: 0;
                    }

                    .content {
                        font-size: 0.18rem;
                        width: 5.73rem;
                        height: 1rem;
                        margin-top: 0.4rem;
                        line-height: 0.28rem;
                    }
                }

                .adv-child:nth-child(3) {
                    margin: 0.22rem 0 0;
                    height: 4rem;
                }

                .adv-child:nth-child(2) {
                    margin: 0.22rem 0 0;
                    height: 2.7rem;
                }
            }
        }

        .ec-main {
            width: 100%;
            height: 5.83rem;

            .w {
                width: 7.22rem;

                .ecWrap {
                    margin-top: 0.15rem;
                    text-align: center;

                    .ecContent {
                        margin-top: 0.5rem;
                        width: 100%;
                        height: 5rem;

                        li {
                            width: 3.31rem;
                            height: 2.66rem;
                            margin-right: 0.17rem;
                            border-radius: 0.16rem;
                            margin-top: 0.37rem;
                        }

                        a {
                            width: 3.31rem;
                            height: 2.66rem;
                            // float: left;
                            margin: 0 0.12rem;
                            cursor: pointer;

                            img {
                                width: 3.31rem;
                                height: 2.66rem;
                                border-color: none;
                            }
                        }

                        img:not([src]) {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
