/*
 * @Author: Lucius
 * @Date: 2022-05-25 08:24:02
 * @Last Modified by: LGF
 * @Last Modified time: 2022-06-20 17:04:00
 */
import CN from './lang/zh-CN'
import EN from './lang/en'
import ES from './lang/ES'
import ID from './lang/ID'
import JP from './lang/JP'
import KR from './lang/KR'
import RU from './lang/RU'
import TW from './lang/TW'
import UA from './lang/UA'
import Vue from 'vue'
import store from '../../store/index'
import deepmerge from 'deepmerge'
import Format from './format'

const format = Format(Vue)
const language = { en: EN, ID: ID, KR: KR, TW: TW }
store.commit('SETLANGUAGE', (window.localStorage.getItem('language') || store.getters.getLanguage))
let lang = language[store.getters.getLanguage]
let merged = false
let i18nHandler = function () {
    const vuei18n = Object.getPrototypeOf(this || Vue).$t
    if (typeof vuei18n === 'function' && !!Vue.locale) {
        if (!merged) {
            merged = true
            Vue.locale(
                Vue.config.lang,
                deepmerge(lang, Vue.locale(Vue.config.lang) || {}, { clone: true })
            )
        }
        return vuei18n.apply(this, arguments)
    }
}

export const t = function (path, options) {
    let value = i18nHandler.apply(this, arguments)
    if (value !== null && value !== undefined) return value
    lang = language[store.getters.getLanguage]

    const array = path.split('.')
    let current = lang

    for (let i = 0, j = array.length; i < j; i++) {
        const property = array[i]
        value = current[property]
        if (i === j - 1) return format(value, options)
        if (!value) throw new Error(`${path} is not found in language ${store.getters.getLanguage}`)
        current = value
    }
    return ''
}

export const use = function (l) {
    lang = l || lang
}

export const i18n = function (fn) {
    i18nHandler = fn || i18nHandler
}

export default { use, t, i18n }
