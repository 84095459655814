export default {
    home: 'Домашняя страница',
    technology: 'ТЕХНОЛОГИИ',
    ecological: 'ЭКОСИСТЕМА',
    browser: 'БРАУЗЕР БЛОКЧЕЙНА',
    recruitment: 'УЗЕЛ',
    wallet: 'КОШЕЛЕК',
    contact: 'СВЯЖИТЕСЬ С НАМИ',
    joinUs: 'ПРИСОЕДИНЯЙТЕСЬ К НАМ',
    partner: 'Партнеры',
    language: 'ЯЗЫК',
    learnMore: 'Узнать больше',
    company: 'EDAO DEVELOPMENT FOUNDATION LTD.',
    funds: 'Фонд ICW',
    lucky: 'Счастливое колесо',
    hashGuess: 'Конкурс хешей',
    prize: 'Гарантированный выигрыш, огромный призовой фонд',
    prizeBtn: 'НАЧАТЬ',

    White: 'Загрузка белой книги',
    Introduction: 'Введение в TridentChain',

    Numbertransactions: 'Количество транзакций',
    Blockheight: 'Высота блока',
    Coretoken: 'Общая ставка',

    aboutICW: 'О TridentChain',
    whitePaper: 'Белая книга',
    ICWwhitePaper: 'Белая книга TDCChain ',
    technologyFunctions: 'Технологии и функции',
    roadmap: 'Дорожная карта',
    map: 'Дорожная карта',
    Introduction: 'Введение в TridentChain',

    Numbertransactions: 'Количество транзакций',
    Blockheight: 'Высота блока',
    Coretoken: 'Общий стейк',

    aboutICW: 'О TridentChain',
    whitePaper: 'Белая бумага',
    ICWwhitePaper: 'Белая бумага TDCChain',
    technologyFunctions: 'Технологии и функции',
    roadmap: 'Дорожная карта',
    map: 'Дорожная карта',

    welcometoICW: 'ДОБРО ПОЖАЛОВАТЬ В TDC CHAIN',
    bannerText: 'Исследование нового направления блокчейна в глубинах моря.',
    bannerText2: 'TridentChain поддерживает возможности для опытных пользователей, новичков и всех остальных.',
    whitePaperDownload: 'Ссылка для скачивания белой книги',
    whychoose: 'Почему стоит выбрать',
    aspires: 'ICW стремится стать путеводителем WEB3.0',
    decentralizationTitle: 'Децентрализация, безопасность и высокая производительность',
    decentralizationTitle1: 'Децентрализация, ',
    decentralizationTitle2: 'безопасность и высокая производительность',
    decentralizationSubtitle: 'TridentChain использует гибридную модель консенсуса PoW+DpoS для улучшения децентрализации сети блокчейна и справедливости сообщества, увеличивая порог участия узлов и случайность группы подсчета.',
    contractTitle: 'Общий умный контракт для торговли',
    contractSubtitle: "ridentChain - это децентрализованная публичная сеть, возглавляемая глобальным сообществом разработчиков. Она быстрая, энергоэффективная и надежная. Предоставляет разработчикам мощные открытые возможности.",
    highlyModularTitle: 'Высокая модульность',
    highlyModularSubtitle: 'TridentChain ссылается на модульную архитектуру NULS, которая характеризуется возможностью горячей замены, высокой эффективностью, долговечностью и вечностью. Это значительно уменьшает общую сложность блокчейна, оптимизирует его. ',
    crossChainCommunicationTitle: 'Коммуникация между цепочками',
    crossChainCommunicationSubtitle: 'Создать цепочку коммуникации для валютных переводов, чтобы поддержать обмен валют в будущем.',
    engineSubtitle: 'ICWChain также использует движок суперпараллельной обработки транзакций, что позволяет сети одновременно обрабатывать тысячи вызовов умных контрактов.',

    ICWTechLight1: 'ТЕХНОЛОГИИ ICWCHAIN',
    ICWTechLight2: 'ОСНОВНЫЕ МОМЕНТЫ',

    ICWTechLightSub: 'ICWChain принимает гибридный механизм консенсуса DPOS + POW и разрабатывает высокомодульную и масштабируемую структуру прикладного слоя на техническом уровне, что облегчает межсетевое взаимодействие, параллельное построение цепочек и хранение информации вне цепи.',
    techIntroduction: 'Введение в технологии',
    succeed: 'Успех',
    whitePaperCn: 'Белая книга ICWChain - Китайская',
    whitePaperEn: 'Белая книга ICWChain - Английская',
    decentralizationSubContent1: 'Децентрализация - гибридный механизм консенсуса DPOS+POW',
    decentralizationSubContent2: 'Члены сообщества ICWChain выбирают узлы для выборов путем голосования, а узлы выборов соревнуются за места супер узлов через конкуренцию вычислительных мощностей и получают право на учет раунда; Узел учета завершает эту задачу учета после завершения упаковки блока. Затем через вышеописанный процесс выбирается новый раунд супер узлов и начинается новый раунд бухгалтерского учета.',
    decentralizationSubContent3: 'высокая производительность и низкая задержка - миллион TPS, верификация на уровне секунд',
    decentralizationSubContent4: 'На основе обеспечения степени децентрализации дальнейшее повышение эффективности обработки транзакций, и максимальная скорость транзакции может достигать уровня секунд.',
    decentralizationSubContent5: 'Безопасность - двойная защита консенсуса и повышение сложности совершения зла',
    decentralizationSubContent6: "ICWChain принимает гибридный механизм консенсуса для проведения множественных ротационных выборов бухгалтерской группы, увеличивая тем самым сложность и стоимость совершения злодеяний на узлах, чтобы обеспечить безопасность цифровых активов пользователей.",
    contractSubContent: ' ICWChain разработал полностью децентрализованное решение для сопоставления в цепочке и управления заказами на транзакции. Основываясь на общем умном контракте для транзакций, изначально созданном ICWChain, все пары транзакций в традиционном модуле агрегации сопоставления разделяются на свои модули (умные контракты), чтобы реализовать полностью децентрализованную транзакцию активов в цепочке. Когда пользователи с другими валютами пишут и инициируют контракты на токенах ICWChain, им необходимо вызвать общий умный контракт ICWChain для транзакций и связать одну или несколько пар транзакций, при этом валюта пары транзакций должна быть валютой контракта, выпущенной на ICWChain для транзакции. В то же время, общий контракт для транзакций имеет собственную функцию сопоставления транзакций, и пользователи могут напрямую размещать заказы в контракте для выполнения децентрализованной транзакции активов в цепочке.',
    contractSubContent1: 'ICWChain представил полностью децентрализованное решение для сопоставления на цепочке и управления заказами на транзакции. Используя основной умный контракт для транзакций, разработанный ICWChain, данный подход делит пары транзакций из традиционного модуля агрегации сопоставления на отдельные модули (умные контракты), чтобы реализовать полностью децентрализованную транзакцию активов на цепочке.',
    contractSubContent2: 'Когда пользователи с другими валютами пишут и инициируют контракты на токенах ICWChain, им необходимо вызвать общий умный контракт ICWChain для транзакций и привязать одну или несколько пар транзакций, причем валюта пары транзакций должна быть валютой контракта, выпущенной на ICWChain для транзакции. В то же время, общий контракт для транзакций обладает собственной функцией сопоставления транзакций, и пользователи могут напрямую размещать заказы в контракте для выполнения децентрализованной транзакции активов на цепочке.',
    highlyModularSubContent: 'Модульная инфраструктура NULS, на которую ссылается ICWChain, является самой фундаментальной частью экосистемы ICWChain. Архитектура модулей NULS обладает характеристиками такими как горячее подключение, высокая эффективность, долговечность, безвременье и т.д. Хорошая модульность может значительно снизить общую сложность блокчейна и упростить проектирование, отладку и обслуживание блокчейна; Каждый модуль может работать независимо. Модуль NULS предоставляет стандартное определение «модуля». Все модули ICWChain следуют спецификации модуля NULS. Менеджер модулей ICWChain контролирует администрирование всех модулей и определяет спецификации для действий, таких как загрузка/выгрузка модулей и регистрация сервисов. Любые расширяющие модули, соответствующие спецификации модулей NULS, могут быть загружены в узел ICWChain через менеджера модулей.',
    highlyModularSubContent1: 'Модульная инфраструктура NULS, на которую ссылается ICWChain, является самой фундаментальной частью экосистемы ICWChain. Архитектура модулей NULS обладает характеристиками горячего подключения, высокой эффективности, долгосрочности, отсутствия временных ограничений и т.д. Хорошая модульность может значительно уменьшить общую сложность блокчейна и упростить его проектирование, отладку и обслуживание; каждый модуль может функционировать независимо. Модуль NULS предоставляет стандартное определение «модуля».',
    highlyModularSubContent2: 'Все модули ICWChain следуют определению модуля NULS. Менеджер модулей ICWChain отвечает за управление всеми модулями и определение спецификаций, таких как загрузка / выгрузка модуля и регистрация службы. Все расширяющие модули, следующие спецификации модуля NULS, могут быть загружены в узел ICWChain через менеджера модулей.',
    crossChainCommunicationSubContent: ' ICWChain спроектировала и разработала шлюз для кросс-чейн связи альянса. Через этот шлюз реализуется взаимосвязь данных и токенов между альянсом и основными публичными сетями. Переток токенов через сети реализуется с помощью протокола IBC.',
    engineSubContent: 'ICWChain принимает движок сверхпараллельной обработки транзакций, который позволяет сети одновременно обрабатывать тысячи вызовов умных контрактов. Благодаря своей инфраструктуре конвейера, ICWChain также может быстрее проверять транзакции и копировать информацию о транзакциях между узлами, независимо от их аппаратных спецификаций.',
    techRoadMap1: "Запуск бета-версии TridentChain, начало набора узлов, открытие торгов на DEX",
    techRoadMap2: 'Поддержка сторонними кошельками, TridentChain поддерживает взаимодействие между несколькими цепями',
    techRoadMap3: 'Запуск основной сети TridentChain, сообщество достигает более 50 000 участников',
    techRoadMap4: 'Интеграция множества приложений в экосистему TridentChain',
    techRoadMap5: 'Продвижение TridentChain и повышение узнаваемости бренда, продолжение развития сообщества TridentChain',
    techRoadMap6: 'Поощрять больше приложений к присоединению к экосистеме TridentChain',
    ICWCHAIN: 'TridentChain',
    adopts: 'TridentChain - это модульная публичная блокчейн-сеть, использующая механизм консенсуса POW+DPOS, нацеленная на предоставление надежной поддержки для устойчивого роста и развития экосистемы блокчейна. Наши узлы играют ключевую роль в достижении этой цели.',

    ecologicalNetTit: 'ЭКОЛОГИЧЕСКАЯ СЕТЬ ICWCHAIN',
    ecologicalNetTit1: 'Экосистема TridentChain',
    ecologicalNetTit2: 'СЕТЬ',
    ecologicalSub: 'ICWChain обладает разнообразной социальной сетью экосистемы с полной "свободой и равенством, демократией и автономией, сотрудничеством и обменом", которая разрабатывается всеми членами сообщества на основе "консенсуса в видении, консенсуса в правах и интересах, консенсуса в ценностях, консенсуса в управлении, консенсуса в доходах и консенсуса в технологиях".',
    ecologicalSub1: 'TridentChain является процветающей экосистемой социальной сети, где в центре стоят ценности "принятия свободы и равенства, поддержания демократии и автономии, способствования сотрудничеству и обмену". Давайте объединимся, чтобы вместе строить будущее открытого веба.',
    ecologicalSub2: 'Давайте вместе строить открытый веб.',
    ecologicalAppTit: 'Экологическое приложение ICWChain',

    API: 'Документация API',
    NodeCreation: 'Документация по созданию узлов',

    Find1: 'Откройте для себя самые горячие проекты на TridentChain',
    Find2: 'CHAIN?',
    development: 'Сбалансированное и здоровое экологическое развитие',
    ICWNode: 'Узел TridentChain',
    incentive: 'Набор узлов',
    section: 'Что такое узлы TridentChain?',
    bookkeeping: 'Узлы TridentChain являются фундаментальными элементами сети, отвечающими за поддержание безопасности, стабильности и децентрализации сети. Эти узлы составляют ядро нашей экосистемы, обеспечивая её бесперебойную работу',
    ServiceNode: 'Создание узла и стейкинг',
    servicenode: 'Создание узла TridentChain требует депозита в 1000 TDC (токенов TridentChain). Минимальная сумма стейкинга на узел составляет 3000 TDC, с максимальным пределом стейкинга в 30 000 TDC.',
    Grossincome: 'Бухгалтерская группа и механизм вознаграждения',
    Grossincomes: 'Один цикл бухгалтерской группы состоит из 27 узлов. В течение одного бухгалтерского периода (45 минут) блоки генерируются по круговой системе (каждый узел производит 10 блоков, всего 270 блоков). Время подтверждения каждого блока составляет 10 секунд. Изначально каждый блок вознаграждается 10 токенами TDC, и это вознаграждение уменьшается на 15% ежегодно. Этот динамичный механизм вознаграждения обеспечивает упорядоченную эмиссию новых токенов для поддержания долгосрочной устойчивости сети TridentChain.',

    ecologicalAppTitle1: 'Цифровая идентичность и доверительная сеть',
    ecologicalAppTitle2: 'Финансовая сфера и DeFi',
    ecologicalAppTitle3: 'Децентрализованные социальные сети',
    ecologicalAppTitle4: 'Электронная коммерция и трансграничные платежи',
    ecologicalAppTitle5: 'Защита авторских прав и NFT',
    ecologicalAppTitle6: 'Благотворительность',
    ecologicalContent1: 'Распределенная система хранения данных на блокчейне ICWChain соединяет множество обычных серверов вместе, предлагая решения для хранения в виде коллектива через сетевое соединение. Она использует структуру данных блокчейна для верификации и хранения данных, а также распределенный алгоритм консенсуса узлов для генерации и обновления данных. Это обеспечивает поддержание комплексной, эффективной и безопасной базы данных.',
    ecologicalContent2: 'ICWChain переопределяет концепции активов в цифровом мире и устанавливает определение онлайн-прав и интересов для данных активов, которые представляют любую форму актива, обеспечивая при этом защиту транзакционного поведения. Блокчейн-сеть ICWChain будет подключаться и создавать основную и доверенную систему блокчейна в финансовой индустрии. Это будет способствовать сотрудничеству между различными приложениями в финансовой индустрии через безопасный механизм передачи и обмена токенами.',
    ecologicalContent3: 'ICWChain вливает новую кровь в контент-платформы социальных сетей с помощью технологии блокчейн. Как важный член экологического приложения ICWChain, eseeworld использует распределенное управление для децентрализации платформы.',
    ecologicalContent4: 'ICWChain применяет технологию блокчейна к торговле ценными бумагами, что делает удобным для каждого участника самостоятельно завершать весь процесс расчетов после того, как ICWChain применяет технологию точка-точка в области трансграничных платежей, канал денежных переводов становится прозрачным, а данные истории транзакций могут быть прослежены. Это не только успокаивает получателя и отправителя, но и значительно повышает эффективность и скорость.',
    ecologicalContent5: 'Опираясь на характеристики блокчейн-сети, ICWChain позволяет верифицировать и подтверждать владение редкими атрибутами и активами в метавселенной. Это обеспечивает поток ценности данных за счет отображения цифровых представлений таких активов, как экипировка пользователей, пропсы, права на владение землей в "метавселенной". В результате эти активы становятся объектами торговли в этом цифровом мире.',
    ecologicalContent6: 'Технология блокчейна может значительно повысить прозрачность информации в области благотворительности, дать донорам право контролировать систему и процессы благотворительности, а также защитить конфиденциальность доноров и получателей.',

    mission: 'МИССИЯ ICWCHAIN',
    mission1: 'ICW CHAIN',
    mission2: 'МИССИЯ',
    contactSub: 'По сравнению с централизованными интернет-платформами, блокчейн предлагает повышенную стабильность, превосходную устойчивость к рискам, большую справедливость и простоту. ICWChain изменил способ взаимодействия информации, преодолевая вызовы приложений, которые невозможно решить с централизованными идеями, способствуя внедрению построения цифровой экономики. Он тесно интегрировал Web3.0 с распределенной идеей.',

    vipkefu: 'VIP-обслуживание (Wechat)',
    community: 'Сообщество ICWChain',
    Telegram: 'Telegram',
    Twitter: 'Twitter',

    recruitTit: 'НАБОР НАЧАЛЬНЫХ УЗЛОВ ICW',
    userManual: 'Руководство пользователя',
    selfCreation: 'Самостоятельное создание',
    replaceCreation: 'Услуга строительства агентом',
    agentConstruction: 'Строительство агентом',
    installtion: 'Пакет установки узла',
    account: 'Пожалуйста, введите ваше имя или название сущности',
    mobileAccount: 'Пожалуйста, введите ваш мобильный номер',
    emailAccount: 'Пожалуйста, введите ваш адрес электронной почты',
    infoText1: 'Пользователи могут приобрести услуги хостинга серверов через платформу и наслаждаться индивидуальным обслуживанием клиентов.',
    infoText2: 'После того как клиент заполняет форму намерений покупки, менеджер по работе с клиентами отправит письмо с руководством по завершению последующей операции покупки сервера',
    thirdPartyServices: 'Эта услуга предоставляется третьей стороной ESEEWORLD',
    apply: 'Подать заявку немедленно',
    advTitle1: 'Скоро будет запущена основная сеть публичной блокчейн ICWChain',
    advTitle2: 'Сейчас мы набираем начальные узлы по всему миру',
    advContent1: 'ICWChain - это инновационная публичная сеть блокчейн, учитывающая децентрализацию, высокую производительность и безопасность данных.',
    advContent2: 'ICWChain станет новатором и проводником в волне третьего поколения научно-технологической революции Интернета и возьмет на себя продвижение строительства глобальной цифровой экономической системы в качестве первоочередной задачи и конечной миссии.',
    nrTitle: 'Период набора узлов',
    ndTitle: 'Период развертывания узлов',
    nrTime: 'с 26 мая 12:00 до 15 июня',
    ndTime: 'с 15 июня 12:00 до 20 июня',
    ndTime1: 'Запуск и работа узла',
    nrContetn1: '•     Пользователи могут приобретать услуги хостинга серверов через платформу для предоставления услуги обслуживания клиентов один на один.',
    nrContetn2: '•     Каждые три месяца за услуги хостинга будут начисляться 20 000 ICWS (стоимостью 20u). Покупайте больше и получайте больше. Присоединяйтесь раньше и получайте преимущества раньше. За деталями обращайтесь в службу поддержки.',
    ndContetn1: '•     Во время периода набора узла, пользователи, которые покупают управляемый сервер, консультируясь с менеджером по обслуживанию клиентов, могут наслаждаться вознаграждением в виде аирдропа ICW, который может быть непосредственно использован для залога созданного узла.',
    recruitmentTit: 'План вознаграждения и доход от первоначального узла ICW',
    accountingTitle: 'Бухгалтерская группа',
    accountingContent: 'Бухгалтерская группа состоит из 15 консенсусных узлов. Они по очереди создают блоки за 6 секунд, и бухгалтерский период составляет 15 минут (каждый узел создает 10 блоков, следовательно, общее количество блоков составляет 150), и каждый день создается 14 400 блоков. Награда за создание блока уменьшается вдвое каждые 4 года, и первоначальная награда составляет 100 ICW за блок. Первоначальная ежедневная награда составляет 1.44 миллиона ICW, а годовая награда - 525.6 миллиона ICW. Таким образом, когда награда достигнет 2.1024 миллиарда, она будет уменьшена вдвое.',
    nodeTitle: 'Узел Обслуживания',
    nodeContent: 'Для синхронизации услуг учета, узел обслуживания награждается один раз за каждый бухгалтерский период (15 минут) и 96 раз в день. Награда за синхронизацию уменьшается вдвое каждые четыре года, и первоначальная награда составляет 7,500 ICW за период учета (равномерно распределяется между всеми узлами синхронизации). Первоначальная ежедневная награда составляет 720,000 ICW, а годовая награда - 262.8 миллиона ICW. Таким образом, когда награда достигнет 1.0512 миллиарда, она будет уменьшена вдвое.',
    profitTitle: 'Общий Доход',
    profitContent: 'Если залог узла достигает 500,000 ICW и блок обязательно будет создан (наше предпосылка), блок будет создан за 1 минуту и половину, и доход составит 100 ICW за блок. Ожидаемый доход составляет 4,000 ICW в час, 96,000 ICW в день и 35,040,000 ICW в год, и ожидается, что доходность составит около 70 раз.',
    nodeCreation: 'Создание Узла',
    qualification: 'Квалификация для Создания Узла',
    process: 'Процесс Создания Узла',
    pledge: 'Залог Узла',

    // create global world
    networkEnvironmentSettings: 'Конфигурация сетевой среды',
    deposit: 'Депозит ≥ 20,000 ICW',

    edaos: 'EDAO Development Foundation',
    edao: 'The EDAO Foundation посвящен поддержке роста и развития протокола ICWChain, децентрализованных сетей и экосистем разработчиков.',
    Social: 'Социальная платформа',
    Follow: 'Следите за нами и узнавайте больше о TridentChain',
    Discord: 'Discord - это приложение для голосового и текстового чата, основное программное обеспечение для общения в сообществе web3.',
    github: 'Онлайн-платформа для хостинга исходного кода программного обеспечения',
    Twitters: 'Посвящен поддержке публичного диалога',
    Telegrams: 'Одно из самых популярных межплатформенных программ мгновенного обмена сообщениями в мире',
    ljgd: 'Присоединяйтесь к нам',
    voice: 'Пользователи больше сосредоточены на самой игре, и плавный голос требует меньше CPU',
    safe: "Это безопасно и бесплатно",
    Support: 'Поддерживает одновременное онлайн-общение большого числа пользователей, стандартизированное сообщество по интересам',
    serverSetup: 'Настройка сервера',
    serverSetup1: 'А. Настройка сети самостоятельно',
    serverSetup2: 'Б. Агент сервера',
    loginWallet: 'Войдите в кошелек ICW',
    fillinInfo: 'Заполните информацию о ноде',
    depositPayment: 'Оплата депозита',
    creationSucceeded: 'Создание ноды завершено, ожидание завершения пледжа',
    ICWPledge: 'Пледж ICW',
    nodeOperation: 'Если сумма пледжа превышает 200,000 ICWS, нода может работать',
    more: 'Хотели бы вы узнать больше о ICW Chain',

    // dialog upgrade
    DearICWChainPartner: 'Уважаемый партнер ICWChain',
    OK: 'Хорошо',
    popupDate: 'ICWChain будет обновлен с 9:00 до 18:00 (UTC/GMT+08:00) 24 сентября 2022 года.',
    popupWarn: 'Пожалуйста, воздержитесь от проведения каких-либо операций с передачей активов во время обновления!',
    popupText1: 'Это обновление в основном включает в себя: улучшение эффективности упаковки блоков, а также оптимизацию алгоритма консенсуса сложности и консенсуса голосования узлов.',
    popupText2: 'После завершения обновления время производства блоков узлом группы учета будет скорректировано до 12 секунд за блок (то есть, ротация учета будет скорректирована до 1800 секунд за период),',
    popupText3: 'награда за выход блока будет скорректирована до 200 ICW за блок, а награда за синхронный консенсус будет скорректирована до 15000 ICW за учетный период. Пользователи каждого узла должны обновить свои узлы с 9:00 до 18:00 (UTC/GMT+08:00) 24 сентября. Любые потери узлов, вызванные задержкой обновления, должны быть компенсированы самими пользователями.',
    popupText4: 'В то же время, после этого обновления, ICWChain будет поддерживать выпуск USDT-IRC20.',

    // banner heading
    hbtitle: 'SETH ICO (Первая фаза)',
    hbtime: 'с 10:00 10 ноября 2022 года до 17:00 2 декабря 2022 года (по UTC+8)',
    hbBtn: 'Узнать больше',
    hbuy: 'Распродано',
    // hbuy: 'Распродано',
    // dialog
    name: 'SETH CHAIN',
    diallogtext1: 'Новый гибридный консенсус механизм DPOS+POW',
    diallogtext2: 'Оптимизация консенсуса Ethereum',
    diallogtext3: 'Поддержка майнинг-машин и виртуальных машин Ethereum',
    diallogtext4: 'Содействие миграции оригинальных майнеров Ethereum и строительство экологии в блокчейне',
    dialogT: 'ICO (Первая фаза)',
    begin: 'from',
    dialogStart: 'November 10, 2022, 10:00 a.m.',
    end: 'to',
    dialogEnd: 'November 25, 2022, 17:00 p.m.',
    dialogInfo: '20U ',
    dialogOline: 'Запуск основной сети',
    dialogOlineTime: 'Март 2023 года',
    dialogKnow: 'ХОРОШО',
    address: 'Адрес',
    city2: 'Малайзия',
    city2Ad: 'Уровень 15(A1), Главная офисная башня, Финансовый парк Лабуан, Джалан Мердека, 87000 Федеральная территория Лабуан, Малайзия',
    city1: 'Сингапур',
    city1Ad: '73 Upper Paya Lebar Road #06-01C Centro Bianco Сингапур 534818',
    gmail: 'Gmail',
    emailAddress: 'admin@icwchain.com'
}
