<!-- 技术亮点 -->
<template>
    <div class="home">
        <div class="advantage">
            <div class="w">
                <div class="title">
                    {{ "ICWTechLight1" | t }} <br /><b>{{ "ICWTechLight2" | t }}</b>
                </div>
                <div class="line"></div>
                <div class="adv-info">
                    {{ "ICWTechLightSub" | t }}
                </div>
                <div class="pdf">
                    <a @click="downloadPDF('https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-en.pdf')"
                        target="_blank">
                        {{ "ICWwhitePaper" | t }}
                    </a>
                </div>
                <div class="logo">
                    <img src="../assets//images/TDT/technologylog.png" alt="">
                </div>
            </div>
        </div>
        <div class="container">
            <div class="w">
                <div class="container-top">
                    {{ "technologyFunctions" | t }}
                </div>
                <div class="container-main">
                    <div class="container-main-left">
                        <div class="container-main-lefttexts">
                            Decentralization
                        </div>
                        <div class="container-main-lefttext">
                            Security
                        </div>
                        <div class="container-main-lefttext">
                            High performance
                        </div>
                    </div>
                    <div class="container-main-middle">
                        <div>
                            <img src="../assets/images/TDT/youjian.png" alt="">
                        </div>
                    </div>
                    <div class="container-main-right">
                        <div class="container-main-righttext">
                            Decentralization - DPOS+POW mixed consensus mechanism
                        </div>
                        <div class="container-main-righttext">
                            Security - double consensus defense and improve the difficulty of doing evil
                        </div>
                        <div class="container-main-righttext">
                            High performance and low latency - million TPS, second level verification
                        </div>
                    </div>
                </div>
                <div class="container-footer">
                    <!-- <div class="container-footer-bi">
                        <img src="../assets//images/TDT/bi1.png" alt="">
                    </div> -->
                    <div class="container-footer-title">General trading smart contract</div>
                    <div class="container-footer-text">TRIDENTChain extended a completely decentralized solution for on
                        chain matching and transaction order management. Based on the general
                        transaction smart contract originally created by ICWChain, all transaction pairs in the traditional
                        aggregation matching module are divided into
                        their own modules (smart contract), so as to realize the completely decentralized transaction of
                        assets on the chain.</div>
                </div>
            </div>
        </div>
        <div class="timeInfo">
            <div class="w">
                <div class="timeInfo-title">{{ "map" | t }}</div>
                <div class="timeInfo-item">
                    <div class="timeInfo-item-left">
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2022 Q2</div>
                            <div class="timeInfo-item-left-text-cn">TRIDENTChain's main network was launched, and ICW's was
                                released in early pre release</div>
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2022 Q4</div>
                            <div class="timeInfo-item-left-text-cn">TRIDENTChain multi module upgrade, multi application
                                included in ICWChain ecological construction</div>
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2023 Q2</div>
                            <div class="timeInfo-item-left-text-cn">TRIDENT SPV Wallet launched DEX launched EVM support</div>
                        </div>
                    </div>
                    <div class="timeInfo-item-middle">
                        <div class="timeInfo-item-middle-xiala">
                            <img src="../assets/images/TDT/xiala.png" alt="">
                        </div>
                         <div class="timeInfo-item-middle-tuoyuan">
                            <img src="../assets/images/TDT/tuotuan.png" alt="">
                        </div>
                        <div class="timeInfo-item-middle-tuoyuan1">
                            <img src="../assets/images/TDT/tuotuan.png" alt="">
                        </div>
                    </div>
                    <div class="timeInfo-item-right">
                        <div class="timeInfo-item-right-bi">
                            <img src="../assets/images/TDT/bi2.png" alt="">
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2022 Q2</div>
                            <div class="timeInfo-item-left-text-cn">TRIDENTChain's main network was launched, and ICW's was
                                released in early pre release</div>
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2022 Q4</div>
                            <div class="timeInfo-item-left-text-cn">TRIDENTChain multi module upgrade, multi application
                                included in ICWChain ecological construction</div>
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2023 Q2</div>
                            <div class="timeInfo-item-left-text-cn">TRIDENT SPV Wallet launched DEX launched EVM support</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import downloadjs from 'downloadjs'

export default {
    name: 'technologyPage',
    data() {
        return {
            contentStyleObj: {
                height: '',
                width: ''
            },
            GetWindowHeight: {
                height: ''
            },
            dataList: [
                {
                    id: 1,
                    time: '2022 Q2',
                    content: 'techRoadMap1'
                },
                {
                    id: 2,
                    time: '2022 Q3',
                    content: 'techRoadMap2'
                },
                {
                    id: 3,
                    time: '2022 Q4',
                    content: 'techRoadMap3'
                },
                {
                    id: 4,
                    time: '2023 Q1',
                    content: 'techRoadMap4'
                },
                {
                    id: 5,
                    time: '2023 Q2',
                    content: 'techRoadMap5'
                }, {
                    id: 6,
                    time: '2023 Q3',
                    content: 'techRoadMap6'
                }
            ]
        }
    },
    created() {
        window.addEventListener('resize', this.getHeight)
        this.getHeight()
    },

    destroyed() {
        window.removeEventListener('resize', this.getHeight)
    },
    methods: {
        getHeight() {
            // 获取浏览器高度
            this.contentStyleObj.width = window.innerWidth + 'px'
            this.contentStyleObj.height = window.innerHeight + 'px'
            // 获取浏览器高度，减去顶部导航栏的值70（可动态获取）,再减去head-DIV高度值80
            this.GetWindowHeight.height = window.innerHeight - 200 + 'px'
        },
        gomd(id) {
            // 点击题号跳
            this.$el.querySelector('#' + id).scrollIntoView()
        },
        clickgo(id) {
            // 点击题号跳转
            document.querySelector('#' + id).scrollIntoView()
        },
        downloadPDF(url) {
            // const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            // const pdfUrl = 'http://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
            downloadjs(url)
        }
    }
}
</script>
<style lang="less" scoped>
.home {
    background: url("../assets/images/bg.png") no-repeat center top !important;

    .advantage {
        font-family: 'Gautami-Bold';
        width: 100%;
        height: 872px;
        // background: url('../assets/images/bg2.png') no-repeat center;
        margin: 0 auto;

        .w {
            position: relative;
        }

        .title {
            font-family: 'MiSans';
            position: absolute;
            top: 270px;
            // left: 84px;
            font-weight: 500;
            font-size: 64px;
            line-height: 56px;
            color: #ffffff;

            b {
                position: absolute;
                top: 85px;
                color: #ffffff;
                font-weight: 500 !important;
            }
        }

        .adv-info {
            position: absolute;
            top: 500px;
            // left: 84px;
            width: 440px;
            height: 112px;
            line-height: 28px;
            font-size: 18px;
            color: #ffffff;
            font-weight: 500;
        }

        .pdf a {
            position: absolute;
            top: 720px;
            // left: 84px;
            width: 260px;
            height: 48px;
            line-height: 48px;
            background-image: linear-gradient(to right, #26C1FF, #2387FF);
            color: #FCFCFD;
            font-size: 16px;
            text-align: center;
            font-weight: 700;
            cursor: pointer;
            border-radius: 24px;
        }

        .pdf a:hover {
            background: #0043b3;
        }

        .logo {
            position: absolute;
            top: 90px;
            right: 30px;
        }
    }

    a {
        cursor: pointer;
    }

    .container {
        font-family: 'Gautami-Bold';
        box-sizing: border-box;
        width: 100%;
        height: 730px;
        padding-top: 150px;
        font-size: 42px;
        font-weight: 500;
        padding-bottom: 60px;

        .container-top {
            color: #ffffff;
        }

        .container-main {
            margin-top: 45px;
            display: flex;
            justify-content: space-between;

            .container-main-left {
                width: 40%;

                .container-main-lefttexts {
                    padding: 30px 110px;
                    background: rgba(0, 0, 0, 0.3);
                    color: #ffffff;
                    font-size: 24px;
                    border-radius: 20px;
                    color: #26C1FF;
                }

                .container-main-lefttext {
                    margin-top: 10px;
                    padding: 30px 110px;
                    background: rgba(0, 0, 0, 0.3);
                    color: #ffffff;
                    font-size: 24px;
                    border-radius: 20px;
                }
            }

            .container-main-right {
                width: 45%;
                font-size: 24px;
                font-weight: normal;
                background: rgba(0, 0, 0, 0.3);
                color: #ffffff;
                padding: 13px 20px;
                border-radius: 20px;

                // opacity: 0.5;
                .container-main-righttext {
                    padding: 13px 0;
                }
            }
        }

        .container-footer {
            color: #ffffff;
            background: rgba(0, 0, 0, 0.3);
            margin-top: 30px;
            padding: 20px;
            position: relative;
            border-radius: 20px;
            .container-footer-bi{
                position: absolute;
                top: -120px;
                left: -125px;
            }

            .container-footer-title {
                font-size: 24px;
                color: #26C1FF;
            }

            .container-footer-text {
                font-size: 16px;
                margin-top: 20px;
            }
        }
    }

    .timeInfo {
        height: 800px;
        width: 100%;
        color: #ffffff;
        margin-top: 100px;

        .timeInfo-title {
            font-size: 42px;
        }

        .timeInfo-item {
            display: flex;
            justify-content: space-between;

            .timeInfo-item-left {
                width: 45%;

                .timeInfo-item-left-text {
                    height: 100px;
                    margin-top: 50px;
                    background: rgba(0, 0, 0, 0.3);
                    padding: 20px 40px;
                    border-radius: 20px;
                    .timeInfo-item-left-text-title{
                        margin-bottom: 10px;
                        font-size: 32px;
                        color: #2387FF;
                    }
                }
            }

            .timeInfo-item-middle{
                width: 5px;
                height: 360px;
                background: #2387FF;
                margin-top: 130px;
                position: relative;
                .timeInfo-item-middle-xiala{
                    position: absolute;
                    top: -40px;
                    left: -24px;
                }
                .timeInfo-item-middle-tuoyuan{
                    position: absolute;
                    top: 160px;
                    left: -24px;
                }
                .timeInfo-item-middle-tuoyuan1{
                    position: absolute;
                    bottom: -52px;
                    left: -24px;
                }
            }
            .timeInfo-item-right {
                width: 45%;
                margin-top: 70px;
                position: relative;
                .timeInfo-item-right-bi{
                    position: absolute;
                    top: 165px;
                    right: 0px;
                    z-index: 1;
                }
                .timeInfo-item-left-text {
                    height: 100px;
                    margin-top: 50px;
                    background: rgba(0, 0, 0, 0.3);
                    padding: 20px 40px;
                    border-radius: 20px;
                    position: relative;
                    z-index: 999;
                    .timeInfo-item-left-text-title{
                        margin-bottom: 10px;
                        font-size: 32px;
                        color: #2387FF;
                    }
                }
            }

        }
    }
}
</style>
<!-- 移动端 -->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .home {
        .advantage {
            background: url('../assets/images/shift-bg2.png') no-repeat center;
            background-size: 7.5rem 8.32rem;
            height: 8.32rem;
        }

        .w {
            width: 6.1rem;
            margin: 0 auto;

            .title {
                width: 6.31rem;
                font-size: 0.45rem;
                top: 0.85rem;
                left: -0.1rem;

                b {
                    width: 3.4rem;
                    top: 0.6rem;
                }
            }

            .adv-info {
                width: 3.22rem;
                height: 1.95rem;
                line-height: 0.38rem;
                font-size: 0.24rem;
                top: 2.27rem;
                left: -0.1rem;
                color: #777E90;
                font-weight: 0;
            }

            .pdf a {
                position: absolute;
                top: 7.32rem;
                left: -0.1rem;
                width: 3.3rem;
                height: 0.55rem;
                line-height: 0.53rem;
                background-color: #FFFFFF;
                color: #246EFF;
                font-size: 0.13rem;
                text-align: center;
                font-weight: 0;
                cursor: pointer;
                border: 0.01rem solid #246EFF;
            }
        }

        .container {
            width: 100%;
            height: 10rem;
            padding-top: 0rem;

            .w {
                width: 6.98rem;

                .sideBar {
                    margin-left: 0px;
                    float: none;

                    .sideTitle {
                        width: 3.78rem;
                        height: 0.73rem;
                        margin: 0 auto;
                        line-height: 0.73rem;
                        background-color: #ffffff;
                        font-size: 0.26rem;
                        color: #246EFF;
                    }

                    .titles {
                        display: none;
                    }
                }

                .contant {
                    float: none;
                    box-sizing: border-box;
                    width: 6.98rem;
                    height: 0.17rem;
                    margin: 0 auto;

                    h2 {
                        height: 0.17rem;
                        line-height: 0.17rem;
                        font-size: 0.2rem;
                        color: #94A5C5;
                        font-weight: normal;
                        text-align: center;
                    }

                    .text1 {
                        width: 6.98rem;
                        height: 4rem;
                        background-color: #F7F9FF;
                        margin-top: 0.3rem;
                        padding: 0.45rem 0 0 0;
                        border-radius: 0.2rem;

                        .textT {
                            height: 0.17rem;
                            line-height: 0.17rem;
                            font-weight: 12.21rem;
                            font-size: 0.22rem;
                            text-align: center;
                        }

                        .textW {
                            margin-top: 0.62rem;
                            padding: 0 0.24rem;

                            .bold {
                                height: 0.4rem;
                                line-height: 0.4rem;
                                font-size: 0.19rem;
                                color: #94A5C5;
                                margin-bottom: 0.45rem;
                            }
                        }
                    }

                    .text2 {
                        width: 6.98rem;
                        height: 4rem;
                        background-color: #F7F9FF;
                        margin-top: 0.3rem;
                        padding: 0.45rem 0 0 0;
                        border-radius: 0.2rem;

                        .textT {
                            height: 0.17rem;
                            line-height: 0.17rem;
                            font-weight: 12.21rem;
                            font-size: 0.22rem;
                            text-align: center;
                        }

                        .textW {
                            margin-top: 0.62rem;
                            padding: 0 0.24rem;

                            .light {
                                width: 6.5rem;
                                height: 0.4rem;
                                line-height: 0.4rem;
                                font-size: 0.19rem;
                                color: #94A5C5;
                                margin-bottom: 0.45rem;
                            }
                        }
                    }
                }
            }
        }

        .timeInfo {
            font-family: 'Poppins';
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 15.33rem;
            //  background-color:#F7F9FF;
            padding-top: 0.73rem;
            display: flex;

            .line {
                display: none;
            }

            .photo {
                height: 12.22rem;
                width: 0.17rem;
                margin-left: 0.62rem;
                margin-top: 1.25rem;
                background: url('../assets/images/StepCounting.png') no-repeat;
                background-size: 0.17rem 12.22rem;
                display: block;
            }

            .timeTitle {
                box-sizing: border-box;
                width: 2.6rem;
                height: 0.48rem;
                line-height: 0.48rem;
                text-align: center;
                margin: 0 auto;
                background-color: #DFEAFF;
                border-radius: 0.48rem;
                font-size: 0.26rem;
                color: #021829;
                font-weight: 8.72rem;
            }

            .timeLine {
                margin-top: 0.26rem;
                display: flex;
                flex-wrap: wrap;

                div:nth-child(n+4) {
                    text-align: left;
                }

                div {
                    box-sizing: border-box;
                    width: 5.49rem;
                    height: 1.6rem;
                    padding: 0.17rem;
                    background-color: #FFFFFF;
                    border-radius: 0.2rem;
                    margin: 0 0.52rem 0.7rem 0;
                }

                div:nth-child(3),
                div:nth-child(6) {
                    margin-right: 0;
                }

                .year {
                    width: 1.43rem;
                    height: 0.48rem;
                    line-height: 0.48rem;
                    font-size: 0.31rem;
                    font-weight: 8.72rem;
                    color: #021829;

                }

                .info {
                    text-align: left;
                    width: 5.1rem;
                    margin-top: 0.1rem;
                    font-size: 0.20rem;
                    color: #94A5C5;
                }
            }
        }
    }
}
</style>
