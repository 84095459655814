export default {
    home: 'ホーム',
    technology: 'テクノロジー',
    ecological: 'エコシステム',
    browser: 'ブロックチェーンブラウザ',
    recruitment: 'ノード',
    wallet: 'ウォレット',
    contact: 'お問い合わせ',
    joinUs: '参加する',
    partner: '協力パートナー',
    language: '言語',
    learnMore: 'もっと学ぶ',
    company: 'EDAO DEVELOPMENT FOUNDATION LTD.',
    funds: 'ICW基金',
    lucky: 'ラッキーターンテーブル',
    hashGuess: 'ハッシュコンテスト',
    prize: '100％勝利、巨大な賞金プール',
    prizeBtn: 'GO',

    White: 'ホワイトペーパーのダウンロード',
    Introduction: 'トライデントチェーンの紹介',

    Numbertransactions: 'トランザクション数',
    Blockheight: 'ブロック高',
    Coretoken: '合計ステーク',

    aboutICW: 'トライデントチェーンについて',
    whitePaper: 'ホワイトペーパー',
    ICWwhitePaper: 'TDCChain ホワイトペーパー',
    technologyFunctions: '技術＆機能',
    roadmap: 'ロードマップ',
    map: 'ロードマップ',

    welcometoICW: 'TDC CHAINへようこそ',
    bannerText: '深海のブロックチェーンの新しい方向性を探る。',
    bannerText2: 'TridentChainはパワーユーザー、新規ユーザー、その間の全ての人々のための体験をサポートします。',
    whitePaperDownload: '白書ダウンロードリンク',
    whychoose: 'なぜ選ぶのか',
    aspires: 'ICWはWEB3.0のナビゲーターになることを目指しています',
    decentralizationTitle: '分散化、セキュリティ、高性能',
    decentralizationTitle1: '分散化、',
    decentralizationTitle2: 'セキュリティ、高性能',
    decentralizationSubtitle: 'TridentChainはPoW+DpoSハイブリッドコンセンサスモデルを採用して、ブロックチェーンネットワークの分散化とコミュニティの公平性を向上させるために、ノードの参加閾値と会計グループのランダム性を増加します。',
    contractTitle: '一般的な取引スマートコントラクト',
    contractSubtitle: 'TridentChainは、世界の開発者コミュニティによって主導される分散型のパブリックチェーンです。速く、エネルギー効率が良く、信頼性があります。開発者に強力なオープンを提供します。',
    highlyModularTitle: '高度にモジュラー化',
    highlyModularSubtitle: 'TridentChainはNULSモジュールアーキテクチャを参照しており、ホットスワップ、高効率、長寿命、時代を超越した特徴を備えています。これにより、ブロックチェーンの全体的な複雑さが大幅に削減され、その設計、デバッグ、メンテナンスプロセスが合理化されます。',
    crossChainCommunicationTitle: 'クロスチェーンコミュニケーション',
    crossChainCommunicationSubtitle: '将来のクロスカレンシー交換をサポートするために、クロスカレンシー通信チェーンを確立します。',
    engineSubtitle: 'ICWChainは、ネットワークが同時に数千のスマートコントラクトコールを処理できる超並列トランザクション処理エンジンも採用しています。',

    ICWTechLight1: 'ICWCHAINの技術',
    ICWTechLight2: 'ハイライト',

    ICWTechLightSub: 'ICWChainはDPOS + POWハイブリッド合意形態を採用し、技術レベルで高度にモジュール化され、拡張可能なアプリケーション層の構造を設計しています。これにより、クロスチェーン通信、並行チェーンの構築、およびチェーン外の情報の保存がより便利になります。',
    techIntroduction: '技術紹介',
    succeed: '成功',
    whitePaperCn: 'ICWChain 白書 - 中国語',
    whitePaperEn: 'ICWChain 白書 - 英語',
    decentralizationSubContent1: '分散化 - DPOS+POWハイブリッド合意形態',
    decentralizationSubContent2: 'ICWChainコミュニティのメンバーは投票によって選挙ノードを選出し、選挙ノードは計算能力の競争を通じてスーパーノードの座を競い、そのラウンドの記帳権を獲得します; 記帳ノードはブロックのパッケージングを完成した後、この記帳タスクを終了します。その後、上記のプロセスを通じて新しいラウンドのスーパーノードを選択し、新しいラウンドの記帳に入ります。',
    decentralizationSubContent3: '高性能および低遅延 - 百万TPS、秒レベルの検証',
    decentralizationSubContent4: '分散度を保証する前提のもとで、取引処理効率をさらに向上させ、最速の取引速度は秒レベルに達することができます。',
    decentralizationSubContent5: 'セキュリティ - 二重合意防衛と悪行の難易度の向上',
    decentralizationSubContent6: "ICWChainはハイブリッド合意形態を採用し、記帳グループのために複数のローテーション選挙を行うことにより、ノードでの悪行の難易度とコストを高め、ユーザーのデジタル資産のセキュリティを保証します。",
    contractSubContent: 'ICWChainは、オンチェーンマッチングおよび取引注文管理に完全に分散化されたソリューションを拡張しました。ICWChainが元々作成した一般取引スマートコントラクトに基づいて、従来の集約マッチングモジュールのすべての取引ペアを独自のモジュール（スマートコントラクト）に分け、ICWChainのトークン上で他の通貨を持つユーザーが契約を書き、開始するときに、完全に分散化されたチェーン上の資産の取引を実現するために、ICWChain一般取引スマートコントラクトを呼び出し、一つまたは複数の取引ペアをバインドし、取引ペアの通貨はICWChainで発行された契約通貨である必要があります。同時に、一般取引契約には独自のマッチング取引機能があり、ユーザーは契約内で直接注文を掛けて、チェーン上の資産の分散取引を完了することができます。',
    contractSubContent1: 'ICWChainは、オンチェーンマッチングおよび取引注文管理に完全に分散化されたソリューションを導入しました。ICWChainによって開発された基本的な取引スマートコントラクトを活用し、このアプローチは従来の集約マッチングモジュールから取引ペアを独立したモジュール（スマートコントラクト）にセグメント化し、チェーン上の資産の完全に分散化された取引を実現します。',
    contractSubContent2: '他の通貨を使用するユーザーがICWChainのトークンに関する契約を書いて開始する際には、ICWChain一般取引スマート契約を呼び出し、一つ以上の取引ペアを結びつける必要があります。取引ペアの通貨は、取引のためにICWChain上で発行された契約通貨でなければなりません。同時に、一般取引契約には独自のマッチング取引機能があり、ユーザーは契約内で直接注文を掛けて、チェーン上の資産の分散型取引を完了することができます。',
    highlyModularSubContent: 'ICWChainによって参照されるNULSモジュラーインフラストラクチャは、ICWChainエコロジーの最も基本的な部分です。NULSのモジュールアーキテクチャは、ホットプラグ、高効率、長期間、無時間などの特徴を持っています。良いモジュラリティはブロックチェーンの全体的な複雑さを大幅に減少させ、ブロックチェーンの設計、デバッグ、保守を簡素化することができます；各モジュールは依然として独立して動作することができます。NULSモジュールは「モジュール」の標準定義を提供します。全てのICWChainモジュールは、NULSモジュール仕様に従います。ICWChainモジュールマネージャーは、全モジュールの管理を監督し、モジュールのロード/アンロードやサービス登録などの行動の仕様を概説します。NULSモジュール仕様に準拠する任意の拡張モジュールは、モジュールマネージャーを介してICWChainノードにロードすることができます。',
    highlyModularSubContent1: 'ICWChainによって参照されるNULSモジュラーインフラストラクチャは、ICWChainエコロジーの最も基本的な部分です。NULSのモジュールアーキテクチャは、ホットプラグ、高効率、長期間、無時間などの特徴を持っています。良いモジュラリティはブロックチェーンの全体的な複雑さを大幅に減少させ、ブロックチェーンの設計、デバッグ、保守を簡素化することができます；各モジュールは依然として独立して動作することができます。NULSモジュールは「モジュール」の標準定義を提供します。',
highlyModularSubContent2: '全てのICWChainモジュールはNULSモジュール定義に従います。ICWChainモジュールマネージャーは、全モジュールの管理を担当し、モジュールのローディング/アンローディングやサービス登録などの仕様を定義します。NULSモジュール仕様に従う全ての拡張モジュールは、モジュールマネージャーを通じてICWChainノードにロードされます。',
    crossChainCommunicationSubContent: 'ICWChainは、アライアンスチェーンのクロスチェーンゲートウェイを設計開発しました。このゲートウェイを通じて、アライアンスチェーンと主流のパブリックチェーン間のデータおよびトークンの相互接続が実現されます。トークンのクロスチェーンフローはIBCプロトコルを通じて実現されます。',
    engineSubContent: 'ICWChainは、超並行取引処理エンジンを採用しており、ネットワークが同時に数千のスマートコントラクトコールを処理することを可能にします。そのパイプラインインフラストラクチャを通じて、ICWChainは取引をより速く検証し、ハードウェアの仕様に関わらず、ノード間で取引情報をコピーすることもできます。',
    techRoadMap1: "TridentChainベータのローンチ、ノード募集の開始、DEXでの取引開始",
    techRoadMap2: 'サードパーティのウォレットによるサポート、TridentChainはマルチチェーンインタラクションをサポート',
    techRoadMap3: 'TridentChainメインネットのローンチ、コミュニティは50,000メンバーを超える',
    techRoadMap4: 'TridentChainエコシステムに複数のアプリケーションが統合される',
    techRoadMap5: 'TridentChainの推進とブランド認知度の向上、TridentChainコミュニティのさらなる開発を続ける',
    techRoadMap6: 'より多くのアプリケーションがTridentChainエコシステムに参加することを奨励',
    ICWCHAIN: 'TridentChain',
    adopts: 'TridentChainは、POW+DPOS合意形態を採用したモジュラーパブリックブロックチェーンで、ブロックチェーンエコシステムの持続的な成長と発展のための強力なサポートを提供することに専念しています。私たちのノードは、このビジョンを達成する上で重要な役割を果たしています。',

    ecologicalNetTit: 'ICWCHAIN生態系ネットワーク',
    ecologicalNetTit1: 'TridentChainエコシステム',
    ecologicalNetTit2: 'ネットワーク',
    ecologicalSub: 'ICWChainは、「自由と平等、民主主義と自主性、協力と共有」という完全な社会ネットワークエコシステムを持ち、「ビジョンの合意、権利と利益の合意、価値の合意、ガバナンスの合意、収入の合意、技術の合意」の基盤上で全てのコミュニティメンバーによって開発されています。',
    ecologicalSub1: "TridentChainは、「自由と平等を受け入れ、民主主義と自主性を支持し、協力と共有を促進する」という価値観を核とする、活気に満ちた社会ネットワークエコシステムです。開かれたウェブの未来を築くために、一致団結しましょう。",
    ecologicalSub2: "一緒に開かれたウェブを構築しましょう。",
    ecologicalAppTit: 'ICWChain生態系アプリケーション',

    API: 'API文書',
    NodeCreation: 'ノード作成文書',

    Find1: 'TridentChainで最も注目のプロジェクトを発見',
    Find2: 'チェーン？',
    development: 'バランスの取れた健全な生態系開発',
    ICWNode: 'TridentChainノード',
    incentive: 'ノード募集',
    section: 'TridentChainノードとは何か？',
    bookkeeping: 'TridentChainノードは、ネットワークの安全性、安定性、分散性を維持するための基本的な構成要素です。これらのノードは、エコシステムの核心を構成し、スムーズな運用を保証します。',
    ServiceNode: 'ノードの作成とステーキング',
    servicenode: 'TridentChainノードを作成するには、1000TDC（TridentChainトークン）のデポジットが必要です。ノードごとの最低ステーキング額は3000TDCで、最大ステーキング限度は30,000TDCです。',
    Grossincome: '会計グループと報酬メカニズム',
    Grossincomes: '会計グループの1ラウンドは27ノードから構成されます。1つの会計期間（45分）に、ラウンドロビン方式（各ノードが10ブロックを生成し、合計270ブロック）を通じてブロックが生成されます。各ブロックの確認時間は10秒です。当初、各ブロックの報酬は10TDCトークンで、この報酬は年間15％減少します。この動的報酬メカニズムは、TridentChainネットワークの長期的な持続可能性を支える新トークンの秩序ある発行を保証します。',

    ecologicalAppTitle1: 'デジタルアイデンティティと信頼できるネットワーク',
    ecologicalAppTitle2: '金融分野とDeFi',
    ecologicalAppTitle3: '分散型ソーシャルネットワーキング',
    ecologicalAppTitle4: 'Eコマースと国境を越えた支払い',
    ecologicalAppTitle5: '著作権保護とNFT',
    ecologicalAppTitle6: 'チャリティー',
    ecologicalContent1: 'ICWChainのブロックチェーン分散ストレージシステムは、多数の通常のサーバーを接続し、ネットワーク接続を介して集合的にストレージソリューションを提供します。これは、データ検証とストレージのためにブロックチェーンデータ構造を使用し、データ生成と更新のために分散ノードコンセンサスアルゴリズムを利用します。これにより、包括的で効率的かつ安全なデータベースの維持が保証されます。',
ecologicalContent2: 'ICWChainは、デジタル世界での資産概念を再定義し、あらゆる形態の資産を表すデータ資産のオンライン権利と利益の定義を確立し、取引行動の保護を保証します。ICWChainブロックチェーンネットワークは、金融業界内で主流で信頼できるブロックチェーンシステムにアクセスし、構築します。これにより、安全なトークン転送および交換メカニズムを通じて、金融業界のさまざまなアプリケーション間の協力が促進されます。',
    ecologicalContent3: 'ICWChainは、ブロックチェーン技術を通じてコンテンツソーシャルプラットフォームに新しい血を注入します。ICWChain生態系アプリケーションの重要なメンバーであるESEEWORLDは、分散管理を使用してプラットフォームを分散化します。',
    ecologicalContent4: 'ICWChainはブロックチェーン技術を証券取引に適用し、各参加者が独立して全体の決済プロセスを完了できるように便利にします。ICWChainが国境を越えた支払い分野にポイントツーポイント技術を適用した後、送金チャネルは透明になり、取引履歴データが追跡できるようになります。これは、受取人と送金人を安心させるだけでなく、効率と速度を大幅に向上させます。',
    ecologicalContent5: 'ブロックチェーンネットワークの特性に依存して、ICWChainはメタバース内での希少属性および資産の所有権の検証と確認を可能にします。これにより、ユーザーの装備、小道具、土地所有権などの資産のデジタル表現をマッピングすることで、データコンテンツの価値流れが可能になります。その結果、これらの資産はデジタル世界で取引可能なエンティティになります。',
    ecologicalContent6: 'ブロックチェーン技術は、チャリティー分野の情報の透明性を大幅に向上させることができ、ドナーにチャリティーシステムとプロセスを監督する権利を与え、ドナーと受取人のプライバシーを保護します。',

    mission: 'ICWCHAINの使命',
    mission1: 'ICWチェーン',
    mission2: '使命',
    contactSub: '中央集権型インターネットプラットフォームと比べて、ブロックチェーンは、安定性が高く、リスク耐性が優れており、公平性とシンプルさが増します。ICWChainは情報の相互作用の方法を変え、中央集権的なアイデアでは解決できないアプリケーションの課題を克服し、デジタル経済の構築の実施を促進しました。Web3.0と分散型アイデアを密接に統合しました。',

    vipkefu: 'VIPサービス（Wechat）',
    community: 'ICWChainコミュニティ',
    Telegram: 'テレグラム',
    Twitter: 'ツイッター',

    recruitTit: 'ICWジェネシスノード募集',
    userManual: 'ユーザーマニュアル',
    selfCreation: 'セルフクリエーション',
    replaceCreation: '代理構築サービス',
    agentConstruction: '代理構築',
    installtion: 'ノードインストールパッケージ',
    account: 'お名前または法人名を入力してください',
    mobileAccount: '携帯電話番号を入力してください',
    emailAccount: 'メールアドレスを入力してください',
    infoText1: 'ユーザーはプラットフォームを通じてサーバーホスティングサービスを購入し、ワンツーワンのカスタマーサービスを楽しむことができます。',
    infoText2: '顧客が購入意向フォームを記入した後、アカウントマネージャーがメールを送信し、顧客が続くサーバー購入操作を完了するように案内します',
    thirdPartyServices: 'このサービスは第三者のESEEWORLDによって提供されます',
    apply: '即時申請',
    advTitle1: 'ICWChain公有チェーンのメインネットが間もなくローンチされます',
    advTitle2: '現在、世界中のジェネシスノードの募集をしています',
    advContent1: 'ICWChainは、分散性、高性能、データセキュリティを考慮した革新的な公有チェーンネットワークです。',
    advContent2: 'ICWChainは、第三世代インターネット科学技術革命の波の中での革新者であり、航海者となり、グローバルデジタル経済システムの構築を促進することを前進力と最終的なミッションとします。',
    nrTitle: 'ノード募集期間',
    ndTitle: 'ノードデプロイメント期間',
    nrTime: '5月26日12:00 p.m. から 6月15日',
    ndTime: '6月15日 12:00 p.m. から 6月20日',
    ndTime1: 'ノードの起動と運行',
    nrContetn1: '• ユーザーはプラットフォームを通じてサーバーホスティングサービスを購入し、1対1のカスタマーサービスを提供できます。',
    nrContetn2: '• 3か月ごとに、20,000 ICWS（20uの価値）がホスティングサービスに対して授与されます。購入すればするほど、より多くの報酬を得られます。早く参加して早く利益を得ましょう。詳細はカスタマーサービスにご相談ください。',
    ndContetn1: '• ノードの募集期間中、カスタマーマネージャーに相談してマネージドサーバーを購入したユーザーは、ICWエアドロップ報酬を楽しむことができ、作成されたノードのプレッジに直接使用することができます。',
    recruitmentTit: 'ICWジェネシスノード報酬プランと収益',
    accountingTitle: '会計グループ',
    accountingContent: '会計グループは15の合意ノードで構成されています。彼らは交代で6秒ごとにブロックを作成し、会計期間は15分です（各ノードは10ブロックを作成するため、合計で150ブロックがあります）、そして毎日14,400ブロックが作成されます。ブロック作成の報酬は4年ごとに半減し、初期報酬はブロックあたり100 ICWsです。初期の日報酬は144万ICWsであり、年間報酬は5億2560万ICWsです。したがって、報酬が21億240万に達すると、半減します。',
    nodeTitle: 'サービスノード',
    nodeContent: '元帳サービスを同期するために、サービスノードは会計期間ごと（15分）に1回、1日に96回報酬を受け取ります。同期報酬は4年ごとに半減し、初期報酬は会計期間ごとに7,500 ICWsです（すべての同期ノードに均等に配布されます）。初期の日報酬は720,000 ICWsであり、年間報酬は2億6280万ICWsです。したがって、報酬が10億5120万に達すると、半減します。',
    profitTitle: '総収入',
    profitContent: 'ノードの担保が50万ICWに達し、ブロックが必ず生成される（私たちの前提条件）場合、1分半でブロックが生成され、収入はブロックあたり100 ICWsになります。推定収入は1時間に4,000 ICWs、1日に96,000 ICWs、1年に3,504万ICWsで、リターンは約70倍と予想されます。',
    nodeCreation: 'ノード作成',
    qualification: 'ノード作成資格',
    process: 'ノード作成プロセス',
    pledge: 'ノード担保',

    // create global world
    networkEnvironmentSettings: 'ネットワーク環境設定',
    deposit: '預金 ≥ 20,000 ICW',

    edaos: 'EDAO開発財団',
    edaos: 'EDAO財団は、ICWChainプロトコル、分散型ネットワーク、および開発者エコシステムの成長と開発を支援することに専念しています。',
    Social: 'ソーシャルプラットフォーム',
    Follow: 'フォローして、TridentChainの詳細を学びましょう',
    Discord: 'Discordは、音声およびテキストチャットのためのアプリで、web3の主流コミュニティコミュニケーションソフトウェアです。',
    github: 'オンラインソフトウェアソースコードホスティングサービスプラットフォーム',
    Twitters: '公開対話に奉仕することに専念しています',
    Telegrams: '世界で最も人気のあるクロスプラットフォームインスタントメッセージングソフトウェアの一つです',
    ljgd: '私たちに参加してください',
    voice: 'ユーザーはゲーム自体により焦点を当てており、スムーズな声はCPUをあまり使用しません',
    safe: '安全で無料です',
    Support: '大規模なユーザーが同時にオンラインでコミュニケーションをとることをサポートし、標準化された興味コミュニティ',
    serverSetup: 'サーバー設定',
    serverSetup1: 'A. 自己構成ネットワーク',
    serverSetup2: 'B. サーバーエージェント',
    loginWallet: 'ICWウォレットにログイン',
    fillinInfo: 'ノード情報を記入',
    depositPayment: '入金',
    creationSucceeded: 'ノード作成に成功し、誓約の完了を待っています',
    ICWPledge: 'ICWの誓約',
    nodeOperation: '誓約額が200,000ICWSを超える場合、ノードを運用できます',
    more: 'ICW Chainについてもっと知りたいですか',

    // dialog upgrade
    DearICWChainPartner: '親愛なるICWChainのパートナーへ',
    OK: 'OK',
    popupDate: 'ICWChainは2022年9月24日の9:00-18:00 (UTC/GMT+08:00)にアップグレードを行います。',
    popupWarn: 'アップグレード中は、資産の転送操作を行わないでください！',
    popupText1: 'このアップグレードには主に、ブロックのパッケージング効率の向上、難易度のコンセンサスアルゴリズムおよびノード投票のコンセンサスの最適化が含まれます。',
    popupText2: 'アップグレード完了後、会計グループノードのブロック生成時間は12秒/ブロックに調整され（つまり、会計の回転は1800秒/期間に調整されます）,',
    popupText3: 'ブロック出報酬は200ICW/ブロックに、同期コンセンサス報酬は15000ICW/会計期間に調整されます。各ノードのユーザーは、9月24日の9:00-18:00 (UTC/GMT+08:00)の間に自身のノードをアップグレードする必要があります。遅延したアップグレードによるノードの損失は自己責任で負うべきです。',
    popupText4: '同時に、このアップグレード後、ICWChainはUSDT-IRC20のリリースをサポートする予定です。',

    // banner heading
    hbtitle: 'SETH ICO (フェーズI)',
    hbtime: '2022/11/10 10:00:00（UTC+8）～2022/12/2 17:00:00（UTC+8）',
    hbBtn: 'もっと知る',
    hbuy: '完売',
    // hbuy: '購入',
    // dialog
    name: 'SETH CHAIN',
    diallogtext1: '新しいDPOS+POWハイブリッドコンセンサスメカニズム',
    diallogtext2: 'イーサリアムコンセンサスの最適化',
    diallogtext3: 'イーサリアムのマイニングマシンと仮想マシンのサポート',
    diallogtext4: 'オリジナルイーサリアムマイナーの移行促進とオンチェーンエコロジーの構築',
    dialogT: 'ICO (フェーズI)',
    begin: 'から',
    dialogStart: '2022年11月10日、午前10時',
    end: 'まで',
    dialogEnd: '2022年11月25日、午後5時',
    dialogInfo: '20U',
    dialogOline: 'メインネットのローンチ',
    dialogOlineTime: '2023年3月',
    dialogKnow: 'OK',
    address: 'アドレスです',
    city2: 'マレーシア',
    city2Ad: 'マレーシア連邦領ラブアン、ジャラン・メルデカ、ファイナンシャル・パーク・ラブアン、メイン・オフィス・タワー、レベル15(A1)、87000',
    city1: 'シンガポール',
    city1Ad: 'シンガポール534818 セントロ・ビアンコ 73アッパー・パヤ・レバー・ロード #06-01C',
    gmail: 'Gmail',
    emailAddress: 'admin@icwchain.com'
}
