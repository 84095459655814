export default {
    home: 'BERANDA',
    technology: 'TEKNOLOGI',
    ecological: 'EKOSISTEM',
    browser: 'PERAMBAN BLOCKCHAIN',
    recruitment: 'NODE',
    wallet: 'DOMPET',
    contact: 'HUBUNGI KAMI',
    joinUs: 'BERGABUNG DENGAN KAMI',
    partner: 'Mitra Kerjasama',
    language: 'BAHASA',
    learnMore: 'Pelajari lebih lanjut',
    company: 'EDAO DEVELOPMENT FOUNDATION LTD.',
    funds: 'Dana ICW',
    lucky: 'Roda keberuntungan',
    hashGuess: 'Kontes hash',
    prize: 'Menang seratus persen, hadiah besar',
    prizeBtn: 'AYO',

    White: 'Unduh kertas putih',
    Introduction: 'Pengenalan TridentChain',

    Numbertransactions: 'Jumlah transaksi',
    Blockheight: 'Tinggi blok',
    Coretoken: 'Total Stake',

    aboutICW: 'Tentang TridentChain',
    whitePaper: 'Kertas Putih',
    ICWwhitePaper: 'Kertas Putih TDCChain',
    technologyFunctions: 'Teknologi & Fungsi',
    roadmap: 'Peta Jalan',
    map: 'Peta jalan',

    welcometoICW: 'SELAMAT DATANG DI RANTAI TDC',
    bannerText: 'Menjelajahi arah baru blockchain di laut dalam.',
    bannerText2: 'TridentChain mendukung pengalaman untuk pengguna berpengalaman, konsumen baru, dan semua orang di antaranya.',
    whitePaperDownload: 'Link unduh kertas putih',
    whychoose: 'Mengapa memilih',
    aspires: 'ICW bercita-cita menjadi navigator dari WEB3.0',
    decentralizationTitle: 'Desentralisasi, keamanan dan performa tinggi',
    decentralizationTitle1: 'Desentralisasi, ',
    decentralizationTitle2: 'keamanan dan performa tinggi',
    decentralizationSubtitle: 'TridentChain mengadopsi model konsensus hibrida PoW+DpoS untuk meningkatkan desentralisasi jaringan blockchain dan keadilan komunitas dengan meningkatkan ambang partisipasi node dan keacakan kelompok akuntansi.',
    contractTitle: 'Kontrak pintar perdagangan umum',
    contractSubtitle: 'TridentChain adalah rantai publik desentralisasi yang dipimpin oleh komunitas pengembang global. Ini cepat, hemat energi, dan dapat diandalkan. Ini menyediakan pengembang dengan dukungan terbuka yang kuat.',
    highlyModularTitle: 'Sangat modular',
    highlyModularSubtitle: 'TridentChain mengacu pada arsitektur modul NULS, yang memiliki fitur penukaran panas, efisiensi tinggi, umur panjang, dan keabadian. Ini secara signifikan mengurangi kompleksitas keseluruhan blockchain, mempermudah desain, debugging, dan proses pemeliharaannya. ',
    crossChainCommunicationTitle: 'Komunikasi Lintas Rantai',
    crossChainCommunicationSubtitle: 'Mendirikan rantai komunikasi lintas mata uang untuk mendukung pertukaran lintas mata uang di masa depan.',
    engineSubtitle: 'ICWChain juga mengadopsi mesin pemrosesan transaksi super paralel, yang memungkinkan jaringan untuk memproses ribuan pemanggilan kontrak pintar secara bersamaan.',

    ICWTechLight1: 'TEKNOLOGI ICWCHAIN',
    ICWTechLight2: 'SOROTAN',

    ICWTechLightSub: 'ICWChain mengadopsi mekanisme konsensus hibrid DPOS + POW, dan merancang struktur lapisan aplikasi yang sangat modular dan scalable pada tingkat teknis, yang membuat komunikasi lintas rantai, konstruksi rantai paralel, dan penyimpanan informasi di luar rantai menjadi lebih nyaman.',
    techIntroduction: 'Pengantar Teknologi',
    succeed: 'Berhasil',
    whitePaperCn: 'White paper ICWChain - Bahasa Cina',
    whitePaperEn: 'White paper ICWChain - Bahasa Inggris',
    decentralizationSubContent1: 'Desentralisasi - Mekanisme konsensus hibrid DPOS+POW',
    decentralizationSubContent2: 'Anggota komunitas ICWChain memilih node pemilihan melalui pemungutan suara, dan node pemilihan bersaing untuk kursi supernode melalui kompetisi kekuatan komputasi dan memperoleh hak perhitungan putaran; Node akuntansi mengakhiri tugas akuntansi ini setelah menyelesaikan pengepakan blok. Kemudian memilih putaran baru dari supernode melalui proses di atas dan memasuki putaran baru dari pencatatan.',
    decentralizationSubContent3: 'kinerja tinggi dan latensi rendah - jutaan TPS, verifikasi tingkat kedua',
    decentralizationSubContent4: 'Dengan prasyarat memastikan derajat desentralisasi, lebih lanjut meningkatkan efisiensi pemrosesan transaksi, dan tingkat transaksi tercepat dapat mencapai tingkat kedua.',
    decentralizationSubContent5: 'Keamanan - pertahanan konsensus ganda dan meningkatkan kesulitan melakukan kejahatan',
    decentralizationSubContent6: "ICWChain mengadopsi mekanisme konsensus hibrida untuk melakukan pemilihan rotasi berganda untuk grup akuntansi, meningkatkan kesulitan dan biaya melakukan kejahatan pada node, sehingga dapat memastikan keamanan aset digital pengguna.",
    contractSubContent: 'ICWChain memperluas solusi yang sepenuhnya desentralisasi untuk pencocokan dan manajemen urutan transaksi di rantai. Berdasarkan kontrak pintar transaksi umum yang awalnya dibuat oleh ICWChain, semua pasangan transaksi dalam modul pencocokan agregasi tradisional dibagi ke dalam modul mereka sendiri (kontrak pintar), sehingga dapat mewujudkan transaksi aset yang sepenuhnya desentralisasi di rantai ketika pengguna dengan mata uang lain menulis dan menginisiasi kontrak pada token dari ICWChain, mereka perlu memanggil kontrak pintar transaksi umum ICWChain dan mengikat satu atau lebih pasangan transaksi, dan mata uang dari pasangan transaksi perlu menjadi mata uang kontrak yang diterbitkan pada ICWChain untuk transaksi. Pada saat yang sama, kontrak transaksi umum memiliki fungsi transaksi pencocokan sendiri, dan pengguna dapat langsung menggantung pesanan dalam kontrak untuk menyelesaikan transaksi aset yang desentralisasi di rantai.',
    contractSubContent1: 'ICWChain telah memperkenalkan solusi sepenuhnya terdesentralisasi untuk pencocokan dan pengelolaan perintah transaksi di rantai. Dengan memanfaatkan kontrak cerdas transaksi dasar yang dikembangkan oleh ICWChain, pendekatan ini membagi pasangan transaksi dari modul pencocokan agregasi konvensional menjadi modul-modul terpisah (kontrak cerdas), sehingga dapat merealisasikan transaksi aset di rantai yang sepenuhnya terdesentralisasi.',

    contractSubContent2: 'ketika pengguna dengan mata uang lain menulis dan menginisiasi kontrak pada token dari ICWChain, mereka perlu memanggil kontrak cerdas transaksi umum ICWChain dan mengikat satu atau lebih pasangan transaksi, dan mata uang dari pasangan transaksi tersebut perlu menjadi mata uang kontrak yang dikeluarkan di ICWChain untuk transaksi. Pada saat yang sama, kontrak transaksi umum memiliki fungsi transaksi pencocokan sendiri, dan pengguna dapat langsung menggantung perintah dalam kontrak untuk menyelesaikan transaksi aset yang terdesentralisasi di rantai.',

    highlyModularSubContent: 'Infrastruktur modular NULS yang dirujuk oleh ICWChain adalah bagian paling mendasar dari ekologi ICWChain. Arsitektur modul dari NULS memiliki karakteristik hot plug, efisiensi tinggi, jangka panjang, tidak terikat waktu dan lain-lain. Modularitas yang baik dapat sangat mengurangi kompleksitas keseluruhan blockchain dan menyederhanakan desain, debugging, dan pemeliharaan blockchain; Setiap modul masih dapat beroperasi secara independen Modul NULS menyediakan definisi standar dari “modul”. Semua modul ICWChain mengikuti spesifikasi modul NULS. Manajer modul ICWChain mengawasi administrasi semua modul dan menguraikan spesifikasi untuk tindakan seperti pemuatan/pembongkaran modul dan pendaftaran layanan. Setiap modul ekstensi yang sesuai dengan spesifikasi modul NULS dapat dimuat ke dalam node ICWChain melalui manajer modul.',
    highlyModularSubContent1: 'Infrastruktur modular NULS yang dirujuk oleh ICWChain adalah bagian paling fundamental dari ekologi ICWChain. Arsitektur modul dari NULS memiliki karakteristik hot plug, efisiensi tinggi, jangka panjang, tanpa waktu, dan lain-lain. Modularitas yang baik dapat sangat mengurangi kompleksitas keseluruhan blockchain dan menyederhanakan desain, debugging, dan pemeliharaan blockchain; Setiap modul masih dapat beroperasi secara independen. Modul NULS menyediakan definisi standar dari "modul".',
    highlyModularSubContent2: 'Semua modul ICWChain mengikuti definisi modul NULS. Manajer modul ICWChain bertanggung jawab untuk mengelola semua modul dan mendefinisikan spesifikasi seperti pemuatan / pembongkaran modul dan pendaftaran layanan. Semua modul ekstensi yang mengikuti spesifikasi modul NULS dapat dimuat ke dalam node ICWChain melalui manajer modul.',
    crossChainCommunicationSubContent: 'ICWChain telah merancang dan mengembangkan sebuah gateway rantai silang dari rantai aliansi. Melalui gateway ini, interkoneksi data dan token antara rantai aliansi dan rantai publik utama direalisasikan. Aliran token rantai silang direalisasikan melalui protokol IBC.',
    engineSubContent: 'ICWChain mengadopsi mesin pemrosesan transaksi super paralel, yang memungkinkan jaringan untuk memproses ribuan panggilan kontrak pintar secara bersamaan. Melalui infrastruktur pipelinenya, ICWChain juga dapat memverifikasi transaksi lebih cepat dan menyalin informasi transaksi antar node, terlepas dari spesifikasi perangkat kerasnya.',
    techRoadMap1: 'Beberapa aplikasi terintegrasi ke dalam ekosistem TridentChain',
    techRoadMap2: 'Memajukan TridentChain dan meningkatkan kesadaran merek, Melanjutkan pengembangan komunitas TridentChain',
    techRoadMap3: "Meluncurkan di 3-4 bursa CMC top 20 untuk meningkatkan likuiditas token.",
    techRoadMap4: 'Mulai peristiwa pengembang, kontes, dan hadiah untuk menarik pengembang agar berpartisipasi dalam konstruksi ekologi; mendukung DApps dengan lebih banyak teknologi dan sumber daya, meningkatkan aktivitas ekosistem.',
    techRoadMap5: 'Tingkatkan kerja sama dengan mitra lintas rantai untuk memajukan ekosistem blockchain.',
    techRoadMap6: 'Mendorong lebih banyak aplikasi untuk bergabung dengan ekosistem TridentChain',
    ICWCHAIN: 'TridentChain',
    adopts: 'TridentChain adalah blockchain publik modular yang mengadopsi mekanisme konsensus POW+DPOS, didedikasikan untuk memberikan dukungan kuat untuk pertumbuhan dan pengembangan ekosistem blockchain yang berkelanjutan. Node kami memainkan peran krusial dalam mencapai visi ini.',

    ecologicalNetTit: 'JARINGAN EKOLOGIS ICWCHAIN',
    ecologicalNetTit1: 'Ekosistem TridentChain',
    ecologicalNetTit2: 'JARINGAN',
    ecologicalSub: 'ICWChain memiliki ekosistem jaringan sosial yang beragam dengan kebebasan dan kesetaraan, demokrasi dan otonomi, kerjasama dan berbagi yang lengkap, yang dikembangkan oleh semua anggota komunitas berdasarkan konsensus visi, konsensus hak dan kepentingan, konsensus nilai, konsensus tata kelola, konsensus pendapatan dan konsensus teknologi.',
    ecologicalSub1: 'TridentChain berdiri sebagai ekosistem jaringan sosial yang berkembang, di mana nilai-nilai menganut kebebasan dan kesetaraan, memegang teguh demokrasi dan otonomi, mendorong kolaborasi dan berbagi merupakan inti darinya. Mari bersatu untuk membentuk masa depan web terbuka.',
    ecologicalSub2: 'Mari membangun web terbuka bersama-sama.',
    ecologicalAppTit: 'Aplikasi ekologis ICWChain',
    API: 'Dokumentasi API',
    NodeCreation: 'Dokumentasi Pembuatan Node',

    
    Find1: 'Temukan Proyek Terpanas di TridentChain',
    Find2: 'CHAIN?',
    development: 'Pengembangan ekologi yang seimbang dan sehat',
    ICWNode: 'Node TridentChain',
    incentive: 'Rekrutmen Node',
    section: 'Apa Itu Node TridentChain?',
    bookkeeping: 'Node TridentChain adalah blok bangunan dasar dari jaringan, bertanggung jawab untuk menjaga keamanan jaringan, stabilitas, dan desentralisasi. Node-node ini merupakan inti dari ekosistem kami, memastikan operasi yang lancar.',
    ServiceNode: 'Pembuatan Node dan Staking',
    servicenode: 'Membuat node TridentChain memerlukan deposit sebanyak 1000 TDC (token TridentChain). Jumlah staking minimum per node adalah 3000 TDC, dengan batas maksimum staking sebesar 30.000 TDC.',
    Grossincome: 'Grup Akuntansi dan Mekanisme Reward',
    Grossincomes: 'Satu putaran grup akuntansi terdiri dari 27 node. Dalam satu periode akuntansi (45 menit), blok dihasilkan melalui proses round-robin (dengan setiap node menghasilkan 10 blok, total 270 blok). Setiap blok memiliki waktu konfirmasi 10 detik. Awalnya, setiap blok memberikan reward 10 token TDC, dan reward ini berkurang 15% setiap tahunnya. Mekanisme reward dinamis ini memastikan penerbitan token baru yang teratur untuk mendukung keberlanjutan jangka panjang jaringan TridentChain.',

    ecologicalAppTitle1: 'Identitas digital dan jaringan terpercaya',
    ecologicalAppTitle2: 'Bidang keuangan dan DeFi',
    ecologicalAppTitle3: 'Jejaring sosial terdesentralisasi',
    ecologicalAppTitle4: 'E-commerce dan pembayaran lintas batas',
    ecologicalAppTitle5: 'Perlindungan hak cipta dan NFT',
    ecologicalAppTitle6: 'Amal',
    ecologicalContent1: 'Sistem penyimpanan terdistribusi blockchain ICWChain menghubungkan banyak server reguler bersama-sama, menawarkan solusi penyimpanan sebagai kolektif melalui konektivitas jaringan. Ini menggunakan struktur data blockchain untuk verifikasi dan penyimpanan data, sambil memanfaatkan algoritma konsensus node terdistribusi untuk generasi dan pembaruan data. Ini memastikan pemeliharaan basis data yang komprehensif, efisien, dan aman.',
    ecologicalContent2: 'ICWChain mendefinisikan ulang konsep aset di dunia digital, dan menetapkan definisi hak dan kepentingan online untuk aset data yang mewakili bentuk aset apa pun, sekaligus memastikan perlindungan perilaku transaksi. Jaringan blockchain ICWChain akan mengakses dan membangun sistem blockchain yang mainstream dan terpercaya dalam industri keuangan. Ini akan memfasilitasi kolaborasi di antara berbagai aplikasi dalam industri keuangan melalui mekanisme transfer dan pertukaran token yang aman.',
    ecologicalContent3: 'ICWChain menyuntikkan darah baru ke dalam platform sosial konten melalui teknologi blockchain. Sebagai anggota penting dari aplikasi ekologis ICWChain, ESEEWORLD menggunakan manajemen terdistribusi untuk mendesentralisasi platform.',
    ecologicalContent4: 'ICWChain menerapkan teknologi blockchain pada perdagangan sekuritas, yang memudahkan setiap peserta untuk menyelesaikan seluruh proses penyelesaian secara mandiri setelah ICWChain menerapkan teknologi titik ke titik di bidang pembayaran lintas batas, saluran remitansi menjadi transparan dan data riwayat transaksi dapat dilacak, yang tidak hanya menenangkan penerima dan pengirim, tetapi juga sangat meningkatkan efisiensi dan kecepatan.',
    ecologicalContent5: 'Berdasarkan karakteristik jaringan blockchain, ICWChain memungkinkan verifikasi dan konfirmasi kepemilikan untuk atribut dan aset langka dalam metaverse. Hal ini memungkinkan aliran nilai konten data dengan memetakan representasi digital dari aset seperti peralatan pengguna, properti, hak kepemilikan tanah di dalam "metaverse". Akibatnya, aset-aset ini menjadi entitas yang dapat diperdagangkan di dunia digital ini.',
    ecologicalContent6: 'Teknologi blockchain dapat sangat meningkatkan transparansi informasi di bidang amal, memberikan donor hak untuk mengawasi sistem dan proses amal, serta melindungi privasi donor dan penerima.',

    mission: 'MISI ICWCHAIN',
    mission1: 'ICW CHAIN',
    mission2: 'MISI',
    contactSub: 'Dibandingkan dengan platform internet terpusat, blockchain menawarkan stabilitas yang lebih baik, resistensi risiko yang superior, keadilan dan kesederhanaan yang lebih besar. ICWChain telah mengubah cara interaksi informasi, mengatasi tantangan aplikasi yang tidak dapat dipecahkan dengan ide terpusat, mempromosikan implementasi pembangunan ekonomi digital. Ini telah terintegrasi erat dengan Web3.0 dengan ide terdistribusi.',

    vipkefu: 'Layanan VIP（Wechat）',
    community: 'Komunitas ICWChain',
    Telegram: 'Telegram',
    Twitter: 'Twitter',

    recruitTit: 'PENERIMAAN NODE GENESIS ICW',
    userManual: 'Manual Pengguna',
    selfCreation: 'Penciptaan Diri',
    replaceCreation: 'Layanan Konstruksi Agen',
    agentConstruction: 'Konstruksi Agen',
    installtion: 'Paket Pemasangan Node',
    account: 'Silakan masukkan nama Anda atau nama entitas',
    mobileAccount: 'Silakan masukkan nomor ponsel Anda',
    emailAccount: 'Silakan masukkan alamat email Anda',
    infoText1: 'Pengguna dapat membeli layanan hosting server melalui platform dan menikmati layanan pelanggan satu-ke-satu.',
    infoText2: 'Setelah pelanggan mengisi formulir intensi pembelian, manajer akun akan mengirim email untuk memandu pelanggan menyelesaikan operasi pembelian Server berikutnya',
    thirdPartyServices: 'Layanan ini disediakan oleh pihak ketiga ESEEWORLD',
    apply: 'Ajukan Segera',
    advTitle1: 'Jaringan utama blockchain publik ICWChain akan segera diluncurkan',
    advTitle2: 'Sekarang kami sedang merekrut untuk node Genesis di seluruh dunia',
    advContent1: 'ICWChain adalah jaringan blockchain publik inovatif yang mempertimbangkan desentralisasi, kinerja tinggi dan keamanan data.',
    advContent2: 'ICWChain akan menjadi inovator dan navigator dalam gelombang revolusi Sains dan teknologi Internet generasi ketiga, dan mengambil mempromosikan pembangunan sistem ekonomi digital global sebagai kekuatan aksi utama dan misi utama.',
    nrTitle: 'Periode Perekrutan Node',
    ndTitle: 'Periode Penempatan Node',
    nrTime: '26 Mei 12:00 siang sampai 15 Juni',
    ndTime: '15 Juni 12:00 siang sampai 20 Juni',
    ndTime1: 'Peluncuran dan pengoperasian node',
    nrContetn1: '• Pengguna dapat membeli layanan hosting server melalui platform untuk menyediakan layanan pelanggan 1v1.',
    nrContetn2: '• Setiap tiga bulan, 20.000 ICWS (senilai 20u) akan diberikan untuk layanan hosting. Beli lebih banyak dan dapatkan lebih banyak. Bergabung lebih awal dan dapatkan keuntungan lebih awal. Untuk detailnya, silakan konsultasikan layanan pelanggan.',
    ndContetn1: '• Selama periode perekrutan node, pengguna yang membeli server yang dikelola dengan berkonsultasi dengan manajer pelanggan dapat menikmati hadiah airdrop ICW, yang dapat langsung digunakan untuk jaminan node yang dibuat.',
    recruitmentTit: 'ICW Genesis Node Reward Plan dan Pendapatan',
    accountingTitle: 'Kelompok Akuntansi',
    accountingContent: 'Kelompok akuntansi terdiri dari 15 node konsensus. Mereka membuat blok setiap 6 detik secara bergantian, dan periode akuntansi adalah 15 menit (setiap node membuat 10 blok, sehingga total ada 150 blok), dan 14,400 blok dibuat setiap hari. Hadiah pembuatan blok akan dibagi dua setiap 4 tahun, dan hadiah awal adalah 100 ICWs/blok. Hadiah harian awal adalah 1,44 juta ICWs dan hadiah tahunan adalah 525,6 juta ICWs. Oleh karena itu, ketika hadiah mencapai 2,1024 miliar, itu akan dibagi dua.',
    nodeTitle: 'Node Layanan',
    nodeContent: 'Untuk sinkronisasi layanan buku besar, node layanan dihadiahi sekali setiap periode akuntansi (15 menit) dan 96 kali sehari. Hadiah sinkronisasi akan dibagi dua setiap empat tahun, dan hadiah awal adalah 7,500 ICWs per periode akuntansi (dibagikan sama rata di antara semua node sinkronisasi). Hadiah harian awal adalah 720,000 ICWs dan hadiah tahunan adalah 262,8 juta ICWs. Oleh karena itu, ketika hadiah mencapai 1,0512 miliar, itu akan dibagi dua.',
    profitTitle: 'Total Pendapatan',
    profitContent: 'Jika jaminan node mencapai 500,000 ICW dan blok tersebut pasti akan dihasilkan (prasyarat kami), sebuah blok akan dihasilkan dalam 1 menit setengah, dan pendapatannya adalah 100 ICWs/blok. Pendapatan yang diperkirakan adalah 4,000 ICWs dalam satu jam, 96,000 ICWs dalam sehari, dan 35,040,000 ICWs dalam setahun, dan pengembaliannya diharapkan sekitar 70 kali.',
    nodeCreation: 'Pembuatan Node',
    qualification: 'Kualifikasi Pembuatan Node',
    process: 'Proses Pembuatan Node',
    pledge: 'Jaminan Node',

    // create global world
    networkEnvironmentSettings: 'Konfigurasi lingkungan jaringan',
    deposit: 'Setoran ≥ 20.000 ICW',
    edaos: 'EDAO Development Foundation',
    edao: 'The EDAO Foundation berdedikasi untuk mendukung pertumbuhan dan pengembangan protokol ICWChain, jaringan terdesentralisasi, dan ekosistem pengembang.',
    Social: 'Platform Sosial',
    Follow: 'Ikuti kami dan pelajari lebih lanjut tentang TridentChain',
    Discord: 'Discord adalah aplikasi untuk obrolan suara dan teks, perangkat lunak komunikasi komunitas mainstream dari web3.',
    github: 'Platform layanan hosting kode sumber perangkat lunak online',
    Twitters: 'Berdedikasi untuk melayani dialog publik',
    Telegrams: 'Salah satu perangkat lunak pesan instan lintas platform paling populer di dunia',
    ljgd: 'Bergabunglah dengan kami',
    voice: 'Pengguna lebih fokus pada permainan itu sendiri, dan suara yang lancar memakan lebih sedikit CPU',
    safe: "Aman dan gratis",
    Support: 'Mendukung komunikasi online sejumlah besar pengguna pada saat yang sama, komunitas kepentingan yang distandarisasi',
    serverSetup: 'Pengaturan server',
    serverSetup1: 'A. Mengonfigurasi jaringan sendiri',
    serverSetup2: 'B. Agen server',
    loginWallet: 'Masuk ke ICW Wallet',
    fillinInfo: 'Isi informasi node',
    depositPayment: 'Pembayaran setoran',
    creationSucceeded: 'Pembuatan node berhasil, menunggu penyelesaian janji',
    ICWPledge: 'Janji ICW',
    nodeOperation: 'Jika jumlah janji melebihi 200.000 ICWS, node dapat berjalan',
    more: 'Ingin tahu lebih banyak tentang ICW Chain',
   
    // dialog upgrade
    DearICWChainPartner: 'Dear ICWChain Partner',
    OK: 'OK',
    popupDate: 'ICWChain akan ditingkatkan pada 9:00-18:00 (UTC/GMT+08:00) pada 24 September 2022.',
    popupWarn: 'Mohon untuk tidak melakukan operasi transfer aset selama peningkatan!',
    popupText1: 'Peningkatan ini terutama mencakup: peningkatan efisiensi pengemasan blok, dan optimasi algoritma konsensus kesulitan serta konsensus pemungutan suara node.',
    popupText2: 'Setelah peningkatan selesai, waktu produksi blok dari node grup akuntansi akan diatur menjadi 12s/blok (yaitu, rotasi akuntansi akan diatur menjadi 1800s/periode),',
    popupText3: 'hadiah keluar blok akan diatur menjadi 200ICW/blok, dan hadiah konsensus sinkron akan diatur menjadi 15000ICW/periode akuntansi. Pengguna dari setiap node harus meningkatkan node mereka sendiri antara 9:00-18:00 (UTC/GMT+08:00) pada 24 September. Kerugian node apa pun yang disebabkan oleh peningkatan terlambat harus ditanggung sendiri.',
    popupText4: 'Pada saat yang sama, setelah peningkatan ini, ICWChain akan mendukung penerbitan USDT-IRC20.',

    // banner heading
    hbtitle: 'SETH ICO (Tahap I)',
    hbtime: '10 November 2022 10:00:00 (UTC+8) ~ 2 Desember 2022 17:00:00 (UTC+8)',
    hbBtn: 'Pelajari lebih lanjut',
    hbuy: 'Habis terjual',
    // hbuy: 'beli',
    // dialog
    name: 'SETH CHAIN',
    diallogtext1: 'Mekanisme Konsensus Hybrid Baru DPOS+POW',
    diallogtext2: 'Optimalisasi konsensus Ethereum',
    diallogtext3: 'Dukungan untuk mesin penambang Ethereum dan mesin virtual',
    diallogtext4: 'Promosi Migrasi Penambang Ethereum Asli dan konstruksi ekologi rantai',
    dialogT: 'ICO (Tahap I)',
    begin: 'dari',
    dialogStart: '10 November 2022, 10:00 pagi',
    end: 'sampai',
    dialogEnd: '25 November 2022, 17:00 sore',
    dialogInfo: '20U',
    dialogOline: 'Peluncuran Mainnet',
    dialogOlineTime: 'Maret 2023',
    dialogKnow: 'OK',
    address: 'Alamat',
    city2: 'Malaysia',
    city2Ad: 'Tingkat 15(A1), Menara Kantor Utama, Financial Park Labuan, Jalan Merdeka, 87000 Wilayah Persekutuan Labuan, Malaysia',
    city1: 'Singapura',
    city1Ad: '73 Upper Paya Lebar Road #06-01C Centro Bianco Singapura 534818',
    gmail: 'Gmail',
    emailAddress: 'admin@icwchain.com'
}
