<template>
    <div class="home">
        <div class="troduce">
            <div class="w">
                <div class="welcomeImg">
                    <img src="../assets/images/TDT/welcome.png" alt="">
                </div>
                <h1 class="welcome">
                    <span>TRIDENT <b> CHAIN</b> </span>
                </h1>
                <p class="ph">
                    {{ 'bannerText' | t }}
                </p>
                <p class="ph phs">
                    {{ 'bannerText2' | t }}
                </p>
            </div>
        </div>

        <div class="num">
            <div class="w">
                <div class="fl">
                    <img class="pcBlock" src="../assets/images/block.png" alt="">
                    <img class="ydBlock" src="../assets/images/TDT/blocks.png" alt="">
                </div>
                <div class="block fr">
                    <div>
                        <h3 v-cloak class="blocktext1">
                            <CountTo :startVal='startVal' :endVal='endVal' :duration='duration' />
                            <!-- 000,000 -->
                        </h3>
                        <h5>{{ 'Numbertransactions' | t }}</h5>
                    </div>
                    <div>
                        <h3 v-cloak class="blocktext2">
                            <CountTo :startVal='startVals' :endVal='endVals' :duration='duration' />
                            <!-- 000,000 -->
                        </h3>
                        <h5>{{ 'Blockheight' | t }}</h5>
                    </div>
                    <div>
                        <h3 v-cloak class="blocktext3">
                            <!-- <CountTo :startVal='startValss' :endVal='endValss' :duration='duration' /> -->
                            <!-- 000,000,000 -->
                            {{ TotalStake }}
                            K
                        </h3>
                        <h5>{{ 'Coretoken' | t }}</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="advantage">
            <div class="w">
                <img class="coin3" src="../assets/images/coin3.png" alt="">
                <div class="fl why">
                    <h2>{{ 'whychoose' | t }} <b>TRIDENT CHAIN</b></h2>
                </div>
                <div class="fr adv-child">
                    <div class="title">
                        <div class="line">
                        </div>
                        <div class="titleBody">
                            {{ 'highlyModularTitle' | t }}
                        </div>

                    </div>
                    <div class="content">
                        {{ 'highlyModularSubtitle' | t }}
                    </div>
                </div>
                <div class="fl adv-child">

                    <div class="title">
                        <div class="line">
                        </div>
                        <div class="titleBody">

                            {{ 'decentralizationTitle1' | t }}<br>
                            {{ 'decentralizationTitle2' | t }}
                        </div>
                    </div>
                    <div class="content">

                        {{ 'decentralizationSubtitle' | t }}
                    </div>
                </div>
                <div class="fr adv-child">
                    <div class="title">
                        <div class="line">
                        </div>
                        <div class="titleBody">
                            {{ 'contractTitle' | t }}
                        </div>

                    </div>
                    <div class="content">
                        {{ 'contractSubtitle' | t }}
                    </div>
                </div>
            </div>
        </div>

        <div class="socialize">
            <div class="w">
                <div class="terrace">
                    <h2><b>TRIDENT CHAIN</b> {{ 'Social' | t }}</h2>
                    <h5>{{ 'Follow' | t }}</h5>
                </div>
            </div>
            <div class="w">
                <div class="terraceList" @mouseover="over(index)" @mouseout="out(index)" v-on:click='change(index)'
                    :key='item.id' v-for='(item, index) in terraceList'>
                    <img :src="item.path1" alt="">
                </div>
            </div>
            <div class="w">
                <div class="Discord" v-if="this.tabChange !== 1 && this.tabChange !== 2 && this.tabChange !== 3">
                    <h3>Discord</h3>
                    <p>{{ 'Discord' | t }}</p>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 1">
                <div class="Discord">
                    <h3>GitHub</h3>
                    <p>{{ 'github' | t }}</p>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 2">
                <div class="Discord">
                    <h3>Telegram</h3>
                    <p>{{ 'Telegrams' | t }}</p>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 3">
                <div class="Discord">
                    <h3>Twitter</h3>
                    <p>{{ 'Twitters' | t }}</p>
                </div>
            </div>
        </div>

        <div class="socialize1">
            <div class="w">
                <div class="terrace">
                    <h2><b>TRIDENT CHAIN</b> {{ 'Social' | t }}</h2>
                    <img src="../assets/images/Vector 2.png" alt="" class="hor">
                    <h5>{{ 'Follow' | t }}</h5>
                </div>
            </div>
            <div class="w">
                <div class="terraceList" @touchstart="gtouchstart(index)" @touchmove="gtouchmove(index)"
                    @touchend="gtouchend(index)" :key='item.id' v-for='(item, index) in terraceList'>
                    <img :src="item.path1" alt="">
                </div>
            </div>
            <div class="w">
                <div class="Discord" v-if="this.tabChange !== 1 && this.tabChange !== 2 && this.tabChange !== 3">
                    <h3>Discord</h3>
                    <p>{{ 'Discord' | t }}</p>
                    <div class="edit" @click="learnMorefirst">{{ 'ljgd' | t }}</div>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 1">
                <div class="Discord">
                    <h3>GitHub</h3>
                    <p>{{ 'github' | t }}</p>
                    <div class="edit" @click="learnMoretwo">{{ 'ljgd' | t }}</div>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 2">
                <div class="Discord">
                    <h3>Telegram</h3>
                    <p>{{ 'Telegrams' | t }}</p>
                    <div class="edit" @click="learnMorethree">{{ 'ljgd' | t }}</div>
                </div>
            </div>
            <div class="w" v-if="this.tabChange === 3">
                <div class="Discord">
                    <h3>Twitter</h3>
                    <p>{{ 'Twitters' | t }}</p>
                    <div class="edit" @click="learnMorefour">{{ 'ljgd' | t }}</div>
                </div>
            </div>
        </div>

        <div class="timeInfo" id="timeInfo">
            <div class="w">
                <div class="timeInfo-title">{{ "map" | t }}</div>
                <div class="timeInfo-item">
                    <div class="timeInfo-item-left">
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2023 Q4</div>
                            <div class="timeInfo-item-left-text-cn">{{ "techRoadMap1" | t }}</div>
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2024 Q1</div>
                            <div class="timeInfo-item-left-text-cn">{{ "techRoadMap2" | t }}</div>
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2024 Q2</div>
                            <div class="timeInfo-item-left-text-cn">{{ "techRoadMap3" | t }}
                            </div>
                        </div>
                    </div>
                    <div class="timeInfo-item-middle">
                        <div class="timeInfo-item-middle-xiala">
                            <img src="../assets/images/TDT/xiala.png" alt="">
                        </div>
                        <div class="timeInfo-item-middle-tuoyuan">
                            <img src="../assets/images/TDT/tuotuan.png" alt="">
                        </div>
                        <div class="timeInfo-item-middle-tuoyuan1">
                            <img src="../assets/images/TDT/tuotuan.png" alt="">
                        </div>
                    </div>
                    <div class="timeInfo-item-right">
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2024 Q3</div>
                            <div class="timeInfo-item-left-text-cn">{{ "techRoadMap4" | t }}</div>
                        </div>
                        <div class="timeInfo-item-left-text">
                            <div class="timeInfo-item-left-text-title">2024 Q4</div>
                            <div class="timeInfo-item-left-text-cn">{{ "techRoadMap5" | t }}{{ "techRoadMap6" | t }}</div>
                        </div>
                    </div>
                </div>
                <div class="w">
                    <img class="coin4" src="../assets/images/coin4.png" alt="">
                    <img class="coin5" src="../assets/images/coin5.png" alt="">
                    <img class="coin6" src="../assets/images/TDT/bottomLeft.png" alt="">
                    <img class="coin7" src="../assets/images/TDT/bottomRight.png" alt="">
                </div>
            </div>
        </div>

        <!-- 悬浮小广告样式的提示信息
        <div id="thediv" ref="thediv" v-show="thedivShow" @mouseover="clearFdAd" @mouseout="starFdAd">
            <div class="clear" @click="clear">
                <i class="el-icon-circle-close"></i>
            </div>
            <a href="https://medium.com/@tridentchain96/lp-airdrop-15-million-tdc-43471d97dfb8" target="_blank">
                <img src="../assets/images/TDT/pop.png" width="320" /></a>
        </div> -->

    </div>
</template>
<script>
import downloadjs from 'downloadjs'
import Discordss from '@/assets/images/Discordss.png'
import Discord1 from '@/assets/images/Discord1.png'
import GitHub from '@/assets/images/GitHub.png'
import GitHub1 from '@/assets/images/GitHub1.png'
import telegram from '@/assets/images/telegram.png'
import telegram1 from '@/assets/images/telegram1.png'
import twitter from '@/assets/images/twitter.png'
import twitter1 from '@/assets/images/twitter1.png'
import CountTo from 'vue-count-to'

export default {
    name: 'home-page',
    data() {
        return {
            startVal: 0,
            startVals: 0,
            startValss: 0,
            endVal: 0,
            endVals: 0,
            endValss: 0,
            duration: 2000,
            popupState: true,
            transactions: '',
            height: '',
            TotalStake: '',
            timer: null,
            startTime: 0, // 初始开始请求时间
            currentIndex: 0,
            userShow: true,
            terraceList: [{
                id: 1,
                path1: Discordss,
                path2: Discord1,
                transfer: ''
            }, {
                id: 2,
                path1: GitHub,
                path2: GitHub1,
                transfer: ''
            }, {
                id: 3,
                path1: telegram,
                path2: telegram1,
                transfer: ''
            }, {
                id: 4,
                path1: twitter,
                path2: twitter1,
                transfer: ''
            }],
            tabChange: '',
            randomNumber: 1,
            randomNumbers: 1,
            randomNumberss: 1,
            // xPos: 0,
            // yPos: 0,
            // xin: true,
            // yin: true,
            // step: 1,
            // delay: 18,
            // height: 0,
            // Hoffset: 0,
            // Woffset: 0,
            // yon: 0,
            // xon: 0,
            // thedivShow: true,
            // interval: null,
        }
    },
    components: {
        CountTo
    },
    methods: {
        // // 飘窗动画方法
        // changePos() {
        //     let width = document.documentElement.clientWidth;
        //     let height = document.documentElement.clientHeight;
        //     this.Hoffset = this.$refs.thediv.offsetHeight; //获取元素高度
        //     this.Woffset = this.$refs.thediv.offsetWidth;

        //     // 滚动部分跟随屏幕滚动
        //     this.$refs.thediv.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
        //     this.$refs.thediv.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px";

        //     // 滚动部分不随屏幕滚动
        //     // this.$refs.thediv.style.left =
        //     //     this.xPos + document.body.scrollLeft + "px";
        //     // this.$refs.thediv.style.top = this.yPos + document.body.scrollTop + "px";

        //     if (this.yon) {
        //         this.yPos = this.yPos + this.step;
        //     } else {
        //         this.yPos = this.yPos - this.step;
        //     }
        //     if (this.yPos < 0) {
        //         this.yon = 1;
        //         this.yPos = 0;
        //     }
        //     if (this.yPos >= height - this.Hoffset) {
        //         this.yon = 0;
        //         this.yPos = height - this.Hoffset;
        //     }

        //     if (this.xon) {
        //         this.xPos = this.xPos + this.step;
        //     } else {
        //         this.xPos = this.xPos - this.step;
        //     }
        //     if (this.xPos < 0) {
        //         this.xon = 1;
        //         this.xPos = 0;
        //     }
        //     if (this.xPos >= width - this.Woffset) {
        //         this.xon = 0;
        //         this.xPos = width - this.Woffset;
        //     }
        // },

        // // 鼠标移入关闭广告
        // clearFdAd() {
        //     clearInterval(this.interval);
        // },

        // // 鼠标移出开始广告
        // starFdAd() {
        //     clearInterval(this.interval);
        //     this.interval = setInterval(this.changePos, this.delay);
        // },

        // // 点击关闭广告
        // clear() {
        //     this.thedivShow = false
        //     setTimeout(() => {
        //         clearInterval(this.interval);
        //     }, 1000);

        // },

        download() {
            const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            const pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
            downloadjs(pdfUrl)
        },
        popupClick() {
            this.popupState = false
        },
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(nameOrURI)
                }
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        async getTransactions() {
            this.randomNumber = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/', {
                jsonrpc: '2.0',
                method: 'getCoinInfo',
                params: [
                    66
                ],
                id: 250
            })
            let txCount = res.result.txCount
            txCount = this.addDou(txCount.toString())
            this.transactions = txCount
            this.endVal = res.result.txCount
            this.startVal = this.endVal - this.randomNumber

        },

        async getBlockheight() {
            this.randomNumbers = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/',
                {
                    jsonrpc: '2.0',
                    method: 'getBestBlockHeader',
                    params: [
                        66
                    ],
                    id: 165
                }
            )
            let height = res.result.height
            height = this.addDou(height.toString())
            this.height = height
            this.endVals = res.result.height
            this.startVals = this.endVals - this.randomNumbers
        },

        async getTotalStake() {
            this.randomNumberss = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/',
                {
                    jsonrpc: '2.0',
                    method: 'getCoinInfo',
                    params: [
                        66
                    ],
                    id: 508
                }
            )
            let TotalStake = res.result.consensusTotal
            TotalStake = this.thousands(TotalStake.toString())
            this.TotalStake = TotalStake
            this.endValss = parseFloat(res.result.consensusTotal.toString().slice(0, 9))
            this.startValss = this.endValss - this.randomNumberss
        },
        addDou(num) {
            const numArr = num.split('.')
            num = numArr[0]
            let result = ''
            while (num.length > 3) {
                result = ',' + num.slice(-3) + result
                num = num.slice(0, num.length - 3)
            }
            if (num) {
                result = num + result
            }
            return result
        },

        thousands(num) {
            num = num / 100000000000
            var str = num.toFixed(2).toString()
            var reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
            return str.replace(reg, '$1,')
        },

        time() {
            setInterval(() => {
                setTimeout(() => {
                    this.getBlockheight()
                    this.getTotalStake()
                    this.getTransactions()
                }, 0)
            }, 5000)
        },
        // pc点击跳转
        change: function (index) {
            if (index === 0) {
                window.open('https://discord.com/invite/KcKMrG36ws')
            } else if (index === 1) {
                window.open('https://github.com/TridentChain/TridentChain')
            } else if (index === 2) {
                window.open('https://t.me/TridentChain_EN')
            } else if (index === 3) {
                window.open('https://twitter.com/TDC_Chain')
            }
        },
        // 鼠标移入切换图片
        over(e) {
            this.terraceList[e].transfer = this.terraceList[e].path1
            this.terraceList[e].path1 = this.terraceList[e].path2
            this.tabChange = e
        },
        // 鼠标移出切换图片
        out(e) {
            this.terraceList[e].path1 = this.terraceList[e].transfer
            this.tabChange = e
        },
        // 移动端手指触碰
        gtouchstart(index) {
            // console.log('index', index)
            this.terraceList[index].transfer = this.terraceList[index].path1
            this.terraceList[index].path1 = this.terraceList[index].path2
            this.tabChange = index
        },
        // 移动端手指滑动
        gtouchmove(index) {
            // console.log('index1', index)
        },
        // 移动端手指离开
        gtouchend(index) {
            setTimeout(() => {
                this.terraceList[index].path1 = this.terraceList[index].transfer
            }, 1000)
            this.tabChange = index
        },
        // 移动端点击跳转页面
        learnMorefirst() {
            window.open('https://discord.gg/wFtfb9fB')
        },
        learnMoretwo() {
            window.open('https://github.com/TridentChain/TridentChain')
        },
        learnMorethree() {
            window.open('https://t.me/TridentChain_EN')
        },
        learnMorefour() {
            window.open('https://twitter.com/TridentChain_ ')
        }
    },
    created() {
        this.getTransactions()
        this.getBlockheight()
        this.getTotalStake()
        this.time()
    },
    // mounted() {
    //     this.interval = setInterval(this.changePos, this.delay);
    // },

}
</script>
<style lang="less" scoped>
.home {
    background: url("../assets/images/bg.png") no-repeat center top !important;

    // #thediv {
    //     z-index: 10000;
    //     position: absolute;
    //     top: 43px;
    //     left: 2px;
    //     height: 300px;
    //     width: 370px;
    //     overflow: hidden;
    //     cursor: pointer;

    //     img {
    //         width: 370px;
    //         height: 250px;
    //     }

    //     .clear {
    //         cursor: pointer;
    //         text-align: right;
    //         font-size: 30px;
    //         color: #999999;
    //     }
    // }


    .troduce {
        width: 100%;
        height: 950px;
        background: url("../assets/images/bghome.gif") no-repeat center top;
        margin: 0 auto;

        // .coin {
        //     position: absolute;
        //     top: 180px;
        //     right: -120px;
        // }

        // .coin1 {
        //     position: absolute;
        //     top: 180px;
        //     right: -40px;
        // }

        // .coin2 {
        //     position: absolute;
        //     top: 680px;
        //     left: 380px;
        // }

        .w {
            position: relative;
        }

        iframe.ap.max {
            border: none;
        }

        .welcomeImg {
            display: none;
        }

        .welcome {
            font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
            width: 711px;
            height: 176px;
            box-sizing: border-box;
            color: #FFFFFF;
            font-size: 68px;
            position: absolute;
            top: 325px;
            left: 22px;
            font-weight: bold;

            b {
                background-image: -webkit-linear-gradient(#1A1440 0%, #26C1FF 0%, #2387FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

        }

        .ph {
            font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
            width: 590px;
            height: 112px;
            line-height: 30px;
            color: #FFFFFF;
            font-size: 22px;
            position: absolute;
            top: 489px;
            left: 22px;
        }

        .phs {
            margin-top: 80px;
        }

        .download {
            font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
            width: 270px;
            height: 48px;
            line-height: 48px;
            position: absolute;
            top: 590px;
            background-color: #246EFF;
            left: 22px;
            font-size: 16px;
            color: #FCFCFD;
            text-align: center;
            box-sizing: border-box;
            border-radius: 24px;
            cursor: pointer;

            &:hover {
                background-color: #0043b3;
            }
        }
    }

    .num {
        margin-top: 100px;
        width: 100%;
        height: 800px;
        color: #FFFFFF;

        .pcBlock {
            margin-top: 150px;
        }

        .ydBlock {
            display: none;
        }

        .block {
            width: 600px;
            margin-top: 10px;
            text-align: left;



            h3 {
                // background-image: -webkit-linear-gradient(#1A1440 0%, #26C1FF 0%, #2387FF 100%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                margin-top: 30px;
                font-size: 80px;
                font-weight: 700;
                font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
            }

            .blocktext1 {
                background: linear-gradient(101.11deg, #f087ff 14.47%, #6e1fce 39.43%, rgba(110, 31, 206, .1) 76.77%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .blocktext2 {
                background: linear-gradient(100.93deg, #1fcff1 15.61%, #234cb6 41.38%, rgba(35, 76, 182, .1) 79.94%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .blocktext3 {
                background: linear-gradient(97.65deg, #19fb9b 11.36%, #199890 54.3%, #005f59 100.78%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            h5 {
                font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
                width: 195px;
                margin-top: 30px;
                font-size: 18px;
                font-weight: normal;
                color: #FFFFFF;
                text-align: left;
            }
        }

    }

    .advantage {
        width: 100%;
        // height: 860px;
        height: 740px;


        .w {
            position: relative;
        }

        .coin3 {
            position: absolute;
            top: 220px;
            left: -80px;
            z-index: 1;
        }

        .why {
            font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
            color: #FFFFFF;
            width: 500px;
            margin: 40px auto 0;
            height: 200px;
            z-index: 99;

            h2 {
                margin-top: 40px;
                font-size: 35px;
            }

            b {
                font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
                color: #FFFFFF;
            }

            .hor {
                width: 39px;
                height: 1px;
                margin: 25px auto;
                text-align: center;
            }

            h5 {
                font-weight: normal;
                color: #FFFFFF;
                font-size: 16px;
                margin-top: 30px;
            }
        }

        .adv-child {
            font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
            box-sizing: border-box;
            margin-top: 40px;
            width: 500px;
            height: 250px;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 999;
            position: relative;



            .title {
                width: 430px;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: 700;
                padding: 40px 40px 0px 50px;

                .line {
                    width: 6px;
                    height: 20px;
                    background-color: #2387FF;
                    display: inline-block;
                }

                .titleBody {
                    display: inline-block;
                    padding-left: 20px;
                }
            }

            .content {
                width: 400px;
                font-size: 17px;
                color: #FFFFFF;
                padding: 40px 40px 0px 50px;
            }
        }

        .adv-child:nth-child(3) {
            height: 300px;
        }

        .adv-child:nth-child(4) {
            height: 350px;
        }

        .adv-info {
            width: 60%;
            font-size: 26px;
            font-weight: bold;
            color: #ffffff;
            margin-top: 44px;
        }
    }

    .socialize1 {
        display: none;
    }

    .socialize {
        font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
        overflow: hidden;
        width: 100%;
        // height: 920px;
        height: 720px;

        .terrace {
            color: #FFFFFF;
            text-align: center;
            margin: 75px auto 0;

            h2 {
                font-size: 50px;

                b {
                    color: #FFFFFF;
                }
            }

            h5 {
                padding-top: 30px;
                font-weight: normal;
                color: #FFFFFF;
                font-size: 20px;
            }
        }

        .terraceList {
            float: left;
            width: 230px;
            height: 150px;
            background-color: rgba(0, 0, 0, 0.3);
            text-align: center;
            line-height: 150px;
            margin: 100px 45px;
            cursor: pointer;
        }

        .terraceList:first-child {
            margin-left: 0;
        }

        .terraceList:last-child {
            margin-right: 0;
        }

        .Discord {
            padding-left: 0px;

            h3 {
                font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
                font-size: 34px;
                color: #FFFFFF;
            }

            p {
                font-size: 20px;
                color: #FFFFFF;
                line-height: 44px;
            }
        }

    }

    .Friend {
        font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
        width: 100%;
        height: 280px;
        background: url('../assets/images/Friend.png') no-repeat center;
        cursor: pointer;

        h2 {
            padding-top: 60px;
            padding-left: 232px;
            font-size: 60px;
            color: #FFFFFF;
        }

        p {
            padding-top: 15px;
            padding-left: 232px;
            font-size: 18px;
            color: #FFFFFF;
        }
    }

    .contact {
        font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
        box-sizing: border-box;
        width: 100%;
        height: 650px;

        .w {
            background: url('../assets/images/contact.png') no-repeat center;
            height: 540px;
        }

        .Gmail {
            overflow: hidden;
            padding-top: 100px;
            padding-left: 50px;

            h5 {
                font-size: 20px;
                color: #1A315B;
            }

            p {
                margin-top: 25px;
                font-size: 16px;
                color: #1A315B;
            }
        }

        .Address {
            overflow: hidden;
            padding-top: 50px;
            padding-left: 50px;

            h5 {
                font-size: 20px;
                color: #1A315B;
            }

            p {
                width: 597px;
                margin-top: 25px;
                font-size: 16px;
                color: #1A315B;
            }

        }
    }

    .Foundation {
        font-family: 'Diatype,Helvetica,Arial,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Canta';
        width: 100%;
        height: 650px;
        text-align: center;

        .w {
            position: relative;
        }

        .coin4 {
            position: absolute;
            top: 250px;
            right: 0px;
        }

        .coin5 {
            position: absolute;
            top: 410px;
            left: 50px;
            z-index: 1;
        }

        p {
            padding-top: 120px;
            width: 1074px;
            font-size: 32px;
            color: #FFFFFF;
            margin: 0 auto;
        }

        .edao {
            width: 475px;
            height: 65px;
            line-height: 65px;
            font-size: 24px;
            background-image: -webkit-linear-gradient(#26C1FF 0%, #2387FF 100%);
            border-radius: 50px;
            margin: 50px auto 0;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    .timeInfo {
        height: 840px;
        width: 100%;
        color: #ffffff;
        margin-top: 40px;
        position: relative;

        .timeInfo-title {
            font-size: 42px;
        }

        .timeInfo-item {
            display: flex;
            justify-content: space-between;

            .timeInfo-item-left {
                width: 45%;

                .timeInfo-item-left-text {
                    height: 100px;
                    margin-top: 50px;
                    background: rgba(0, 0, 0, 0.3);
                    padding: 20px 40px;
                    border-radius: 20px;

                    .timeInfo-item-left-text-title {
                        margin-bottom: 10px;
                        font-size: 32px;
                        color: #2387FF;
                    }
                }
            }

            .timeInfo-item-middle {
                width: 5px;
                height: 360px;
                background: #2387FF;
                margin-top: 130px;
                position: relative;

                .timeInfo-item-middle-xiala {
                    position: absolute;
                    top: -40px;
                    left: -24px;
                }

                .timeInfo-item-middle-tuoyuan {
                    position: absolute;
                    top: 160px;
                    left: -24px;
                }

                .timeInfo-item-middle-tuoyuan1 {
                    position: absolute;
                    bottom: -52px;
                    left: -24px;
                }
            }

            .timeInfo-item-right {
                width: 45%;
                margin-top: 70px;
                position: relative;

                .timeInfo-item-right-bi {
                    position: absolute;
                    top: 165px;
                    right: 0px;
                    z-index: 1;
                }

                .timeInfo-item-left-text {
                    height: 100px;
                    margin-top: 50px;
                    background: rgba(0, 0, 0, 0.3);
                    padding: 20px 40px;
                    border-radius: 20px;
                    position: relative;
                    z-index: 999;

                    .timeInfo-item-left-text-title {
                        margin-bottom: 10px;
                        font-size: 32px;
                        color: #2387FF;
                    }

                }

                .timeInfo-item-left-text:nth-child(2) {
                    height: 150px;
                }
            }

        }

        .w {
            position: relative;

            .coin4 {
                position: absolute;
                bottom: -122px;
                right: 100px;
            }

            .coin5 {
                position: absolute;
                bottom: -223px;
                left: 0px;
                z-index: 1;
            }

            .coin6 {
                display: none;
            }

            .coin7 {
                display: none;
            }
        }
    }
}
</style>
<!--移动端-->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .home {
        background: url("../assets/images/TDT/m-bg.png") no-repeat center top !important;
        height: 61rem;

        // #thediv {
        //     top: 0.43rem;
        //     left: 0.01px;
        //     height: 3rem;
        //     width: 3.7rem;

        //     img {
        //         width: 3rem;
        //         height: 2.5rem;
        //     }

        //     .clear {
        //         font-size: 0.4rem;
        //     }
        // }

        .w {
            width: 6.1rem;
            margin: 0 auto;
        }

        .troduce {
            background: url(../assets/images/m-bghome.gif) no-repeat !important;
            background: none;
            background-size: 7.5rem 12.34rem !important;
            height: 13rem;
            // position: relative;

            .welcome {
                width: 5.07rem;
                font-size: 0.45rem;
                top: 2rem;
                left: -0.1rem;
            }

            .welcomeImg {
                display: none;
                position: absolute;
                top: 1.6rem;
                right: 0;
            }

            .ph {
                font-size: 0.24rem;
                padding: 0;
                top: 3rem;
                width: 5.92rem;
                left: -0.1rem;
                line-height: 0.34rem;
                color: #fff;
            }

            .phs {
                margin-top: 1rem;
            }

            .download {
                left: -0.1rem;
                width: 3.25rem;
                top: 6.2rem;
                height: 0.46rem;
                text-align: center;
                font-size: 0.13rem;
                font-weight: bold;
                color: #246EFF;
                line-height: 0.46rem;
                padding: 0 0;
                background: #fff;
                border: 1px solid #246EFF;
                border-radius: 0.41rem;
            }
        }

        .num {
            width: 6.94rem;
            // background-color: #fff;
            height: 11rem;
            margin: 0 0.3rem;
            border-radius: 0.33rem;

            .w {
                margin-top: -0.73rem;
            }

            .pcBlock {
                display: none;
            }

            .ydBlock {
                display: block;
                width: 6rem;
                height: 3rem;
            }

            .block {
                float: left;
                box-sizing: border-box;
                width: 100%;
                margin-top: 0.5rem;
                padding: 0;

                h3 {
                    margin-top: 0.50rem;
                    color: #000;
                    -webkit-text-fill-color: #000;
                    font-size: 0.8rem;
                }

                h5 {
                    margin-top: 0.3rem;
                    color: white;
                    font-size: 0.13rem;
                    width: 2.5rem;
                }
            }
        }

        .advantage {
            margin-top: 0rem;
            box-sizing: border-box;
            height: 12rem;
            // background-color: red;

            .w {
                width: 6.94rem;
            }

            .coin3 {
                display: none;
            }

            .why {
                padding-top: 0.75rem;
                margin: 0;
                width: 100%;
                height: 0rem;

                h2 {
                    margin-top: 0rem;
                    font-size: 0.41rem;
                    line-height: 0.26rem;
                }

                .hor {
                    margin-top: -1rem;
                }

                h5 {
                    font-size: 0.17rem;
                    line-height: 0.17rem;
                    margin-top: -1rem;
                }
            }

            .adv-child:nth-child(4) {
                margin: 0.5rem 0;
                height: 3.5rem;

                .content {
                    position: absolute;
                    width: 5.5rem;
                    font-size: 0.2rem;
                    margin-top: 0.6rem;
                    left: 0rem;
                    top: 0.5rem;
                }
            }

            .adv-child:nth-child(3) {
                margin-top: 0.8rem;
                height: 3rem;
            }

            .adv-child:nth-child(2) {
                margin-top: 0.5rem;
            }

            .adv-child {
                box-sizing: border-box;
                position: relative;
                margin: 0 auto;
                width: 6.94rem;
                height: 3rem;
                padding: 0;
                border-radius: 0.27rem;

                .title {
                    position: absolute;
                    width: 5.3rem;
                    font-size: 0.26rem;
                    line-height: 0.31rem;
                    top: 0.1rem;
                    left: 0rem;
                    margin: 0.19rem 0;

                    .titleBody {
                        padding-left: 0rem;
                        position: absolute;
                        top: 0.2rem;
                        left: 0.8rem;
                    }

                    .line {
                        height: 0.2rem;
                        position: absolute;
                        width: 0.06rem;
                        margin-top: 0.36rem;
                        top: -0.1rem;
                        left: 0.41rem;
                        border-radius: 3px;
                    }
                }

                .content {
                    position: absolute;
                    width: 5.5rem;
                    font-size: 0.2rem;
                    margin-top: 0.2rem;
                    left: 0rem;
                    top: 0.5rem;
                    padding: 0.5rem;
                }
            }

            .popupWrap {
                width: 4rem;
                height: 2rem;
                border-radius: 0.1rem 0.1rem 0px 0.1rem;
                padding: 0.15rem;
                position: fixed;
                bottom: 0.14rem;
                right: 0.18rem;
                z-index: 100;

                .close {
                    font-size: 0.3rem;
                    color: #fff;
                    position: absolute;
                    top: 10px;
                    right: 26px;
                    cursor: pointer;
                }

                .popup {
                    width: 100%;
                    height: 100%;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                        background: rgba(0, 0, 0, 0.2);
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        background: rgba(0, 0, 0, 0.1);
                    }

                    .popupContent {
                        width: 100%;
                        min-height: 250px;
                        font-size: 0.14rem;
                        font-family: Source Han Sans SC;
                        color: #FFFFFF;

                        .popupTitle {
                            font-weight: bold;
                            margin-bottom: 6px;
                        }

                        .popupWarn {
                            color: #FF0000;
                            font-weight: bold;
                            margin: 6px 0;
                        }

                        .popupText {
                            line-height: 0.3rem;
                        }
                    }

                    .popupButton {
                        width: 1rem;
                        height: 0.3rem;
                        background: #3A5BE9;
                        font-family: PingFang SC;
                        font-size: 0.14rem;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 0.3rem;
                        font-weight: bold;
                        border-radius: 22px;
                        margin: 30px auto 0;
                        cursor: pointer;
                    }
                }
            }
        }

        .socialize {
            display: none;
        }

        .socialize1 {
            width: 100%;
            height: 9rem;
            display: block;
            // background: red;

            .terrace {
                padding-top: 0.2rem;
                margin: 0 auto;

                h2 {
                    margin-top: 0.35rem;
                    font-size: 0.4rem;
                    text-align: center;
                    color: white;
                }

                .hor {
                    margin-top: -1rem;
                    width: 0rem;
                }

                h5 {
                    font-size: 0.17rem;
                    line-height: 0.17rem;
                    margin-top: -1rem;
                    text-align: center;
                    color: white;
                }
            }

            .terraceList {
                box-sizing: border-box;
                float: left;
                width: 2.70rem;
                height: 2rem;
                margin: 0.5rem 0.23rem 0;
                line-height: 0.5rem;
                padding: 0.61rem 0.91rem;
                background: rgba(0, 0, 0, 0.3);

                img {
                    width: 0.78rem;
                    height: 0.78rem;
                }
            }

            .terraceList:first-child {
                margin-left: 0;
            }

            .terraceList:last-child {
                margin-right: 0;
            }

            .terraceList:nth-child(3) {
                margin-left: 0;
            }


            .Discord {
                margin-top: 5.5rem;

                h3 {
                    font-size: 0.24rem;
                    color: white;
                }

                p {
                    font-size: 0.22rem;
                    width: 6.68rem;
                    line-height: 0.38rem;
                    margin-top: 0.21rem;
                    color: white;
                }

                .edit {
                    width: 1.3rem;
                    margin-top: 0.1rem;
                    font-size: 0.18rem;
                    // background: red;
                    text-align: center;
                    padding: 0.1rem 0;
                    border-radius: 0.5rem;
                    border: 0.01rem solid #246EFF;
                    color: #246EFF;
                }
            }

        }

        .Friend {
            background: url(../assets/images/m-Friend.png) no-repeat center;
            box-sizing: border-box;
            width: 100%;
            height: 1.53rem;
            text-align: center;
            padding-top: 0.28rem;

            h2 {
                font-size: 0.31rem;
                padding: 0;
            }

            p {
                margin-top: 0.28rem;
                font-size: 0.21rem;
                padding: 0;
            }
        }

        .contact {
            margin-top: 0.3rem;
            background-color: #fff;
            width: 100%;
            height: 4.66rem;
            padding: 0.3rem 0;
            font-size: 0.21rem;

            .w {
                background: url(../assets/images/m-contact.png) no-repeat center top;
                background-size: 6.95rem 4.03rem;
                width: 6.95rem;
                height: 4.03rem;

                img {
                    width: 0.23rem;
                    height: 0.23rem;
                }

                .Gmail {
                    padding-top: 0.24rem;
                    padding-left: 0.5rem;

                    h5 {
                        font-size: 0.21rem;
                    }

                    p {
                        margin-top: 0.38rem;
                        font-size: 0.21rem;
                    }
                }

                .Address {
                    padding-top: 0.24rem;
                    padding-left: 0.5rem;

                    h5 {
                        font-size: 0.21rem;
                    }

                    p {
                        width: 100%;
                        margin: 0.1rem 0 0;
                        font-size: 0.21rem;
                    }
                }
            }
        }

        .Foundation {
            background: url(../assets/images/m-Foundation.png) no-repeat center top;
            height: 2.37rem;

            p {
                width: 6.32rem;
                font-size: 0.17rem;
                padding-top: 0.45rem;
            }

            .edao {
                width: 3.73rem;
                height: 0.47rem;
                font-size: 0.17rem;
                line-height: 0.47rem;
                margin: 0.3rem auto 0;
            }
        }

        .timeInfo {
            height: 19rem;
            width: 100%;
            color: #ffffff;
            margin-top: 40px;
            position: relative;

            // background: red;
            .timeInfo-title {
                font-size: 0.42rem;
            }

            .timeInfo-item {
                display: block;
                // justify-content: space-between;

                .timeInfo-item-left {
                    width: 100%;

                    .timeInfo-item-left-text {
                        height: 1.7rem;
                        margin-top: 0.5rem;
                        background: rgba(0, 0, 0, 0.3);
                        padding: 0.2rem 0.4rem;
                        border-radius: 0.2rem;

                        .timeInfo-item-left-text-title {
                            margin-bottom: 0.1rem;
                            font-size: 0.4rem;
                            color: #2387FF;
                        }

                        .timeInfo-item-left-text-cn {
                            font-size: 0.2rem;
                        }
                    }
                }

                .timeInfo-item-middle {
                    display: none;
                }

                .timeInfo-item-right {
                    width: 100%;
                    margin-top: 0;
                    // position: relative;

                    .timeInfo-item-right-bi {
                        position: absolute;
                        top: 165px;
                        right: 0px;
                        z-index: 1;
                    }

                    .timeInfo-item-left-text {
                        height: 2rem;
                        margin-top: 0.5rem;
                        background: rgba(0, 0, 0, 0.3);
                        padding: 0.2rem 0.4rem;
                        border-radius: 0.2rem;
                        position: relative;
                        z-index: 999;

                        .timeInfo-item-left-text-title {
                            margin-bottom: 0.1rem;
                            font-size: 0.44rem;
                            color: #2387FF;
                        }

                        .timeInfo-item-left-text-cn {
                            font-size: 0.2rem;
                        }
                    }

                    .timeInfo-item-left-text:nth-child(2) {
                        height: 2.5rem;
                    }
                }

            }

            .w {
                position: relative;

                .coin4 {
                    // position: absolute;
                    // bottom: -210px;
                    // right: 0px;
                    display: none;
                }

                .coin5 {
                    // position: absolute;
                    // bottom: -130px;
                    // left: 0px;
                    // z-index: 1;
                    display: none;
                }

                .coin6 {
                    display: block;
                    position: absolute;
                    bottom: -1rem;
                    left: 0rem;
                    z-index: 1;
                    width: 0.93rem;
                    height: 0.93rem;
                }

                .coin7 {
                    display: block;
                    position: absolute;
                    bottom: -0.9rem;
                    right: 0rem;
                    z-index: 1;
                    width: 1.08rem;
                    height: 1.06rem;
                }
            }
        }
    }
}
</style>
