<template>
    <div class="header">
        <div class="header-nav--pc">
            <div class="nav w">
                <img src="../assets/images/logo.png" class="logo" @click="jumper('home')" />
                <ul class="header-nav-list fr">
                    <li @click="jumper('home')">
                        <span>{{ "home" | t }}</span>
                    </li>
                    <li @click="jumper('ecological')">
                        <span>{{ "ecological" | t }}</span>
                    </li>
                    <li @click="jumper('recruitment')">
                        <span>{{ "recruitment" | t }}</span>
                    </li>
                </ul>
            </div>

        </div>
        <div class="header-nav--mobile">
            <img src="../assets/images/logo.png" class="logo" @click="jumper('home')" />
            <div class="ri-top">
                <li class="nav-language">
                    <div>
                        <span @click="selectLanguage">{{ selectedLanguage }}</span>
                    </div>

                    <!-- <div class="langs" :class="{ lang }">
                        <span @click="changeLanguage('en')">EN</span>
                        <span @click="changeLanguage('zh-CN')">ZN</span>
                    </div> -->
                </li>
                <div class="menu" :class="{ act }" @click="act = !act">
                    <div class="line1"></div>
                    <div class="center-line"></div>
                    <div class="line2"></div>
                </div>
            </div>
            <div class="triangle" :class="{ act }"></div>
            <div class="nav-list" :class="{ act }">
                <li>
                    <span @click="jumper('home')">{{ "home" | t }}</span>
                </li>
                <li @click="jumper('ecological')">
                    <span>{{ "ecological" | t }}</span>
                </li>
                <li @click="jumper('recruitment')">
                    <span>{{ "recruitment" | t }}</span>
                </li>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="dialogV" v-show="dialogVisible">
            <div v-if="this.dialoglang == 'en'" class="content_en">
                <div class="kown" @click="close">{{ "dialogKnow" | t }}</div>
            </div>
            <div v-else class="content_zh">
                <div class="kown" @click="close">{{ "dialogKnow" | t }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import cl from '@/utils/changeLanguage'
import downloadjs from 'downloadjs'
import prizeZh from '../assets/images/prizeZh.png'
import prizeEn from '../assets/images/prizeEn.png'
import prizeZhM from '../assets/images/375prizeZh.png'
import prizeEnM from '../assets/images/375prizeEn.png'

export default {
    name: 'page-header',
    mixins: [cl],
    data() {
        return {
            act: false,
            tegAct: false,
            DAPP: false,
            lang: false,
            dialogVisible: false,
            index: '1',
            dialoglang: '',
            prizeZh,
            prizeEn,
            prizeZhM,
            prizeEnM,
            selectedLanguage: ''
        }
    },
    mounted() {
        this.dialoglang = localStorage.getItem('language')
        const internationalization = window.localStorage.getItem('language')
        if (internationalization == 'zh-CN') {
            this.selectedLanguage = 'CN'
        } else {
            this.selectedLanguage = 'EN';
        }
    },
    updated() {
        const internationalization = window.localStorage.getItem('language')
        if (internationalization == 'zh-CN') {
            this.selectedLanguage = 'CN'
        } else {
            this.selectedLanguage = 'EN';
        }
    },
    methods: {
        /**
         * 死路由跳转
         */
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                window.open(nameOrURI)
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        downloadPDF() {
            const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            const pdfUrl =
                'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' +
                lang +
                '.pdf'
            downloadjs(pdfUrl)
        },
        // 打开弹窗
        handleClick() {
            // this.dialogVisible = true //预热活动弹窗
            // 购买活动h5
            this.dialoglang = localStorage.getItem('language')
            // const href = 'http://8.219.121.179:8082/#/purchase?language=' + this.dialoglang // h5测试地址
            // const href = 'https://seth.icwchain.com/#/purchase?language=' + this.dialoglang // h5正式地址
            // console.log(href, 'href')
            // window.open(href, '_blank')
        },
        // 关闭弹窗
        close() {
            this.dialogVisible = false
        },
        // 跳转到ICW基金
        fundsClick() {
            const lang = localStorage.getItem('language') === 'en' ? 'en' : 'zh'
            window.open('http://icwfund.icwchain.com/#/home?lang=' + lang)
        },
        // 跳转到幸运抽奖
        luckyClick() {
            const lang = localStorage.getItem('language') === 'en' ? 'en' : 'zh'
            window.open('http://luckdraw.gamfi.cc/#/home?lang=' + lang)
        },
        // 跳转到哈希竞猜
        hashGuess() {
            const lang = localStorage.getItem('language') === 'en' ? 'en' : 'zh'
            window.open('http://ihash.gamfi.cc/#/home?lang=' + lang)
        },
        selectLanguage() {
            const internationalization = localStorage.getItem('language')
            if (internationalization == 'zh-CN') {
                this.selectedLanguage = 'EN'
                this.$store.commit(
                    'SETLANGUAGE',
                    'en'
                )
            } else {
                this.selectedLanguage = 'ZN';
                this.$store.commit(
                    'SETLANGUAGE',
                    'zh-CN'
                )
            }

        }
    }
}
</script>

<style lang="less">
.header {
    .header-nav--pc {
        position: absolute;
        width: 100%;
        height: 80px;
        background-color: rgba(0, 0, 0, 0.3);

        .nav {
            height: 80px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 109;

            img.logo {
                width: 140px;
                cursor: pointer;
            }

            ul.header-nav-list {
                height: 100%;
                display: flex;
                margin: 0;
                padding-inline-start: 0;

                >li {
                    float: left;
                    position: relative;

                    span {
                        font-family: '思源黑体';
                        display: block;
                        height: 80px;
                        margin: 0 30px;
                        line-height: 80px;
                        font-size: 14px;
                        color: #FFFFFF;
                        cursor: pointer;
                    }

                    ul.nav-toggle-down {
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        padding-inline-start: 0;
                        //background-color: #08192b;
                        // display: none;
                        overflow: hidden;
                        z-index: 999;

                        li {
                            white-space: nowrap;
                            display: block;
                            text-align: center;
                            line-height: 36px;
                            margin: 0;
                            font-size: 13px;
                            cursor: pointer;
                            color: #FFFFFF;

                            &:hover {
                                background-color: #5981bd;
                            }
                        }
                    }

                    &:hover {
                        ul.nav-toggle-down {
                            display: block;
                        }
                    }
                }
            }

            @media screen and (max-width: 1780px) {
                ul.header-nav-list {
                    >li {
                        font-size: 16px;

                        ul.nav-toggle-down {
                            li {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 1580px) {
                ul.header-nav-list {
                    >li {
                        font-size: 12px;

                        ul.nav-toggle-down {
                            li {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .el-dialog__close {
        display: none;
    }

    .el-dialog__body,
    .el-dialog__header {
        padding: 0;
    }

    .dialogV {
        width: 100%;
        height: 100vh;
        background-color: rgb(14 13 13 / 89%);
        position: fixed;
        left: 0;
        top: -0px;
        z-index: 100;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
    }

    .content_zh,
    .content_en {
        color: #fff;
    }

    .dialogtext {
        text-align: left;
    }

    .yellowT {
        color: rgb(250, 199, 104);
        font-size: 20px;
    }

    .yellow {
        color: rgb(250, 199, 104);
    }

    .header-nav--mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 7.49rem;
        height: 0.69rem;
        box-sizing: border-box;
        // background-color: #EFF8FF;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.55rem;
        z-index: 999999;

        .logo {
            width: 1.08rem;
        }

        .ri-top {
            display: flex;
            align-items: center;

            .nav-language {
                line-height: 0.23rem;
                font-size: 0.18rem;
                color: #1072FB;
                margin-right: 0.3rem;
                font-weight: 600;
                position: relative;

                div {
                    width: 0.3rem;
                    height: 0.22rem;
                    border: 0.02rem solid #1072FB;
                    padding: 0.05rem;
                }
            }

            .menu {
                width: 0.24rem;
                height: 0.15rem;
                position: relative;

                .line1 {
                    width: 100%;
                    height: 0.017rem;
                    background-color: #FFF;
                    position: absolute;
                    transition: all 0.3s linear;
                    top: 0;
                    left: 0;
                }

                .center-line {
                    width: 100%;
                    height: 0.017rem;
                    background-color: #FFF;
                    position: absolute;
                    transition: all 0.3s linear;
                    top: 0.069rem;
                    left: 0;
                }

                .line2 {
                    width: 100%;
                    height: 0.017rem;
                    background-color: #FFF;
                    position: absolute;
                    transition: all 0.3s linear;
                    top: 0.13rem;
                    left: 0;
                }
            }

            .menu.act {
                .line1 {
                    top: 0.069rem;
                    width: 0.26rem;
                    transform: rotate(45deg);
                }

                .center-line {
                    display: none;
                }

                .line2 {
                    top: 0.069rem;
                    width: 0.26rem;
                    transform: rotate(135deg);
                }
            }
        }

        .triangle {
            position: fixed;
            width: 0;
            height: 0;
            border-left: 0.3rem solid transparent;
            border-right: 0.3rem solid transparent;
            border-bottom: 0.6rem solid rgba(0, 0, 0, 0.8);
            top: -4.14rem;
            left: 5.9rem;

            &.act {
                top: 0.9rem;
            }
        }

        .nav-list {
            width: 2.54rem;
            padding: 0.13rem 5%;
            background: rgba(0, 0, 0, 0.8);
            position: fixed;
            margin: 0;
            top: -4.14rem;
            left: 3.6rem;
            z-index: 9999;
            overflow: auto;
            transition: all 0.3s linear;
            // color: #436BF6;
            color: #FFFFFF;
            border-radius: 0.17rem;

            >li {
                line-height: 0.5rem;

                div {
                    display: flex;
                }

                span {
                    font-size: 0.3rem;
                    flex: 1;
                }

                span.right {
                    width: 2em;
                    flex: unset;
                    text-align: center;
                }

                .submenu-list {
                    flex-direction: column;
                    font-size: 0.13rem;
                    line-height: 0.31rem;

                    li {
                        padding-left: 0.13rem;
                    }
                }
            }

            &.act {
                top: 1.14rem;
            }
        }
    }

    @media only screen and (min-width: 990px) {

        [class*='--mobile'] {
            display: none;
        }
    }

    @media only screen and (max-width: 990px) {
        [class*='--pc'] {
            display: none;
        }
    }
}
</style>
